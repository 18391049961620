import { api } from "@services"

export const searchPage = (keyword, location, isLoggedIn) =>
  api.get(
    `/v1/fb-page-search?keyword=${keyword}&location=${location}&token=${isLoggedIn}`
  )
export const loadMore = (url, keyword, location) =>
  api.post(`/v1/fb-fetch-data`, { url, keyword, location })

export const loadLinkedinLeads = () => api.get(`/v1/linkedin-leads`)

export const getFoldersApi = () => api.get(`/v1/fb-folder`)
export const getPagesByFolderId = (folderId, query) =>
  api.get(`/v1/fb-folder/page/${folderId}?q=${query}`)
export const movePage = (folderId, pages, activeFolderId) =>
  api.post(`/v1/fb-folder/move-page`, { folderId, pages, activeFolderId })
export const deletePage = (pageIds, activeFolderId) =>
  api.post(`/v1/fb-folder/delete_page`, { pageIds, activeFolderId })
