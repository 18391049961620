import React, { Component } from "react";
import { Grid, List, ListItem, ListContent, ListHeader, FormField, Button, Form, TextArea, Confirm } from "semantic-ui-react";

import "./index.scss";

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            removeModal: false
        };
    }

    initLoad = () => {
        const { activeAssistant, assistantList } = this.props;
        const assistant = assistantList.find(ass => ass.id == activeAssistant);
        if (assistant) {
            this.props.changeForm({
                ...assistant,
                type: "edit"
            });
        }
    };

    componentDidMount() {
        this.initLoad();
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.props.changeForm({
            [name]: value
        });
    };

    handleSubmit = () => {
        this.props.saveForm(this.props.form, "settings");
    };

    handleDelete = () => {
        if (this.props.form.form.id) {
            this.props.deleteAssisatnt(this.props.form.form.id);
            window.location.reload();
        }
    };

    openConfirmModal = (removeModal = true) => {
        this.setState({ removeModal });
    };

    onConfirm = () => {
        this.setState({ removeModal: false });
        if (this.props.form.form.id) {
            this.props.deleteAssistant(this.props.form.form.id);
        }
    };

    render() {
        const { form } = this.props;
        const formData = form.form;
        const { welcome_message, system_prompt, open_ai_key } = formData;

        return (
            <Grid className="h-100 assistant-settings">
                <Confirm
                    className="confirm-modal"
                    open={this.state.removeModal}
                    onCancel={() => this.openConfirmModal(false)}
                    onConfirm={this.onConfirm}
                />
                <Grid.Column width={16} className="header">
                    <div className="d-flex align-items-center">
                        <i className="ti ti-settings"></i>
                        <div>
                            <strong>Assistant settings</strong>
                            <p>Assistant behavior customizations and general settings.</p>
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column className="main-form" width={8}>
                    <Form onSubmit={this.handleSubmit}>
                        <FormField>
                            <label>Welcome message</label>
                            <input
                                placeholder="Hey, this is John, how can I help you?"
                                name="welcome_message"
                                value={welcome_message || ""}
                                onChange={this.handleChange}
                            />
                            <span>This is the first message user will see when opening the chat widget.</span>
                        </FormField>
                        <FormField>
                            <label>System prompt</label>

                            <textarea
                                name="system_prompt"
                                placeholder="You are Assistant AI and your task is to use your knowledge and skills to help website visitors with their queries."
                                rows="4"
                                onChange={this.handleChange}
                                value={system_prompt || ""}
                            />
                            <span>
                                This is the base prompt that provides context to the AI model. Use it to describe what
                                your assistant can do and how it can help the user.
                            </span>
                        </FormField>
                        <FormField>
                            <label>OpenAI API key (PRO)</label>
                            <input
                                placeholder="sk-..."
                                name="open_ai_key"
                                value={open_ai_key || ""}
                                onChange={this.handleChange}
                            />
                            <span>
                                You can provide your own OpenAI API key e.g. if you need more monthly GPT-4 messages or
                                because to your company policies.
                            </span>
                        </FormField>

                        <FormField className="delete-btn">
                            <label>Delete Assistant</label>
                            <div className="btn-container">
                                <p>Delete this assistant and all of its data and configuration. </p>
                                <p className="text-danger">Warning: This action cannot be undone.</p>
                                <Button type="button" onClick={() => this.openConfirmModal(true)} color="red">
                                    Delete
                                </Button>
                            </div>
                        </FormField>

                        <Button type="submit" positive>
                            Save changes
                        </Button>
                    </Form>
                </Grid.Column>
            </Grid>
        );
    }
}

export default Settings;
