import React, { Component } from "react";
import { compose } from "react-recompose";
import { Segment, Button, Grid, Icon, Label, Form, TextArea } from "semantic-ui-react";
import {
    BreadCrumbContainer,
    LeadNotesContainer,
    LeadFormContainer,
    LeadsContainer,
    EmailFormContainer
} from "@containers";
import TimeLine from "./timeline";
import "./index.scss";
import LeadModal from "components/@common/modals/lead";
import { Link } from "react-router-dom";
import { Device } from "twilio-client";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";

const editor = React.createRef();
const config = {
    readonly: false,
    extraButtons: ["CustomTags"],
    minHeight: "300px"
};

class LeadNotes extends Component {
    state = {
        onPhone: false,
        readyToCall: false,
        showSMSModal: false,
        form: {
            message: ""
        }
    };
    async componentWillMount() {
        const { companyId, leadId } = this.props;
        this.props.loadLead(companyId, leadId, true, true);
        this.props.fetchTwilioTokenBy(leadId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.leadId !== this.props.leadId) {
            const { companyId, leadId } = this.props;

            this.props.fetchTwilioTokenBy(leadId);
            this.props.loadLead(companyId, leadId, true, true);
        }
        if (prevProps.twilioToken !== this.props.twilioToken && this.props.twilioToken) {
            Device.setup(this.props.twilioToken);
        }

        if (prevState.onPhone && !this.state.onPhone) {
            let { companyId, leadId } = this.props;
            setTimeout(() => {
                this.props.loadLead(companyId, leadId, true, true);
            }, 5000);
        }
    }

    componentDidMount() {
        Device.disconnect(() => {
            this.setState({
                onPhone: false
            });
        });
        Device.ready(() => {
            this.setState({
                readyToCall: true
            });
        });
    }

    onAddNote = async form => {
        this.props.createLeadNote({
            ...form,
            status: form.status ? form.status : this.props.lead.status
        });
        await this.props.searchLeads("");
    };

    onCall = () => {
        const checkIsValidNumber = /^([0-9]|#|\*)+$/.test(this.props.lead.phone.replace(/[+\-()\s]/g, ""));
        if (this.state.onPhone) {
            Device.disconnectAll();
        } else if (this.props.twilioToken && checkIsValidNumber) {
            try {
                Device.connect({ number: this.props.lead.phone });

                this.setState({
                    onPhone: true
                });

                this.props.createLeadNote({
                    message: "initiated agency call",
                    status: "CONTACTED_CALL"
                });
            } catch (e) {
                toast.error("Your twilio account is not active or limited", { position: toast.POSITION.TOP_RIGHT });
                // toast.error(e.message, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    };

    setSMSModal = () => {
        this.setState({ showSMSModal: !this.state.showSMSModal, form: { message: "" } });
    };

    onCancelSendSMS = () => {
        this.setState({ showSMSModal: false, form: { message: "" } });
    };

    onSendSMS = () => {
        try {
            if (this.state.form.message === "") {
                return;
            }

            this.props.sendSMSMessage(this.state.form);

            this.setState({ showSMSModal: false, form: { message: "" } });

            let { companyId, leadId } = this.props;
            setTimeout(() => {
                this.props.loadLead(companyId, leadId, true, true);
            }, 5000);
        } catch (e) {
            toast.error("Your twilio account is not active or limited", { position: toast.POSITION.TOP_RIGHT });
            // toast.error(e.message, { position: toast.POSITION.TOP_RIGHT });
        }
    };

    onChangeSMSMessage = data => {
        this.setState({
            form: { message: data }
        });
    };

    render() {
        const { lead, leadNotes, leadStatuses, twilioToken } = this.props;
        const { onPhone, form } = this.state;
        return (
            <div className="lead-notes-profile-container">
                <LeadModal size="small" />
                <Segment className="lead-n-p-content">
                    <div className="lead-n-p-row lead-n-p-row-top align-stretch">
                        <div className="btnCloseLead" onClick={() => this.props.onClose()}>
                            <i className="flaticon stroke x-2"></i>
                        </div>
                        <div className="title">Quick Preview</div>
                        <div className="link-profile">
                            <Link to={`/companies/${lead.company.id}/leads/${lead.id}/notes`} className="btn">
                                Profile
                            </Link>
                        </div>
                    </div>
                    <div className="lead-n-p-row align-center">
                        <div className={`circle-label lead-status-${lead.status[0].toLowerCase()}`}>
                            {(lead.fullname && lead.fullname.charAt(0).toUpperCase()) ||
                                lead.status.charAt(0).toUpperCase()}
                            {(lead.smsReplayCount && (
                                <Label color="red" floating>
                                    {lead.smsReplayCount}
                                </Label>
                            )) ||
                                ""}
                        </div>
                        <div className="l-full-name">{lead.fullname}</div>
                        <div className="l-full-name">{lead.business_name}</div>
                        <div className="l-email">{lead.email}</div>
                        {!lead.deleted_at && (
                            <>
                                <Grid.Column className="circle-button-groups">
                                    <div className={"ui secondary menu leadnotes"}>
                                        {/* <Button circular className='email icon'
                                                as='a' href={`mailto:${lead.email}`} ><Icon className='ti-mail-forward ti icon'/></Button> */}
                                        <Button
                                            circular
                                            className="email icon"
                                            onClick={this.props.showModal.bind(this, {
                                                ...lead,
                                                company_id: lead.company.id,
                                                show: true
                                            })}
                                        >
                                            <Icon className="ti-mail-forward ti icon" />
                                        </Button>
                                        {twilioToken && lead.phone && (
                                            <>
                                                <Button
                                                    circular
                                                    className={onPhone ? "icon endCall" : "icon onCall"}
                                                    onClick={this.onCall}
                                                >
                                                    <Icon className="ti-phone ti icon" />
                                                </Button>
                                                <Button circular className="sms icon" onClick={this.setSMSModal}>
                                                    <Icon className="ti-device-mobile-message ti icon" />
                                                </Button>
                                            </>
                                        )}

                                        <Button
                                            circular
                                            className="editlead icon"
                                            onClick={this.props.loadForm.bind(this, {
                                                ...lead,
                                                company_id: lead.company.id,
                                                show: true
                                            })}
                                        >
                                            <Icon className="ti-pencil ti icon" />
                                        </Button>
                                    </div>
                                </Grid.Column>
                                {this.state.showSMSModal && (
                                    <Form>
                                        <Form.Field>
                                            <label className="sendtexthead">Send a text message</label>

                                            <JoditEditor
                                                ref={editor}
                                                value={form.message || ""}
                                                config={config}
                                                tabIndex={1}
                                                onChange={this.onChangeSMSMessage}
                                                name="message"
                                                className="jodit-sms"
                                            />

                                            {/* <TextArea
                                                className="text-sms"
                                                name="message"
                                                onChange={this.onChangeSMSMessage}
                                            /> */}
                                        </Form.Field>
                                        <Button.Group>
                                            <Button onClick={this.onCancelSendSMS}>Cancel</Button>
                                            <Button.Or />
                                            <Button type="button" onClick={this.onSendSMS} positive>
                                                Send
                                            </Button>
                                        </Button.Group>
                                    </Form>
                                )}
                            </>
                        )}

                        <TimeLine
                            notes={leadNotes}
                            lead={lead}
                            onAddNote={this.onAddNote}
                            leadStatuses={leadStatuses}
                        />
                    </div>
                </Segment>
            </div>
        );
    }
}

export default compose(
    BreadCrumbContainer,
    LeadsContainer,
    LeadNotesContainer,
    LeadFormContainer,
    EmailFormContainer
)(LeadNotes);
