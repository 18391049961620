export const LOAD_PIPELINE = "LOAD_PIPELINE";
export const CHANGE_ACTIVE_PIPELINE = "CHANGE_ACTIVE_PIPELINE";
export const PIPELINE_FORM = "PIPELINE_FORM";
export const CHANGE_DRAG_ACTION = "CHANGE_DRAG_ACTION";

export const loadPipelines = pipelines => ({
    type: LOAD_PIPELINE,
    pipelines
});

export const changeActivePipeline = pipeline => ({
  type: CHANGE_ACTIVE_PIPELINE,
  pipeline
});

export const pipelineForm = openForm => ({
  type: PIPELINE_FORM,
  openForm
});

export const changeDragAction = newItems => ({
  type: CHANGE_DRAG_ACTION,
  newItems
});
