import { api, Auth } from "@services";

export const fetchPipelines = params => {
    return api.get(`/v1/pipeline`, {
        params
    });
};

export const createPipeline = pipeline => {
    return api.post(`/v1/pipeline`, {
        pipeline
    });
};

export const updatePipeline = pipeline => {
    return api.put(`/v1/pipeline/${pipeline.id}`, {
        pipeline
    });
};

export const changeDragAction = (leads, pipelineId) => {
    return api.post(`/v1/agency/drag-update`, {
        leads,
        pipelineId
    });
};

export const createUpdateAutoStage = param => {
    return api.post(`/v1/pipeline/auto-stage-update`, {
        param
    });
};

export const deleteStage = id => {
    return api.delete(`/v1/pipeline/delete-stage/${id}`);
};

export const deletePipeline = id => {
    return api.delete(`/v1/pipeline/${id}`);
};
