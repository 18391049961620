import { api, Auth } from "@services";

export const getAssist = async uuid => {
    const res = await api.get(`/v1/assist/${uuid}`);
    return res.data;
};

export const createNewThread = async uuid => {
    try {
        const response = await api.post(`/v1/thread`, { uuid });
        return response.data;
    } catch (err) {
        console.log(err.message);
    }
};

export const fetchThread = async (threadId, uuid) => {
    try {
        const response = await api.get(`/v1/thread/${threadId}/${uuid}`);
        return response.data;
    } catch (err) {
        console.log(err.message);
    }
};

export const fetchRun = async (threadId, runId) => {
    try {
        const response = await api.get(`/v1/thread-retrive/${threadId}/${runId}`);
        return response.data;

        // let response = await fetch(`http://localhost:8000/api/threads/${threadId}/runs/${runId}`);
        // return response.json();
    } catch (err) {
        console.log(err.message);
    }
};

export const postMessage = async (threadId, msg) => {
    try {
        const response = await api.post(`/v1/thread/${threadId}/message`, { msg });
        return response.data;
    } catch (err) {
        console.log(err.message);
    }
};

export const postToolResponse = async (threadId, runId, toolResponses) => {
    try {
        let response = await fetch(`http://localhost:8000/api/threads/${threadId}/runs/${runId}/tool`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(toolResponses)
        });
        return response.json();
    } catch (err) {
        console.log(err.message);
    }
};

export const sendEmailNameApi = async (threadId, runId, payload, response) => {
    try {
        const res = await api.post(`/v1/assistant-collect/${threadId}/${runId}`, { payload, response });
        return res.data;
        // let response = await fetch(`http://localhost:8000/api/threads/${threadId}/runs/${runId}`);
        // return response.json();
    } catch (err) {
        console.log(err.message);
    }
};
