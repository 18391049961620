import React, { Component } from "react";
import {
    Form,
    Input,
    Select,
    TextArea
    // Button,
} from "semantic-ui-react";
// import "./index.scss";
import { Auth } from "@services";
import { compose } from "react-recompose";
import { FBFolderFormContainer, CompaniesContainer } from "@containers";

class FbFolderForm extends Component {
    componentWillMount() {
        if (Auth.role === "agency") {
            this.props.loadSelectBoxCompanies();
        }
    }

    onChangeFirst = e => {
        this.props.changeForm({ changeable: e.target.value });
    };


    onChangeField = data => {
        this.props.changeForm({ [data.name]: data.value });
    };

    render() {
        const { name, description, default_msg, user_id } = this.props.form;
        const { selectBoxCompanies } = this.props;

        return (
            <Form className="domainForm">
                <Form.Field required>
                    <label>Name</label>
                    <Form.Field
                        control={Input}
                        placeholder={"Folder Name"}
                        value={name}
                        name="name"
                        onChange={(e, data) => this.onChangeField(data)}
                    />
                </Form.Field>
                {Auth.role === "agency" && (
                    <Form.Field required>
                        <label>Company</label>
                        <Select
                            placeholder="Select Company"
                            value={user_id}
                            name="user_id"
                            options={selectBoxCompanies}
                            onChange={(e, data) => this.onChangeField(data)}
                        />
                    </Form.Field>
                )}
                <Form.Field required>
                    <label>Description</label>
                    <TextArea
                        name="description"
                        onChange={(e, data) => this.onChangeField(data)}
                        value={description || ""}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Preloaded messenger content(optional);</label>
                    <TextArea
                        name="default_msg"
                        onChange={(e, data) => this.onChangeField(data)}
                        value={default_msg || ""}
                    />
                </Form.Field>
            </Form>
        );
    }
}

export default compose(FBFolderFormContainer, CompaniesContainer)(FbFolderForm);
