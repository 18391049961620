export const getCurrencySymbol = currency => {
    if (currency === "EUR") {
        return "€";
    }
    if (currency === "GBP") {
        return "£";
    }
    return "$";
};

export const stringToJson = str => {
    if (!str) {
        return [];
    }
    return JSON.parse(str);
};

export const htmlToText = html => {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
};
