import React, { Component } from "react";
import {
    Grid,
    List,
    ListItem,
    ListContent,
    ListHeader,
    Form,
    FormField,
    Button,
    Input,
    Icon,
    Segment,
    Checkbox,
    TextArea
} from "semantic-ui-react";

import "./index.scss";
// import defaultAvatarIcon from " images/user.png";

class Appearence extends Component {
    constructor(props) {
        super(props);
        this.state = {
            avatar: "",
            button_icon: ""
        };
    }

    initLoad = () => {
        const { activeAssistant, assistantList } = this.props;
        const assistant = assistantList.find(ass => ass.id == activeAssistant);
        if (assistant) {
            this.props.changeForm({
                ...assistant,
                type: "edit"
            });
        }
    };

    componentDidMount() {
        this.initLoad();
    }

    handleCoreChange = e => {
        const { name, value } = e.target;
        this.props.changeForm({
            [name]: value
        });
    };

    handleChange = (e, data) => {
        const { name, value } = data;
        this.props.changeForm({
            [name]: value
        });
    };

    handleFileChange = (event, type) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.addEventListener(
                "load",
                () => {
                    this.props.changeForm({ [type]: reader.result });
                },
                false
            );
        }
    };

    handleToggle = (e, data) => {
        const { checked } = e.target;
        this.props.changeForm({
            show_branding: checked
        });
    };

    handleSubmit = () => {
        this.props.saveForm(this.props.form, "appeareance");
    };

    getContrastColor = hexColor => {
        // Convert hex color to RGB
        let r = parseInt(hexColor.substr(1, 2), 16);
        let g = parseInt(hexColor.substr(3, 2), 16);
        let b = parseInt(hexColor.substr(5, 2), 16);

        // Calculate relative luminance
        let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        // Use a threshold to determine whether to use black or white text
        return luminance > 0.5 ? "black" : "white";
    };

    render() {
        const { form, activeAssistant } = this.props;
        const formData = form.form;
        const { name, chat_placeholder, accent_color, avatar, button_icon, button_text, show_branding } = formData;
        return (
            <Grid className="h-100 assistant-container assistant-appearence">
                <Grid.Row className="container-row">
                    <Grid.Column className="left-bar">
                        <List divided verticalAlign="middle">
                            <ListItem>
                                <List.Icon className="ti ti-paint" />
                                <ListContent>
                                    <ListHeader>
                                        <strong> Chat Appeareance</strong>
                                    </ListHeader>
                                    <p>Customize your widget to match your brand</p>
                                </ListContent>
                            </ListItem>
                            <ListItem>
                                <Form onSubmit={this.handleSubmit}>
                                    <FormField>
                                        <label>Assistant name</label>
                                        <input
                                            placeholder="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={this.handleCoreChange}
                                        />
                                        <span>This name will be displayed at the top of the widget</span>
                                    </FormField>
                                    <FormField>
                                        <label>Chat placeholder</label>
                                        <input
                                            placeholder="how can I help you today ?"
                                            name="chat_placeholder"
                                            value={chat_placeholder || ""}
                                            onChange={this.handleCoreChange}
                                        />
                                        <span>This is the default text on the chat message field</span>
                                    </FormField>
                                    <FormField>
                                        <label>Accent color</label>
                                        <div className="accent-color-container">
                                            <Input
                                                iconPosition="left"
                                                placeholder="Accent color"
                                                className="accent-color-input"
                                                onChange={this.handleChange}
                                                name="accent_color"
                                                value={accent_color || "#4d77ff"}
                                            >
                                                <span name="at"></span>
                                                <input />
                                            </Input>
                                            <input
                                                type="color"
                                                name="accent_color"
                                                value={accent_color || "#4d77ff"}
                                                className="accent-color-panel"
                                                onChange={this.handleCoreChange}
                                            />
                                        </div>
                                        <span>
                                            Select the main color used for highlights and buttons inside the chat widget
                                        </span>
                                    </FormField>
                                    <FormField className="file-input">
                                        <label>Avatar</label>
                                        <div className="d-flex align-items-center files-upload">
                                            <img src={avatar ? avatar : "/img/user.png"} />

                                            <Button as="label" htmlFor="avatar-file" type="button">
                                                Choose File
                                            </Button>
                                            <input
                                                type="file"
                                                id="avatar-file"
                                                hidden
                                                onChange={e => this.handleFileChange(e, "avatar")}
                                            />
                                        </div>
                                        <span>This is optional and will be displayed on op of the widget window</span>
                                    </FormField>
                                    <FormField className="file-input">
                                        <label>Button icon</label>
                                        <div className="d-flex align-items-center files-upload btn-icon">
                                            <div className="rounded">
                                                <img src={button_icon ? button_icon : "/img/widgetbut.svg"} alt="" />
                                            </div>

                                            <Button as="label" htmlFor="btn-icon-file" type="button">
                                                Choose File
                                            </Button>
                                            <input
                                                type="file"
                                                id="btn-icon-file"
                                                hidden
                                                onChange={e => this.handleFileChange(e, "button_icon")}
                                            />
                                        </div>
                                        <span>
                                            This is the icon displayed on the button that opens the chat widget.w
                                        </span>
                                    </FormField>
                                    <FormField>
                                        <label>Button text</label>
                                        <input
                                            placeholder="Button text"
                                            value={button_text}
                                            name="button_text"
                                            onChange={this.handleCoreChange}
                                        />
                                        <span>
                                            This text will be placed above your chat button. Leave this field empty if
                                            you don't wanna use it
                                        </span>
                                    </FormField>
                                    <FormField className="branding-container">
                                        <div className="branding-half">
                                            <label htmlFor="show_branding">Show branding</label>
                                            <span>Show "powered by convertlead" text. {show_branding} </span>
                                        </div>
                                        <Checkbox
                                            toggle
                                            id="show_branding"
                                            onChange={e => this.handleToggle(e)}
                                            checked={show_branding == 1 ? true : false}
                                        />
                                    </FormField>
                                    <Button type="submit" positive>
                                        Save changes
                                    </Button>
                                </Form>
                            </ListItem>
                        </List>
                    </Grid.Column>
                    <Grid.Column className="right-bar">
                        <div className="bot-wrapper">
                            <div className="bot-container">
                                <div className="bot-header">
                                    <div className="avatar">
                                        <img src={avatar ? avatar : "/img/user.png"} alt="" width={30} />
                                        <span className="assistant-name">{name}</span>
                                    </div>
                                    <i className="ti ti-x"></i>
                                </div>
                                <div className="body-content">
                                    <div className="msg-left">
                                        <p
                                            style={{
                                                backgroundColor: accent_color || "#4d77ff",
                                                color: this.getContrastColor(accent_color || "#4d77ff")
                                            }}
                                        >
                                            hey, how can I help you ?
                                        </p>
                                    </div>
                                    <div className="msg-right">
                                        <p>Hey, I need your help setting up my convertlead account.</p>
                                    </div>
                                </div>
                                <div className="flex-grow-1"></div>
                                <div className="chat-input">
                                    <Form>
                                        <TextArea placeholder={chat_placeholder || ""} rows={1} disabled />
                                        <i className="ti ti-send"></i>
                                    </Form>
                                </div>
                                {!!show_branding && (
                                    <div className="powered text-center">
                                        powered by{" "}
                                        <a href="https://convertlead.com">
                                            <img src="https://app.convertlead.com/img/logo.png"></img> Convertlead
                                        </a>
                                    </div>
                                )}
                            </div>
                            <div className="open-btn">
                                <span>{!!button_text && button_text}</span>
                                <Button
                                    className="rounded"
                                    type="primary"
                                    style={{ background: accent_color || "#4d77ff" }}
                                >
                                    <img src={button_icon ? button_icon : "/img/widgetbut.svg"} alt="" width={30} />
                                </Button>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default Appearence;
