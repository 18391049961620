import React, { Component } from "react";
import { compose } from "react-recompose";
import "./index.scss";
import Tour from "../tour";
import { Label, Segment, Grid, Menu, Input, Button, Form, Select } from "semantic-ui-react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip
    // Cell ,Legend, ResponsiveContainer
} from "recharts";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import moment from "moment";

class PipelineSummary extends Component {
    pipelineObj = id => {
        const pipeline = this.props.pipelines.find(i => i.id === id);
        if (!pipeline) {
            return this.props.pipelines[0];
        }
        return pipeline;
    };

    render() {
        const { activePipelineList, activePipeline, summary, endDate, startDate, companyId } = this.props;
        let pipelineList = activePipelineList.map(pipeline => ({
            key: pipeline.id,
            value: pipeline.id,
            text: pipeline.name
        }));

        if (pipelineList.length === 0) {
            return (
                <Segment basic>
                    <div className="d-flex justify-between w-100">
                        <strong>Pipeline summary</strong>
                        <Form>
                            <Form.Field
                                control={Select}
                                className="sortby filter white wrapper"
                                options={pipelineList}
                                value={pipeline}
                                search
                                searchInput={{ id: "pipeline-list" }}
                                onChange={this.props.onChagngePipeline}
                          />
                        </Form>
                    </div>
                    <div class="ui three column grid pipeline-stats-wrap">
                        <div class="row w-100-card">
                            <div class="column">
                                <div class="ui card">
                                    <div class="content">
                                        <div class="header">Daily leads</div>
                                        <div className="mt-3">No Data</div>
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="ui card">
                                    <div class="content">
                                        <div class="header">Stages</div>
                                        <div class="description">No Data</div>
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="ui card">
                                    <div class="content">
                                        <div className="d-flex justify-between">
                                            <div class="header">Pipeline value</div>
                                        </div>
                                        <div className="text-center mt-3">No Data</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Segment>
            );
        }

        const pipeline = activePipeline ? activePipeline : activePipelineList[0].id;
        const leads = summary.leads;
        const stageLeads = {};
        const stageValues = {};

        let totalLeads = 0;
        let totalValues = 0;
        let soldLead = 0;

        const leadByDate = {};
        leads.map(lead => {
            if (lead.pipeline_id == pipeline) {
                totalLeads++;
                totalValues = totalValues + lead.value;

                if (stageLeads[lead.stage_id]) {
                    stageLeads[lead.stage_id] = stageLeads[lead.stage_id] + 1;
                } else {
                    stageLeads[lead.stage_id] = 1;
                }

                if (stageValues[lead.stage_id]) {
                    stageValues[lead.stage_id] = stageValues[lead.stage_id] + lead.value;
                } else {
                    stageValues[lead.stage_id] = lead.value;
                }

                if (lead.status == "SOLD") {
                    soldLead++;
                }

                const leadDate = moment(lead.created_at).format("Y-MM-DD");
                if (leadByDate[leadDate]) {
                    leadByDate[leadDate] = leadByDate[leadDate] + 1;
                } else {
                    leadByDate[leadDate] = 1;
                }
            }
        });
        const conversionRate = totalLeads == 0 ? 0 : Math.round((soldLead / totalLeads) * 100);

        const leadDateList = [];
        const currentDate = moment(startDate);
        const finalDate = moment(endDate);

        while (currentDate.isSameOrBefore(finalDate)) {
            leadDateList.push(currentDate.format("Y-MM-DD"));
            currentDate.add(1, "days");
        }

        const leadBarDate = [];
        leadDateList.map(leadDate => {
            leadBarDate.push({
                name: moment(leadDate).format("MM/DD"),
                lead_count: leadByDate[leadDate] ? leadByDate[leadDate] : 0
            });
        });

        return (
            <Segment basic>
                <div className="d-flex justify-between w-100">
                    <Label className="stats-headline">
                        Pipeline summary
                    </Label>

                    <Form className="filter white wrapper">
                        <Form.Field
                            control={Select}
                            className="sortby"
                            options={pipelineList}
                            value={pipeline}
                            search
                            searchInput={{ id: "pipeline-list" }}
                            onChange={this.props.onChagngePipeline}
                        />
                    </Form>
                </div>
                <div class="ui three column grid pipeline-stats-wrap">
                    <div class="row w-100-card">
                        <div class="column">
                            <div class="ui card">
                                <div class="content">
                                <div className="d-flex justify-between">
                                    <div class="header small">Leads and conversions</div>
                                    </div>
                                    <div className="mt-3">
                                        <BarChart
                                            width={350}
                                            height={270}
                                            data={leadBarDate}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 0,
                                                bottom: 5
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis width={40} />
                                            <Tooltip />
                                            <Bar dataKey="lead_count" name="Total Leads" fill="#4d77ff" barSize={5} />
                                        </BarChart>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="ui card">
                                <div class="content">
                                <div className="d-flex justify-between">
                                    <div class="header small">Stages</div>
                                    </div>
                                    <div class="description">
                                        <ul className="pipeline-stage">
                                            {this.pipelineObj(pipeline) &&
                                                this.pipelineObj(pipeline).stages &&
                                                this.pipelineObj(pipeline)
                                                    .stages.sort((a, b) => (a.priority > b.priority ? 1 : -1))
                                                    .map(stage => (
                                                        <li key={stage.id}>
                                                            <p>{stage.name}</p>
                                                            <div class="stage-stats"><p>
                                                                <i className="ti ti-user"></i>{" "}
                                                                {stageLeads[stage.id] ? stageLeads[stage.id] : 0}
                                                            </p>
                                                            <p>$ {stageValues[stage.id] ? stageValues[stage.id] : 0}</p></div>
                                                        </li>
                                                    ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="ui card pipeline-chart">
                                <div class="content">
                                    <div className="d-flex justify-between">
                                        <div class="header small">Pipeline value</div>
                                        <div className="d-flex pipeline-small-stats">
                                            <i className="ti ti-user"></i> {totalLeads}
                                           <span className="totalearnings-small"> <i className="ti ti-database"></i> $ {totalValues}</span>
                                        </div>
                                    </div>
                                    <div className="text-center mt-3">
                                        <div class="description">
                                            <Label className="blue-wrapper"> Projected earnings</Label>
                                            <h4>${(conversionRate * totalValues) / 100}</h4>
                                        </div>
                                        <div className="ring-chart">
                                            <div className="ring-chart-text "> CONVERSION RATE </div>
                                            <CircularProgressbar
                                                value={conversionRate}
                                                strokeWidth={10}
                                                text={`${conversionRate} %`}
                                                circleRatio={0.5}
                                                styles={buildStyles({
                                                    // Rotation of path and trail, in number of turns (0-1)
                                                    rotation: 3 / 4,
                                                    textSize: "12px",
                                                    pathColor: `#4a74ff, ${conversionRate / 100})`,
                                                    textColor: "black",
                                                    
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Segment>
        );
    }
}

export default PipelineSummary;
