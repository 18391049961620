//Modal Open
export const UPLOAD_DONE = "UPLOAD_DONE";
export const UPLOAD_START = "UPLOAD_START";
export const ADD_FILE_LIST = "ADD_FILE_LIST";
export const REMOVE_DONE = "REMOVE_DONE";
export const FORMAT_LIST = "FORMAT_LIST";

export const uploadFileDoneAction = payload => ({
    type: UPLOAD_DONE,
    payload
});

export const uploadFileAddListAction = payload => ({
    type: ADD_FILE_LIST,
    payload
});

export const uploadFileStartAction = () => ({
    type: UPLOAD_START
});

export const uploadRemoveAction = payload => ({
    type: REMOVE_DONE,
    payload
});

export const formatListAction = () => ({
    type: FORMAT_LIST
});
