import React, { Component } from "react";
import { compose } from "react-recompose";
import { BreadCrumbContainer } from "@containers";
import "./index.scss";
import Tour from "../tour";
import { Label, Segment, Grid, Menu, Input, Button, Form, Header } from "semantic-ui-react";

class LeadOverview extends Component {
    render() {
        const { summary, pagination } = this.props;

        return (
            <Segment basic>
                <div className="text-right">
                    <Label className="stats-headline">Leads by status</Label>
                </div>
                <div class="ui four column grid lead-stats-wrap">
                    <div class="row">
                        <div class="column">
                            <div class="ui card">
                                <div class="content">
                                    <div class="total icon-box"><i class="ti ti-user"></i></div>
                                    <div class="meta prehead">Total Leads</div>
                                     <div class="header flex">{summary.total}
                                    {summary.totalIncreaseCount >= 0 ?  
                                            (
                                                <div class="meta blue"><i className="ti ti-arrow-up"></i>+ {Math.abs(summary.totalIncreasePercent)}% </div>                                 
    
                                     ) : (
                                                <div class="meta red"> <i className="ti ti-arrow-down"></i>- {Math.abs(summary.totalIncreasePercent)}%</div>
                                        )}
                                       
                                    </div>
                                    <div class="description">
                                        {summary.totalIncreaseCount >= 0 ? (<span className='blue'>+ {Math.abs(summary.totalIncreaseCount)}</span>) : (<span className='red'>- {Math.abs(summary.totalIncreaseCount)}</span>)}
                                       {" "} vs previous 30 days
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="ui card">
                                <div class="content">
                                 <div class="contacted icon-box"><i class="ti ti-mail"></i></div>
                                    <div class="meta prehead">Contacted</div>
                                    <div class="header flex">{summary.contacted}
                                    {summary.contactedIncreaseCount >= 0 ?  
                                            (
                                                <div class="meta blue"><i className="ti ti-arrow-up"></i>+ {Math.abs(summary.contactedIncreasePercent)}% </div>                                 
    
                                     ) : (
                                                <div class="meta red"> <i className="ti ti-arrow-down"></i>- {Math.abs(summary.contactedIncreasePercent)}%</div>
                                        )}
                                       
                                    </div>
                                    <div class="description">
                                        {summary.contactedIncreaseCount >= 0 ? (<span className='blue'>+ {Math.abs(summary.contactedIncreaseCount)}</span>) : (<span className='red'>- {Math.abs(summary.contactedIncreaseCount)}</span>)}
                                       {" "}
                                        vs previous 30 days
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="ui card">
                                <div class="content">
                                <div class="missed icon-box"><i class="ti ti-circle-x"></i></div>
                                    <div class="meta prehead">Missed</div>
                                     <div class="header flex">{summary.missed}
                                    {summary.missedIncreaseCount >= 0 ?  
                                            (
                                                <div class="meta blue"><i className="ti ti-arrow-up"></i>+ {Math.abs(summary.missedIncreasePercent)}% </div>                                 
    
                                     ) : (
                                                <div class="meta red"> <i className="ti ti-arrow-down"></i>- {Math.abs(summary.missedIncreasePercent)}%</div>
                                        )}
                                       
                                    </div>
                                    <div class="description">

                                     {summary.missedIncreaseCount >= 0 ? (<span className='blue'>+ {Math.abs(summary.missedIncreaseCount)}</span>) : (<span className='red'>- {Math.abs(summary.missedIncreaseCount)}</span>)}
                                       {" "}
                                        vs previous 30 days
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="ui card">
                                <div class="content">
                                    <div class="sold icon-box"><i class="ti ti-database"></i></div>
                                    <div class="meta prehead">Sold</div>
                                    <div class="header flex">{summary.sold}
                                    {summary.soldIncreaseCount >= 0 ?  
                                            (
                                                <div class="meta blue"><i className="ti ti-arrow-up"></i>+ {Math.abs(summary.soldIncreasePercent)}% </div>                                 
    
                                     ) : (
                                                <div class="meta red"> <i className="ti ti-arrow-down"></i>- {Math.abs(summary.soldIncreasePercent)}%</div>
                                        )}
                                       
                                    </div>
                                    <div class="description">
                                         {summary.soldIncreaseCount >= 0 ? (<span className='blue'>+ {Math.abs(summary.soldIncreaseCount)}</span>) : (<span className='red'>- {Math.abs(summary.soldIncreaseCount)}</span>)}
                                       {" "}
                                        vs previous 30 days
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Segment>
        );
    }
}

export default compose(BreadCrumbContainer)(LeadOverview);
