import React, { Component } from "react";
import { Grid, Table, TableHeader, TableRow, TableHeaderCell, TableCell, TableBody } from "semantic-ui-react";
import { AssistantFormContainer, ProfileContainer } from "@containers";
import * as moment from "moment";

import "./index.scss";
import { compose } from "react-recompose";

class Knowledge extends Component {
    constructor(props) {
        super(props);
    }

    distanceTwoDates = (startDate, isUnix = false) => {
        if (!startDate) {
            return;
        }
        let date1 = startDate;
        if (!isUnix) {
            date1 = moment(startDate);
        } else {
            date1 = moment.unix(startDate);
        }
        const date2 = moment();

        const daysDifference = date2.diff(date1, "days");
        if (daysDifference > 0) {
            return daysDifference + "days ago";
        }
        const hoursDiff = date2.diff(date1, "hours");
        if (hoursDiff > 0) {
            return hoursDiff + " hours ago";
        }
        const minsDiff = date2.diff(date1, "minutes");
        console.log({ minsDiff });
        if (minsDiff > 0) {
            return minsDiff + " minutes ago";
        }
        const secsDiff = date2.diff(date1, "seconds");
        return secsDiff + " seconds ago";
    };

    render() {
        const { activeAssistant, assistantList, profile } = this.props;
        const assistant = assistantList.find(ass => ass.id == activeAssistant);

        return (
            <Grid className="h-100 assistant-knowledge">
                <Grid.Column width={16} className="header">
                    <div className="d-flex align-items-center">
                        <i className="ti ti-writing"></i>
                        <div>
                            <strong>Train your {assistant.name}</strong>
                            <p>Add knowledge sources that will be used to train your agent</p>
                        </div>
                    </div>
                    <button
                        type="button"
                        onClick={this.props.showModal.bind(this, {
                            show: true,
                            type: "knowledge",
                            id: activeAssistant
                        })}
                    >
                        <i className="flaticon stroke plus-1"></i>
                    </button>
                </Grid.Column>
                <Grid.Column className="main-form" width={16}>
                    <div className="ai-count">
                        A.I. credits: <span className="blue-highlight">{profile.ai_token}/{profile.max_ai_token}</span>
                    </div>
                    <div>
                        <Table stackable>
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell>Type</TableHeaderCell>
                                    <TableHeaderCell>Byte</TableHeaderCell>
                                    <TableHeaderCell>Name</TableHeaderCell>
                                    <TableHeaderCell>Status</TableHeaderCell>
                                    <TableHeaderCell textAlign="right">Actions</TableHeaderCell>
                                </TableRow>
                            </TableHeader>

                            <TableBody>
                                {assistant.files &&
                                    assistant.files.map(file => (
                                        <TableRow key={file.id}>
                                            <TableCell>
                                                <i className="ti ti-file-text"></i>
                                            </TableCell>
                                            <TableCell>
                                                <p>{file.filename}</p>
                                                <p>{this.distanceTwoDates(file.opean_ai_created_at, true)}</p>
                                            </TableCell>
                                            <TableCell>{file.byte} bytes </TableCell>
                                            <TableCell>
                                                <span></span> {file.status}
                                            </TableCell>
                                            <TableCell textAlign="right">
                                                <button
                                                    className="actions button"
                                                    onClick={() =>
                                                        this.props.manageKnowledge({
                                                            type: "redeploy",
                                                            id: file.id
                                                        })
                                                    }
                                                >
                                                    <i className="ti ti-refresh"></i>
                                                </button>
                                                <button
                                                    className="actions button"
                                                    onClick={() =>
                                                        this.props.manageKnowledge({
                                                            type: "delete",
                                                            id: file.id
                                                        })
                                                    }
                                                >
                                                    <i className="ti ti-trash"></i>
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </div>
                </Grid.Column>
            </Grid>
        );
    }
}

export default compose(AssistantFormContainer, ProfileContainer)(Knowledge);
