export const LOAD_PAGE_LIST = "LOAD_PAGE_LIST";
export const LOAD_PROP_LIST = "LOAD_PROP_LIST";
export const LOAD_LINKEDIN_LIST = "LOAD_LINKEDIN_LIST";
export const FB_LOAD_MORE = "FB_LOAD_MORE";
export const FB_ACCESS_TOKEN = "FB_ACCESS_TOKEN";
export const FB_FOLDER_LIST = "FB_FOLDER_LIST";
export const SET_PAGE_LIST = "SET_PAGE_LIST";

export const loadPropList = list => ({
    type: LOAD_PROP_LIST,
    list
});

export const loadPageList = pageBody => ({
    type: LOAD_PAGE_LIST,
    pageBody
});

export const loadLinkedinList = list => ({
    type: LOAD_LINKEDIN_LIST,
    list
});

export const fbLoadMore = pageBody => ({
    type: FB_LOAD_MORE,
    pageBody
});

export const setFBAccessToken = token => ({
    type: FB_ACCESS_TOKEN,
    token
});

export const setFolderList = list => ({
    type: FB_FOLDER_LIST,
    list
});

export const setPageList = folderPageList => ({
    type: SET_PAGE_LIST,
    folderPageList
});
