import React, { Component } from "react";
import { Form, Input, TextArea, Select, Grid, Label } from "semantic-ui-react";
import "./index.scss";
import { Auth, api } from "@services";
import { disableAutoComplete } from "../../../../utils";
import { stringToJson } from "../../../../utils/common";

class LeadForm extends Component {
    state = {
        pipelineList: [],
        pipeline: null,

        value: 0
    };

    componentWillMount() {
        if (this.props.form.id) {
            this.props.filterDealsByDealId(this.props.form.deal_id);
            this.props.filterDealsByCompany(this.props.form.company_id);
            this.props.filterDealCampaignsById(this.props.form.deal_campaign_id);
        }
        if (Auth.isAgency) {
            this.props.loadSelectBoxCompanies();
        }
        if (Auth.isCompany) {
            this.props.changeForm({ company_id: this.props.profile.id });
        }
        if (!Auth.isAgent) {
            this.props.getCompanyDeals();
        }
        if (Auth.isAgent) {
            this.props.changeForm({ agent_id: this.props.profile.id });
        }
    }

    onChange = (event, data) => {
        this.props.changeForm({ [data.name]: data.value });
    };

    onChangeCompany = (event, data) => {
        this.props.changeForm({ company_id: data.value });
        if (!Auth.isAgent) {
            this.props.filterDealsByCompany(data.value);
            this.props.filterDealsByDealId("");
            this.props.loadSelectBoxCompanies("");
        }
        if (Auth.isAgent) {
            this.props.loadCompanyCampaigns(data.value);
        }
    };

    onChangeStatus = (event, data) => {
        this.props.changeForm({ status: data.value });
    };

    onSearchChange = event => {
        this.props.searchCompanies(event.target.value);
    };

    onChangeDeal = (event, data) => {
        console.log("data.value->> campaing id", data.value)
        this.props.filterDealsByDealId(data.value);
        this.props.filterDealCampaignsById("");

        // this.props.changeForm("campaign_id", data.value);

        const campaigns = this.props.selectBoxDeals;
        const campaign = campaigns.find(item => item.value === data.value);
        const pipeline_id = campaign.pipeline_id;

        if (!this.props.form.id) {
            this.props.changeForm({ value: campaign.pipelines_value });
        }

        const pipeline = this.state.pipelineList.find(item => item.id === pipeline_id);
        this.setState({
            pipeline
        });
    };

    onChangeCampaign = (event, data) => {
        this.props.changeForm({ deal_campaign_id: data.value });
        if (!Auth.isAgent) {
            this.props.filterDealCampaignsById(data.value);
        } else {

            const company = this.props.companies.find(com => com.id === this.props.form.company_id);
            const integration = company.campaigns.find(cam => data.value === cam.id);
            console.log({integration})
            const deal = company.deals.find(deal => deal.id == integration.deal_id);
            console.log({deal})

            console.log(this.state.pipelineList)
            this.props.changeForm({ value: deal.value });


            const pipeline = this.state.pipelineList.find(pipe => pipe.id == deal.pipeline_id);
            console.log({pipeline})
            
            this.setState({
                pipeline
            })
        }
    };

    onChangeAgent = (event, data) => {
        this.props.changeForm({ agent_id: data.value });
    };

    handleChangeValue = (event, { name, value }) => {
        this.props.changeForm({ value: value });
        this.setState({
            value
        });
    };

    getPipelineList = async () => {
        const res = await api.get(`/v1/pipeline`);

        const campaigns = this.props.selectBoxDeals || [];
        const campaignId = this.props.form.deal_id;
        const campaign = campaigns.find(item => item.value === campaignId);

        if (!this.props.form.id && campaign && campaign.pipelines_value) {
            this.props.changeForm({ value: campaign.pipelines_value });
        }

        if (campaign && campaign.pipeline_id) {
            const pipeline = res.data.find(item => item.id === campaign.pipeline_id);
            this.setState({
                pipeline,
                pipelineList: res.data
            });
        } else {
            this.setState({
                pipelineList: res.data
            });
        }
    };

    componentDidMount() {
        disableAutoComplete();
        this.getPipelineList();
    }

    getCurrencySymbol = currency => {
        if (currency === "EUR") {
            return "€";
        }
        if (currency === "GBP") {
            return "£";
        }
        return "$";
    };

    getStageList = () => {
        const { pipeline } = this.state;

        if (!pipeline) {
            return [];
        }
        return pipeline.stages.map(item => ({
            value: item.id,
            key: "pipeline-" + item.id,
            text: item.name
        }));
    };

    onChangeStage = (e, { value }) => {
        this.props.changeForm({ stage_id: value });
    };

    render() {
        const { fullname, email, phone, metadata, stage_id, address, website, value, business_name } = this.props.form;
        const { pipeline } = this.state;

        return (
            <Form size="big" className="leadForm">
                <Grid columns={2} relaxed="very" stackable>
                    <Grid.Column>
                        <Form.Field required>
                            <label>Full name</label>
                            <Input
                                placeholder="Full Name"
                                value={fullname || ""}
                                name="fullname"
                                onChange={this.onChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Business name</label>
                            <Input
                                placeholder="Business Name"
                                value={business_name || ""}
                                name="business_name"
                                onChange={this.onChange}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>Email Address</label>
                            <Input
                                placeholder="Email Address"
                                value={email || ""}
                                name="email"
                                onChange={this.onChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Phone Number</label>
                            <Input
                                placeholder="Phone Number"
                                value={phone || ""}
                                name="phone"
                                onChange={this.onChange}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Address</label>
                            <TextArea
                                placeholder="Address"
                                value={address || ""}
                                name="address"
                                onChange={this.onChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Website</label>
                            <Input
                                placeholder="Website"
                                value={website || ""}
                                name="website"
                                onChange={this.onChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Description</label>
                            <TextArea
                                placeholder="Description"
                                value={metadata || ""}
                                name="metadata"
                                onChange={this.onChange}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field
                            required
                            control={Select}
                            options={this.props.selectBoxStatuses || []}
                            label={{ children: "Statuses", htmlFor: "status-list" }}
                            placeholder="Select status"
                            search
                            defaultValue={this.props.form.status || null}
                            onChange={this.onChangeStatus}
                            searchInput={{ id: "status-list" }}
                        />
                        {Auth.isAgency || Auth.isAgent ? (
                            <Form.Field
                                required
                                // loading={!this.props.selectBoxCompanies.length}
                                control={Select}
                                options={this.props.selectBoxCompanies || []}
                                label={{ children: "Company", htmlFor: "companies-list" }}
                                placeholder="Select company"
                                search
                                defaultValue={this.props.form.company_id || null}
                                onChange={this.onChangeCompany}
                                onSearchChange={this.onSearchChange}
                                searchInput={{ id: "companies-list" }}
                            />
                        ) : null}
                        {!Auth.isAgent ? (
                            <Form.Field
                                required
                                control={Select}
                                options={this.props.selectBoxDeals || []}
                                label={{ children: "Campaign", htmlFor: "deals-list" }}
                                placeholder="Select campaign"
                                search
                                defaultValue={this.props.form.deal_id || null}
                                onChange={this.onChangeDeal}
                                searchInput={{ id: "deals-list" }}
                            />
                        ) : null}
                        <Form.Field
                            required
                            // loading={!this.props.selectBoxDealCampaigns.length}
                            control={Select}
                            options={this.props.selectBoxDealCampaigns || []}
                            label={{ children: "Integrations", htmlFor: "campaigns-list" }}
                            placeholder="Select integration"
                            search
                            defaultValue={this.props.form.deal_campaign_id || null}
                            onChange={this.onChangeCampaign}
                            searchInput={{ id: "campaigns-list" }}
                        />
                        {!Auth.isAgent ? (
                            <Form.Field
                                required
                                control={Select}
                                options={this.props.selectBoxDealCampaignAgents || []}
                                label={{ children: "Agents", htmlFor: "agents-list" }}
                                placeholder="Select agent"
                                search
                                defaultValue={this.props.form.agent_id || null}
                                onChange={this.onChangeAgent}
                                searchInput={{ id: "agents-list" }}
                            />
                        ) : null}

                        <Form.Field required>
                            <label>Value</label>
                            <Form.Field>
                                <Input
                                    labelPosition="left"
                                    type="text"
                                    placeholder="Value"
                                    value={value}
                                    name="value"
                                    onChange={this.handleChangeValue}
                                >
                                    <Label basic className="currency-label fs-14">
                                        {this.getCurrencySymbol(pipeline ? pipeline.currency : "USD")}
                                    </Label>
                                    <input />
                                </Input>
                            </Form.Field>
                        </Form.Field>
                        <Form.Field
                            required
                            control={Select}
                            options={this.getStageList()}
                            label={{ children: "Stage", htmlFor: "lead-pipeline-list" }}
                            placeholder="Select Stage"
                            search
                            value={stage_id}
                            // defaultValue={null}
                            // value={stage_id}
                            onChange={this.onChangeStage}
                            searchInput={{ id: "lead-pipeline-list" }}
                        />
                    </Grid.Column>
                </Grid>
            </Form>
        );
    }
}

export default LeadForm;
