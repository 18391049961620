import { api2, Auth } from "@services";

export const fetchProject = form => api2.get(`/v1/company/project/${form.project_id}`);
export const fetchTemplate = form => api2.get(`/v1/company/template/${form.template_id}`);

export const editPage = form => {
    const role = Auth.role === "trial_company" ? "company" : Auth.role;
    return api2.post(`/v1/${role}/page/${Auth.user.id}`, form);
};

export const delPage = pageID => {
    const role = Auth.role === "trial_company" ? "company" : Auth.role;
    return api2.get(`/v1/${role}/page/${pageID}`);
};

export const uploadAsset = (form) => {
    return api2.post(`/v1/projects/image-asset`, form);
}