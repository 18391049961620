import React, { Component } from "react";
import { Button, Form, Grid, Icon, Input, Modal, Select } from "semantic-ui-react";
import "./index.scss";
import { Auth } from "@services";
const customStyle = {
    minWidth: "unset !important",
    borderBottom: "solid 1px #dee0f0 !important;",
    paddingLeft: "10px !important"
};

const currencyList = [
    { key: "USD", text: "$ USD", value: "USD" },
    { key: "EUR", text: "€ EUR", value: "EUR" },
    { key: "GBP", text: "£ GBP", value: "GBP" }
];

const defaultStageLIst = [
    { id: "", name: "NEW LEADS", priority: 0 },
    { id: "", name: "CONTACTED", priority: 1 },
    { id: "", name: "READY TO BOOK", priority: 2 },
    { id: "", name: "MISSED/BAD", priority: 3 }
];

const initPipeline = {
    id: "",
    stageList: defaultStageLIst,
    name: "",
    currency: "USD",
    companyId: ""
};

class PipelineForm extends Component {
    state = {
        pipeline: initPipeline
    };

    componentWillMount() {
        const { editPipeline } = this.props;
        if (editPipeline) {
            const stageList = editPipeline.stages
                .sort((a, b) => (a.priority > b.priority ? 1 : -1))
                .map(item => ({
                    id: item.id,
                    name: item.name,
                    priority: item.priority
                }));

            this.setState({
                pipeline: {
                    ...editPipeline,
                    stageList
                }
            });
        } else {
            this.setState({
                pipeline: initPipeline
            });
        }
    }

    handleDeleteStage = stage => {
        const stageList = this.state.pipeline.stageList.filter((item, index) => item.priority !== stage.priority);
        this.setState({ pipeline: { ...this.state.pipeline, stageList } });
    };

    handleChangeStage = (e, stage) => {
        const key = stage.priority;
        const stageList = this.state.pipeline.stageList.map((item, index) => {
            if (item.priority === key) {
                return {
                    ...item,
                    name: e.target.value
                };
            } else {
                return item;
            }
        });

        this.setState({
            pipeline: {
                ...this.state.pipeline,
                stageList
            }
        });
    };

    handleAddStage = () => {
        const priorityList = this.state.pipeline.stageList.map(item => item.priority);
        this.setState({
            pipeline: {
                ...this.state.pipeline,
                stageList: [
                    ...this.state.pipeline.stageList,
                    { id: "", name: "", priority: Math.max(...priorityList) + 1 }
                ]
            }
        });
    };

    handleChange = (e, { name, value }) => {
        this.setState(state => ({
            pipeline: {
                ...state.pipeline,
                [name]: value
            }
        }));
    };

    handleSubmit = async () => {
        const { pipeline } = this.state;
        this.props.createUpdatePipeline(pipeline);
    };

    render() {
        const { openPipelineForm, pipelineForm } = this.props;
        const { pipeline } = this.state;

        const companyList = this.props.selectBoxCompanies;

        return (
            <Modal
                className="Pipeline modal"
                open={openPipelineForm}
                centered={false}
                size={"tiny"}
                onClose={() => pipelineForm(false)}
            >
                <Modal.Header>{this.props.editPipeline ? "Edit Pipeline" : "Create Pipeline"} </Modal.Header>
                <Form size="big" onSubmit={this.handleSubmit}>
                    <Modal.Content>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={10}>
                                    <Form.Field required>
                                        <label>Name</label>
                                        <Input
                                            placeholder="Pipeline name"
                                            value={pipeline.name}
                                            name="name"
                                            onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={6} style={{ marginBottom: "1rem" }}>
                                    <Form.Field
                                        control={Select}
                                        options={currencyList}
                                        label={{ children: "Currency", htmlFor: "pipeline-currency-list" }}
                                        placeholder="Select Currency"
                                        search
                                        value={pipeline.currency}
                                        name="currency"
                                        onChange={this.handleChange}
                                        // onSearchChange={this.onSearchChange}
                                        searchInput={{ id: "pipeline-currency-list" }}
                                        required
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        {Auth.isAgency && (
                            <Form.Field
                                control={Select}
                                options={companyList || []}
                                label={{ children: "Attach to", htmlFor: "pipeline-form-companies-list" }}
                                placeholder="Select company"
                                name="companyId"
                                search
                                value={pipeline.company_id}
                                onChange={this.handleChange}
                                onSearchChange={this.onSearchChange}
                                searchInput={{ id: "pipeline-form-companies-list" }}
                                className="mt-3"
                                required
                                disabled={!!pipeline.id}
                            />
                        )}

                        <Form.Field>
                            <label className="stages-section">Add/Edit Stages</label>
                            {pipeline.stageList.map((stage, index) => (
                                <Grid key={index}>
                                    <Grid.Row>
                                        <Grid.Column width={15}>
                                            <Input
                                                type="text"
                                                value={stage.name}
                                                className="stage-input"
                                                onChange={e => this.handleChangeStage(e, stage)}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={1} className="d-flex align-center deletestage">
                                            <Icon
                                                className="flaticon stroke trash-1"
                                                onClick={() => this.handleDeleteStage(stage)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            ))}
                            <div className="d-flex mt-3">
                                <i
                                    className="ti ti-circle-plus add-stage margin-auto"
                                    size="big"
                                    onClick={this.handleAddStage}
                                />
                            </div>
                        </Form.Field>
                    </Modal.Content>
                    <Modal.Actions className="text-right">
                        <Button color="black" onClick={() => pipelineForm(false)}>
                            Cancel
                        </Button>
                        <Button color="teal" labelPosition="right" type="submit">
                            Save
                        </Button>
                    </Modal.Actions>
                </Form>
            </Modal>
        );
    }
}

export default PipelineForm;
