import React, { useEffect, useState } from "react";
import { compose } from "react-recompose";
import axios from "axios";
import { toast } from "react-toastify";
import { Auth, api } from "@services";

import "./index.scss";
import { Accordion, Button, Form, Icon, Portal, Segment, Tab, TextArea } from "semantic-ui-react";
import { htmlToText } from "../../../utils/common";
import { ProfileContainer } from "@containers";

const toneOptions = [
    { key: "professional", value: "a Professional", label: "Professional" },
    { key: "conversational", value: "a Conversational", label: "Conversational" },
    { key: "humorous", value: "an Humorous", label: "Humorous" },
    { key: "empathic", value: "an Empathic", label: "Empathic" },
    { key: "simple", value: "a Simple", label: "Simple" },
    { key: "academic", value: "an Academic", label: "Academic" },
    { key: "creative", value: "a Creative", label: "Creative" }
];

const Support = props => {
    const [open, setOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [editMode, setEditMode] = useState(false);

    const [prompt, setPrompt] = useState("");
    const [keyword, setKeyword] = useState("");
    const [tone, setTone] = useState("");

    const [answer, setAnswer] = useState({
        id: "",
        prompt: "",
        answer: ""
    });

    const [aiSubmitLoading, setAiSubmitLoading] = useState(false);

    const [answers, setAnswers] = useState([]);

    const openAIModal = () => {
        setActiveIndex(0);
        setEditMode(false);
        setPrompt("");
        setKeyword("");
        setTone("a Professional");
        setOpen(true);
    };

    const loadAiAnswers = async () => {
        const answers = await api.get("/v1/ai-token");
        setAnswers(answers.data);
    };

    useEffect(() => {
        if (currentUrl.indexOf("chatbot") === -1 && Auth.isAuthorised()) {
            loadAiAnswers();
        }
    }, [open]);

    const handleAIsumbit = async () => {
        const user = props.profile;
        if (!prompt) {
            toast.error("Please fill Prompt", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if (user.ai_token <= 0) {
            toast.error("You have exceeded your AI assistant word count", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        setAiSubmitLoading(true);
        const response = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
                model: "gpt-3.5-turbo",
                n: 1,
                messages: [
                    {
                        role: "user",
                        content: `${prompt}. use these keywords: ${keyword}`
                    },
                    { role: "system", content: `write in ${tone} tone` }
                ]
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_KEY}`
                }
            }
        );

        const token = response.data.usage.total_tokens;
        const answer = response.data.choices[0].message.content;

        setAnswer({
            prompt,
            answer
        });

        setEditMode(true);

        await api.post(`/v1/ai-token-update`, {
            token
        });
        setAiSubmitLoading(false);
    };

    const handleTabSelect = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;

        setActiveIndex(newIndex);
    };

    const currentUrl = window.location.href;

    const panes = () => {
        return [
            {
                menuItem: "Generate",
                render: () => (
                    <Tab.Pane attached={false}>
                        <Form onSubmit={handleAIsumbit}>
                            <Form.Field>
                                <label>How can I help you?</label>
                                <input
                                    placeholder="your prompt here"
                                    value={prompt}
                                    onChange={e => setPrompt(e.target.value)}
                                />
                                <em>ex:'write me a 300 word e-mail'</em>
                            </Form.Field>
                            <Form.Field>
                                <label>Keywords(optional)</label>
                                <input
                                    placeholder="type keywords"
                                    value={keyword}
                                    onChange={e => setKeyword(e.target.value)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Tone</label>
                                <select
                                    onChange={e => setTone(e.target.value)}
                                    value={tone}
                                    placeholder="Authoritative"
                                >
                                    {toneOptions.map(tone => (
                                        <option value={tone.value} key={tone.key}>
                                            {tone.label}
                                        </option>
                                    ))}
                                </select>
                            </Form.Field>
                            <div className="d-flex justify-end mt-1">
                                <a href="http://support.convertlead.com" target="_blank">
                                    Need more words?
                                </a>
                            </div>
                            <Button
                                type="submit"
                                positive
                                style={{ width: "100%", marginTop: "12px" }}
                                disabled={aiSubmitLoading}
                            >
                                Generate
                            </Button>
                        </Form>
                    </Tab.Pane>
                )
            },
            {
                menuItem: "History",
                render: () => (
                    <Tab.Pane attached={false}>
                        <Accordion styled>
                            {answers.map((answer, index) => (
                                <React.Fragment key={answer.id}>
                                    <Accordion.Title
                                        active={activeIndex === index}
                                        index={index}
                                        onClick={handleTabSelect}
                                        className="d-flex content-between"
                                    >
                                        <div>
                                            <i className="ti ti-arrow-narrow-right" />
                                            {answer.prompt}
                                        </div>
                                        <div>
                                            <i
                                                className="me-0 ti ti-pencil"
                                                style={{ marginLeft: "0 !important" }}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setAnswer(answer);
                                                    setEditMode(true);
                                                }}
                                            />
                                            <i
                                                className="me-0 ti ti-copy"
                                                style={{ marginLeft: "0 !important" }}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    navigator.clipboard.writeText(answer.answer);
                                                }}
                                            />
                                        </div>
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex === index}>
                                        <p>{htmlToText(answer.answer)}</p>
                                    </Accordion.Content>
                                </React.Fragment>
                            ))}
                        </Accordion>
                    </Tab.Pane>
                )
            }
        ];
    };

    const onSaveAnswer = async () => {
        if (!answer.id) {
            await api.post(`/v1/ai-token`, answer);
        } else {
            await api.put(`/v1/ai-token/${answer.id}`, answer);
        }

        openAIModal();
        loadAiAnswers();
    };

    const handleChangeAnswer = content => {
        setAnswer({
            ...answer,
            answer: content
        });
    };

    const resultPane = () => {
        return [
            {
                menuItem: "Edit entry",
                render: () => (
                    <Tab.Pane attached={false} active={true} style={{ marginBottom: "25px" }}>
                        <div>
                            <h4 className="title">{answer.prompt || ""}</h4>
                            <div className="content active" style={{ height: "250px", overflow: "auto" }}>
                                <TextArea
                                    value={answer.answer || ""}
                                    onChange={(e, data) => handleChangeAnswer(data.value)}
                                    style={{ width: "100%", maxWidth: "100%", height: "95%", maxHeight: "95%" }}
                                ></TextArea>
                            </div>
                            <div className="d-flex content-between">
                                {!answer.id ? (
                                    <div>
                                        <div className="tooltip ai-buttons">
                                            <Button className="redo-icon" onClick={handleAIsumbit}>
                                                <i
                                                    className="ti ti-refresh me-0"
                                                    style={{ color: "white", marginLeft: "0 !important" }}
                                                />
                                            </Button>
                                            <span className="tooltiptext">Try again</span>
                                        </div>
                                        <div className="tooltip ai-buttons">
                                            <Button
                                                className="copy-icon"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(answer.answer);
                                                }}
                                            >
                                                <i className="ti ti-copy me-0" style={{ marginLeft: "0 !important" }} />
                                            </Button>
                                            <span className="tooltiptext">Copy</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div></div>
                                )}

                                <div>
                                    <Button positive onClick={onSaveAnswer} disabled={aiSubmitLoading}>
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Tab.Pane>
                )
            }
        ];
    };

    
    if(currentUrl.indexOf("chatbot") > -1){
        return null;
    }


    return (
        <>
            <Button onClick={() => openAIModal()} disabled={open} className="support-btn">
                <Icon className="ti ti-recharging assistant" />
            </Button>

            <Portal onClose={() => setOpen(false)} open={open}>
                <Segment className="support-modal">
                    <Icon name="close" onClick={() => setOpen(false)} className="flaticon stroke x-2 close-icon" />
                    {!editMode ? (
                        <Tab menu={{ secondary: true, pointing: true }} panes={panes()} />
                    ) : (
                        <Tab menu={{ secondary: true, pointing: true }} panes={resultPane()} activeIndex={0} />
                    )}
                </Segment>
            </Portal>
        </>
    );
};

export default compose(ProfileContainer)(Support);
