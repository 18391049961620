import * as actions from "./actions";
import { sendMessage } from "../../../messages/thunks";
import { api, Auth } from "@services";
import { fetchCampaigns } from "@containers/campaigns/thunks";
import { idbAddItem } from "../../../../middlewares/idb";
import history from "../../../../history";

export const saveOptinForm = form => async dispatch => {
    try {
        if (!form.companyId) {
            throw new Error("Missing required company!");
        }

        if (!form.dealId) {
            throw new Error("Missing required campaign!");
        }
        await api.patch(`/v1/${Auth.role}/companies/${form.companyId}/deals/${form.dealId}/campaigns/${form.id}`, {
            integration_config: JSON.stringify(form.integrationForm)
        });
        await dispatch(actions.saveOptionForm());
        await dispatch(fetchCampaigns());
        await dispatch(sendMessage("Successfully created!"));
    } catch (e) {
        dispatch(sendMessage(e.message, true));
    }
};

export const saveOptinFormEmbedType = form => async dispatch => {
    try {
        if (!form.companyId) {
            throw new Error("Missing required company!");
        }

        if (!form.dealId) {
            throw new Error("Missing required campaign!");
        }
        await api.patch(`/v1/${Auth.role}/companies/${form.companyId}/deals/${form.dealId}/campaigns/${form.id}`, {
            embed_type: form.embed_type
        });
        // await dispatch(actions.saveOptionForm());
        await dispatch(fetchCampaigns());
        await dispatch(sendMessage("Successfully created!"));
    } catch (e) {
        dispatch(sendMessage(e.message, true));
    }
};

export const saveOptinFormBuilder = form => async dispatch => {
    try {
        if (!form.companyId) {
            throw new Error("Missing required company!");
        }

        if (!form.dealId) {
            throw new Error("Missing required campaign!");
        }
        await api.patch(`/v1/${Auth.role}/companies/${form.companyId}/deals/${form.dealId}/campaigns/${form.id}`, {
            embed_type: form.embed_type
        });
        // await dispatch(actions.saveOptionForm());
        await dispatch(fetchCampaigns());
        await dispatch(sendMessage("Successfully created!"));
    } catch (e) {
        dispatch(sendMessage(e.message, true));
    }
};

export const loadOptinformBuilder = form => async dispatch => {
    try {
        if (!form.companyId) {
            throw new Error("Missing required company!");
        }

        if (!form.dealId) {
            throw new Error("Missing required campaign!");
        }

        Promise.resolve()
        .then(function() {
            idbAddItem(form.form_gjs);
        })
        .then(function() {
            history.push(`/companies/${form.companyId}/deals/${form.dealId}/campaigns/${form.id}/form-build`);
        });

        // const res = await api.get(
        //     `/v1/${Auth.role}/companies/${form.companyId}/deals/${form.dealId}/campaigns/${form.id}`
        // );
        // console.log(" log load optionbuiler--- ", res)
        // await dispatch(actions.saveOptionForm());
        // await dispatch(fetchCampaigns());
        // await dispatch(sendMessage("Successfully created!"));
    } catch (e) {
        dispatch(sendMessage(e.message, true));
    }
};
