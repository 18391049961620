import { api, Auth } from "@services";

export const saveFormApi = form => api.post(`/v1/fb-folder`, form);
export const updateFormApi = form => api.put(`/v1/fb-folder/${form.id}`, form);

export const deleteFolderApi = id => api.post(`/v1/fb-folder/delete`, { id });

export const addPageToFolderThunkApi = (folderId, pages) =>
    api.post(`/v1/fb-folder/add-pages-folder`, { folderId, pages });
export const sendContactEmail = (email, subject, content, folderPageId) =>
    api.post(`/v1/fb-folder/send-contact-email`, { email, subject, content, folderPageId });
