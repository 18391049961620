import { CREATE_PROJECT, SAVED_FORM, CLOSE_FORM, CHANGE_FORM, SHOW_MODAL } from "./actions";

const initState = {
    type: 0,
    isNew: true,
    title: "",

    id: 0,
    form: {
        title: "",
        show: false,
        id: "",
        name: "",
        description: "",
        default_msg: "",
        user_id: ''
    }
};

const folderForm = (state = initState, action) => {
    switch (action.type) {
        //Manage Modal
        case SHOW_MODAL: {
            const title = action.form && action.form.id ? "Update Folder" : "Create Folder";
            return {
                ...state,
                ...action.form,
                title: title,
                show: true
            };
        }
        case CHANGE_FORM: {
            return {
                ...state,
                ...action.form
            };
        }
        case CLOSE_FORM: {
            return {
                ...state,
                form: {
                    show: false,
                    id: "",
                    name: "",
                    description: "",
                    default_msg: "",
                    show: false
                },
                show: false
            };
        }
        case SAVED_FORM: {
            return {
                ...state,
                ...action.form,
                show: false
            };
        }
        //Page Manage Reducer
        case CREATE_PROJECT: {
            let title = action.form.isTemplate === true ? "Create Template" : "Create Page";
            localStorage.setItem("gjsProject", action.form.gjsProject);

            return {
                ...state,
                ...action.form,
                title: title
            };
        }
        default: {
            return state;
        }
    }
};

export default folderForm;
