import { UPLOAD_DONE, UPLOAD_START, ADD_FILE_LIST, REMOVE_DONE, FORMAT_LIST } from "./actions";

const initState = {
    fileInfo: [],
    uploadLoading: false
};

const uploadFileReducer = (state = initState, action) => {
    switch (action.type) {
        case UPLOAD_DONE: {
            return {
                ...state,
                fileInfo: action.payload,
                uploadLoading: false
            };
        }
        case ADD_FILE_LIST: {
            const fileInfo = state.fileInfo ? state.fileInfo : [];
            return {
                ...state,
                fileInfo: [...fileInfo, action.payload],
                uploadLoading: false
            };
        }
        case UPLOAD_START: {
            return {
                ...state,
                uploadLoading: true
            };
        }
        case REMOVE_DONE: {
            const fileList = state.fileInfo ? state.fileInfo.filter(fi => fi.id !== action.payload) : [];
            return {
                ...state,
                fileInfo: fileList,
                uploadLoading: false
            };
        }
        case FORMAT_LIST: {
            return {
                ...state,
                fileInfo: [],
                uploadLoading: false
            };
        }

        //Page Manage Reducer
        default: {
            return state;
        }
    }
};

export default uploadFileReducer;
