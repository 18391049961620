import { uploadFileAddListAction, uploadFileDoneAction, uploadFileStartAction, uploadRemoveAction } from "./actions";
import { sendMessage } from "@containers/messages/thunks";
import { removeFileApi, updateFileApi } from "./api";
import { hideLoader, showLoader } from "../loader/actions";

export const uploadFileThunk = formData => async dispatch => {
    try {
        console.log({ formData });
        const type = formData.get("type");
        dispatch(showLoader());

        dispatch(uploadFileStartAction());

        const formRes = await updateFileApi(formData);

        dispatch(uploadFileAddListAction(formRes.data));

        dispatch(hideLoader());
        return formRes;
    } catch (e) {
        dispatch(sendMessage(e.message, true));
    }
};

export const removeFileThunk = (id, assistId) => async dispatch => {
    try {
        dispatch(showLoader());

        dispatch(uploadFileStartAction());

        const formRes = await removeFileApi(id, assistId);

        if (formRes.data) {
            dispatch(uploadRemoveAction(id));
        } else {
            dispatch(sendMessage("Something went wrong", true));
        }

        dispatch(hideLoader());
        return formRes;
    } catch (e) {
        dispatch(sendMessage(e.message, true));
    }
};
