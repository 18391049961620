export const IntegrationForm = {
  header: {
    title: 'Sign up below'
  },
  fullname: {
    order: 1,
    label: 'Full name',
    placeholder: 'Full name',
    value: '',
    isRequired: true,
    isVisible: true,
  },
  email: {
    order: 2,
    label: 'E-mail',
    placeholder: 'E-mail',
    value: '',
    isRequired: true,
    isVisible: true,
  },
  phone: {
    order: 3,
    label: 'Phone',
    placeholder: 'Phone',
    value: '',
    isRequired: true,
    isVisible: true,
  },
  button: {
    name: 'Sign me up',
  },
  embedType: 'fullPage'
};