import { connect } from "react-redux"
import * as thunks from "./thunks"
import { loadPropList } from "./actions"

const mapStateToProps = (state) => ({
  pageList: state.fbTool.pageList,
  linkedinList: state.fbTool.linkedinList,
  locationList: state.fbTool.locationList,
  nextUrl: state.fbTool.nextUrl,
  prevUrl: state.fbTool.prevUrl,
  fbAccessToken: state.fbTool.fbAccessToken,
  folderList: state.fbTool.folderList,
  folderPageList: state.fbTool.folderPageList,
})

const mapActionsToProps = (dispatch) => ({
  searchPage: (keyword, location, isLoggedIn) =>
    dispatch(thunks.searchPageThunk(keyword, location, isLoggedIn)),
  loadPropList: (list) => dispatch(loadPropList(list)),
  loadLinkedinList: (reload = false) =>
    dispatch(thunks.loadLinkedinLeadsThunk(reload)),
  loadMore: (url, keyword, location) =>
    dispatch(thunks.loadMoreThunk(url, keyword, location)),
  setFBAccessToken: (token) => dispatch(thunks.setFBAccessTokenThunk(token)),
  getFolders: () => dispatch(thunks.getFoldersThunk()),
  getPagesByFolderId: (folderId, query) =>
    dispatch(thunks.getPagesByFolderIdThunk(folderId, query)),
  movePages: (folderId, pages, activeFolderId) =>
    dispatch(thunks.movePageThunk(folderId, pages, activeFolderId)),
  deletePages: (pageIds, activeFolderId) =>
    dispatch(thunks.deletePageThunk(pageIds, activeFolderId)),
})

export default connect(mapStateToProps, mapActionsToProps)
