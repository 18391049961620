import { connect } from "react-redux";
import * as thunks from "./thunks";
import * as actions from "./actions";

const mapStateToProps = state => ({
    form: state.forms.fbFolder,
    show: state.forms.fbFolder.show
});

const mapDispatcherToState = dispatch => ({
    showFolderModal: form => dispatch(actions.showModal(form)), //Modal Show
    changeForm: form => dispatch(actions.changeForm(form)), //when form values changed
    saveForm: form => dispatch(thunks.saveForm(form)), //save in connect modal or project modal
    loadForm: domain => dispatch(actions.closeForm(domain)),
    deleteFolder: folderId => dispatch(thunks.deleteThunk(folderId)),
    addPageToFolder: (folderId, pages) => dispatch(thunks.addPageToFolderThunk(folderId, pages)),
    sendContactEmail: (email, subject, content, folderPageId) => dispatch(thunks.emailSendThunk(email, subject, content, folderPageId)),
});

export default connect(mapStateToProps, mapDispatcherToState);
