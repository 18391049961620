import { connect } from "react-redux";
import * as thunks from "./thunks";
import * as actions from "./actions";

const mapStateToProps = state => ({
    form: state.forms.assistantForm,
    show: state.forms.assistantForm.show,
    type: state.forms.assistantForm.type,
});

const mapDispatcherToState = dispatch => ({
    showModal: form => dispatch(actions.showModal(form)), //Modal Show
    saveForm: (form, type = "create") => dispatch(thunks.saveForm(form, type)), //save in connect modal or project modal
    changeForm: form => dispatch(actions.changeForm(form)), //when form values changed

    uploadFile: formData => dispatch(thunks.uploadFileThunk(formData)), //Modal Show
 


    loadForm: form => dispatch(thunks.closeForm()),
    // loadForm: domain => dispatch(actions.closeForm(domain)),
    // deleteFolder: folderId => dispatch(thunks.deleteThunk(folderId)),
    // addPageToFolder: (folderId, pages) => dispatch(thunks.addPageToFolderThunk(folderId, pages)),
    // sendContactEmail: (email, subject, content, folderPageId) => dispatch(thunks.emailSendThunk(email, subject, content, folderPageId)),
});

export default connect(mapStateToProps, mapDispatcherToState);
