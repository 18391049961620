import React, { Component } from "react";
import LeadModal from "../@common/modals/lead";
import EmailModal from "../@common/modals/email";
import { api, Auth } from "@services";
import { compose } from "react-recompose";
import { Segment, Button, Header, Input, Grid, Menu, Tab, Form, Select, Icon, Confirm } from "semantic-ui-react";
import "./index.scss";
import {
    BreadCrumbContainer,
    DealsContainer,
    CompaniesContainer,
    LeadsContainer,
    LeadFormContainer,
    EmailFormContainer,
    PipelineContainer
} from "@containers";
import LeadNotesPreview from "./lead-notes-preview";
import LeadsTable from "./Leads";
import * as moment from "moment";
import PipelineForm from "./pipeline/form";

const allPipeOption = {
    key: "all",
    value: "all",
    text: "All pipelines"
};

class Leads extends Component {
    state = {
        previewLeadId: null,
        companyId: null,
        showDeleted: false,
        activeIndex: 0,
        startDate: moment("2000-01-01").format("Y-MM-DD"),
        endDate: moment()
            .endOf("isoWeek")
            .format("Y-MM-DD"),
        // listType: "dragdrop",
        editPipeline: null,
        confirmDeletePipeline: false
    };

    componentWillMount() {
        this.props.filterLeads({
            campaignId: null,
            companyId: "",
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            search: "",
            statusType: "",
            sortBy: "name.asc",
            pipeline_id: ""
        });
        // this.props.searchLeads("");

        // this.props.filterLeads({
        //     pipeline_id:  value
        // });
        this.props.loadPipelines("");
    }

    onSearch = (event, data) => {
        // this.props.searchLeads(data.value, listType);
        this.props.searchLeads(data.value);
    };

    onShowArch = (e, tab) => {
        if (tab.activeIndex === this.state.activeIndex) {
            return;
        }

        this.setState({
            activeIndex: tab.activeIndex
        });

        this.props.toggleShowDeleted();
    };

    onCompanyChange = companyId => {
        this.setState({
            companyId
        });
    };

    onPreviewLeadChange = lead => {
        this.setState({
            previewLeadId: lead.id,
            companyId: lead.company_id
        });
    };

    onLeadLeaveDisplayNotes = () => {
        this.setState({
            previewLeadId: null
        });
    };

    pipelineFilter = (e, { value }) => {
        const { pipelines } = this.props;
        if (value === "all") {
            this.props.changeActivePipeline(null);
            this.props.filterLeads({
                pipeline_id: ""
            });
        } else {
            const selectedPipeline = pipelines.find(item => item.id === value);
            this.props.changeActivePipeline(selectedPipeline);
            this.props.filterLeads({
                pipeline_id: value
            });
        }
    };

    handlePipelineDelete = () => {
        const acitvePipeline = this.props.activePipeline;
        if (acitvePipeline) {
            this.props.onDeletePipeline(acitvePipeline.id);
        }
    };

    handleDeletePipelineConfirm = isOpen => {
        this.setState({
            confirmDeletePipeline: isOpen
        });
    };

    render() {
        const { companyId, previewLeadId, editPipeline } = this.state;

        const { changeListType, listType, pipelines, activePipeline, pipelineForm, openPipelineForm } = this.props;

        const pipelineList = pipelines.map(pipeline => ({
            key: pipeline.id,
            value: pipeline.id,
            text: pipeline.name
        }));

        let pipelineOptionList = [];
        if (listType === "table") {
            pipelineOptionList = [allPipeOption, ...pipelineList];
        } else {
            if (pipelineList.length === 0) {
                pipelineOptionList = [allPipeOption, ...pipelineList];
            } else {
                pipelineOptionList = pipelineList;
            }
        }

        const defaultActiveId = "all";

        const tabs = [
            {
                menuItem: "Active",
                render: () => (
                    <LeadsTable
                        onPreviewLeadChange={this.onPreviewLeadChange}
                        listType={listType}
                        handleChangeListType={changeListType}
                        activePipeline={activePipeline}
                    />
                )
            },
            {
                menuItem: "Archived",
                render: () => (
                    <LeadsTable
                        onPreviewLeadChange={this.onPreviewLeadChange}
                        listType={listType}
                        handleChangeListType={changeListType}
                        activePipeline={activePipeline}
                    />
                )
            }
        ];

        return (
            <div className={previewLeadId ? "Leads leadspage sidebarOpened" : "Leads leadspage"}>
                <Confirm
                    open={this.state.confirmDeletePipeline}
                    onCancel={() => this.handleDeletePipelineConfirm(false)}
                    onConfirm={() => this.handlePipelineDelete()}
                    className="confirm-modal"
                />
                <div className="leads-container">
                    <Segment attached="top">
                        <Grid columns={2}>
                            <Grid.Column>
                                <div className="d-flex pipeline-settings">
                                    <Header floated="left" as="h1" className="me-3">
                                        Leads
                                    </Header>
                                    <Button
                                        className="bg-white round-5 me-2"
                                        onClick={() => {
                                            if (activePipeline) {
                                                this.setState({ editPipeline: activePipeline });
                                                pipelineForm(true);
                                            }
                                        }}
                                    >
                                        <i className="ti ti-settings" />
                                    </Button>
                                    <Button
                                        className="bg-white round-5 me-3"
                                        onClick={() => {
                                            this.setState({ editPipeline: null });
                                            pipelineForm(true);
                                        }}
                                    >
                                        <i className="ti ti-plus" />
                                    </Button>
                                    <Form>
                                        <Form.Field
                                            control={Select}
                                            className="sortby"
                                            options={pipelineOptionList}
                                            value={activePipeline ? activePipeline.id : defaultActiveId}
                                            label={{
                                                children: <i className="ti ti-jump-rope" />,
                                                htmlFor: "pipeline-list"
                                            }}
                                            search
                                            searchInput={{ id: "pipeline-list" }}
                                            onChange={this.pipelineFilter}
                                            onSearchChange={this.pipelineFilter}
                                        />
                                    </Form>
                                    <Button
                                        className="bg-white round-5 me-3"
                                        disabled={!activePipeline}
                                        onClick={() => this.handleDeletePipelineConfirm(true)}
                                    >
                                        <i className="ti ti-trash" />
                                    </Button>
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <Menu secondary>
                                    <Menu.Menu position="right">
                                        <Menu.Item>
                                            <Input
                                                icon="search"
                                                onChange={this.onSearch.bind(this)}
                                                placeholder="Search..."
                                            />
                                        </Menu.Item>
                                        <Button
                                            color="teal"
                                            className="new-campaign"
                                            onClick={this.props.loadForm.bind(this, { show: true })}
                                        >
                                            <i className="flaticon stroke plus-1  icon"></i>
                                        </Button>
                                    </Menu.Menu>
                                </Menu>
                            </Grid.Column>
                        </Grid>
                    </Segment>
                    <Tab onTabChange={this.onShowArch} menu={{ secondary: true, pointing: true }} panes={tabs} className="leadstab" />
                </div>
                <LeadModal size="small" />
                <EmailModal size="small" />
                {openPipelineForm && (
                    <PipelineForm getPipelineList={this.getPipelineList} {...this.props} editPipeline={editPipeline} />
                )}

                {previewLeadId && (
                    <LeadNotesPreview
                        leadId={previewLeadId}
                        companyId={companyId}
                        onClose={this.onLeadLeaveDisplayNotes}
                    />
                )}
            </div>
        );
    }
}

export default compose(
    BreadCrumbContainer,
    DealsContainer,
    CompaniesContainer,
    LeadsContainer,
    LeadFormContainer,
    EmailFormContainer,
    PipelineContainer
)(Leads);
