const defaultPage = { "assets": [], "styles": [{ "selectors": [{ "name": "gjs-row", "private": 1 }], 

	"style": { "display": "block", "padding-top": "10px", "padding-right": "10px", "padding-bottom": "10px", "padding-left": "10px", "width": "100%" } }, 

	{ "selectors": [{ "name": "gjs-cell", "private": 1 }], "style": { "width": "100%", "display": "block" }, "mediaText": "(max-width: 768px)", "atRuleType": "media" }, 
	{ "selectors": ["gjs-cell30"], "style": { "width": "100%", "display": "block" }, "mediaText": "(max-width: 768px)", "atRuleType": "media" }, 
	{ "selectors": ["gjs-cell70"], "style": { "width": "100%", "display": "block" }, "mediaText": "(max-width: 768px)", "atRuleType": "media" }, 
	{ "selectors": [{ "name": "gjs-cell", "private": 1 }], "style": { "width": "100%", "display": "black", "height": "275px", "max-width":"1200px" } }, 
	{ "selectors": ["#ind7"], "style": { "height": "580px" } }], 

		"pages": [{ "frames": [{ "component": { "type": "wrapper", "stylable": ["background", "background-color", "background-image", "background-repeat", "background-attachment", "background-position", "background-size"], "_undoexc": ["status", "open"], 
		"components": [] } }], "type": "main", "id": "pedRUqy09IeST5LS" }] }

export default defaultPage;