import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Grid, Icon, Input, Label, Modal, Select } from "semantic-ui-react";
import { getSelectBoxStatuses } from "@models/lead-statuses";
import { useDispatch } from "react-redux";

export default function StageAutomation({ setStageAutoModal, createUpdateAutoStage, stageId, stage, searchLeads }) {
    const [conditions, setConditions] = useState([]);
    const [allLead, setAllLead] = useState(!!stage.is_auto || false);

    useEffect(() => {
        if (stage && stage.auto_stage) {
            const condtionList = stage.auto_stage
                .sort((a, b) => (a.priority > b.priority ? 1 : -1))
                .map(item => item.lead_status);
            setConditions(condtionList);
        }
    }, [stage]);

    const handleChangeStatus = (data, key) => {
        if (key === -1) {
            setConditions([data.value]);
        } else {
            setConditions(
                conditions.map((item, index) => {
                    if (index === key) {
                        return data.value;
                    } else {
                        return item;
                    }
                })
            );
        }
    };
    const handleAddStatus = () => {
        setConditions([...conditions, ""]);
    };

    const handleDeleteStatus = key => {
        setConditions(conditions.filter((item, index) => index !== key));
    };

    const handleCheckbox = (e, { checked }) => {
        setAllLead(checked);
    };

    const handleSubmit = async e => {
        await createUpdateAutoStage({ conditions, stageId, allLead });
        if (allLead) await searchLeads("");
        setStageAutoModal(false);
    };

    return (
        <Modal className="Pipeline" open={true} centered={false} size={"tiny"} onClose={() => setStageAutoModal(false)}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header>
                    <p>Stage automation</p>
                    <p className="modal-description">
                        Set conditions below to autopopulate this stage of your pipeline
                    </p>
                </Modal.Header>
                <Modal.Content>
                    <Form.Field className="text-center">
                        <label>Add all leads with</label>
                        <div className="d-flex mt-3">
                            <hr className="vertical-line-long" />
                        </div>
                        {conditions.length === 0 && (
                            <div>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={15}>
                                            <div className="d-flex content-between align-center stage">
                                                <p className="mb-0">Status</p>
                                                <Form.Field
                                                    control={Select}
                                                    className="parent-bg"
                                                    placeholder='Select status'
                                                    options={getSelectBoxStatuses}
                                                    onChange={(e, data) => handleChangeStatus(data, -1)}
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <div className="d-flex mt-1">
                                    <hr className="vertical-line-short" />
                                </div>
                            </div>
                        )}
                        {conditions.map((stage, index) => (
                            <div key={index}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={15}>
                                            <div className="d-flex content-between align-center stage">
                                                <p className="mb-0">Status</p>
                                                <Form.Field
                                                    control={Select}
                                                    className="parent-bg"
                                                    placeholder='Select status'
                                                    options={getSelectBoxStatuses}
                                                    onChange={(e, data) => handleChangeStatus(data, index)}
                                                    value={stage}
                                                />
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={1} className="d-flex align-center deletestage">
                                            <i
                                                className="flaticon stroke trash-1"
                                                onClick={() => handleDeleteStatus(index)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                {index < conditions.length - 1 && (
                                    <>
                                        <div className="d-flex mt-1">
                                            <hr className="vertical-line-short" />
                                        </div>
                                        <Input
                                            type="text"
                                            value="OR"
                                            className="stage-input text-center w-70px text-blue"
                                        />
                                    </>
                                )}
                                <div className="d-flex mt-1">
                                    <hr className="vertical-line-short" />
                                </div>
                            </div>
                        ))}
                        <div className="d-flex mt-3 nomargin">
                            <i
                                className="ti ti-circle-plus add-stage margin-auto"
                                size="big"
                                onClick={handleAddStatus}
                            />
                        </div>
                    </Form.Field>
                </Modal.Content>
                <Modal.Actions className="text-right nomargin content">
                    <div className="modal-description marginbottom">
                        <Checkbox
                            onClick={handleCheckbox}
                            checked={allLead}
                            label="Apply to all existing leads and move them to this stage if above conditions are meet."
                        />
                    </div>
                    <Button color="black" onClick={() => setStageAutoModal(false)}>
                        Cancel
                    </Button>
                    <Button color="teal" labelPosition="right" type="submit">
                        Save
                    </Button>
                </Modal.Actions>
            </Form>
        </Modal>
    );
}
