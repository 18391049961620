import { connect } from 'react-redux';
import { currentTour } from './actions';


const mapStateToProps = state => ({
    tourInstance: state.tour.campaigns.tour
});

const mapDispatcherToProps = dispatch => ({
    currentTour:    (name, tour) => dispatch(currentTour(name, tour)),
});

export default connect(
  mapStateToProps,
  mapDispatcherToProps,
);
