import React, {useContext, useEffect} from 'react';
import { compose } from 'react-recompose';
import EntityModal from "../index";
import { TourContainer, MessagesContainer, AgentFormContainer, CompaniesContainer, ProfileContainer } from "@containers";
import AgentForm from "components/@common/forms/agent";
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'
import stepsCampaignAgents from 'components/tour/steps_campaign_agents';

const tourOptions = {
    defaultStepOptions: {},
    useModalOverlay: true,
};

function ShButton({ campaignTourInstance }) {
    // const tour = useContext(ShepherdTourContext);

    useEffect(() => {
        if(localStorage.getItem('shepherd-tour')) {
            if (typeof campaignTourInstance !== "undefined" && campaignTourInstance !== null) {
                localStorage.removeItem('shepherd-tour');
                campaignTourInstance.getCurrentStep().cancel();
            }
        }

    }, [campaignTourInstance]);

    if(localStorage.getItem('shepherd-tour')) {
        return (<div></div>);
    }
    return (<></>);
}

const AgentModal = (props) => {
    return (
        <>
            {!props.show && props.success && (<ShepherdTour steps={stepsCampaignAgents} tourOptions={tourOptions}>
                <ShButton campaignTourInstance={props.tourInstance} />
            </ShepherdTour>)}

            <EntityModal {...{...props, Container: AgentForm}}/>
        </>
    );
};

export default compose(MessagesContainer, AgentFormContainer, CompaniesContainer, ProfileContainer, TourContainer)(AgentModal);
