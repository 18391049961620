import {
  searchPage,
  loadMore,
  getFoldersApi,
  getPagesByFolderId,
  movePage,
  deletePage,
  loadLinkedinLeads,
} from "./api"
import { hideLoader, showLoader } from "../loader/actions"
import {
  loadPageList,
  fbLoadMore,
  setFBAccessToken,
  setFolderList,
  setPageList,
  loadLinkedinList,
  loadPropList,
} from "./actions"
import { sendMessage } from "@containers/messages/thunks"

export const searchPageThunk = (keyword, location, isLoggedIn) => async (
  dispatch
) => {
  try {
    dispatch(showLoader())
    const pageList = await searchPage(keyword, location, isLoggedIn)
    dispatch(loadPageList(pageList.data))
  } catch (error) {
    dispatch(
      sendMessage(
        "Maximum allowed queries per hour reached - Come back later !",
        true
      )
    )
  }
  dispatch(hideLoader())
}

export const loadLinkedinLeadsThunk = (reload) => async (dispatch) => {
  try {
    dispatch(showLoader())
    const linkedinList = await loadLinkedinLeads()

    dispatch(loadLinkedinList(linkedinList.data))
    if (reload) {
      dispatch(
        loadPropList(
          linkedinList.data.map((item) => ({
            ...item,
            extension: "linkedin",
          }))
        )
      )
    }
  } catch (error) {
    dispatch(sendMessage("Something went wrong", true))
  }
  dispatch(hideLoader())
}

export const loadMoreThunk = (url, keyword, location) => async (dispatch) => {
  try {
    dispatch(showLoader())
    const loadMoreList = await loadMore(url, keyword, location)
    dispatch(fbLoadMore(loadMoreList.data))
  } catch (error) {
    dispatch(
      sendMessage(
        "Maximum allowed queries per hour reached - Come back later !",
        true
      )
    )
  }
  dispatch(hideLoader())
}

export const setFBAccessTokenThunk = (token) => (dispatch) => {
  dispatch(setFBAccessToken(token))
}

export const getFoldersThunk = () => async (dispatch) => {
  try {
    dispatch(showLoader())
    const folders = await getFoldersApi()
    dispatch(setFolderList(folders.data))
  } catch (error) {
    dispatch(
      sendMessage(
        "Maximum allowed queries per hour reached - Come back later !",
        true
      )
    )
  }
  dispatch(hideLoader())
}

export const getPagesByFolderIdThunk = (folderId, query) => async (
  dispatch
) => {
  try {
    dispatch(showLoader())
    const pages = await getPagesByFolderId(folderId, query)
    dispatch(setPageList(pages.data))
  } catch (error) {
    dispatch(sendMessage("Something went wrong!", true))
  }
  dispatch(hideLoader())
}

export const movePageThunk = (folderId, pages, activeFolderId) => async (
  dispatch
) => {
  try {
    dispatch(showLoader())
    const pageList = await movePage(folderId, pages, activeFolderId)
    dispatch(setPageList(pageList.data))
  } catch (error) {
    dispatch(sendMessage("Something went wrong!", true))
  }
  dispatch(hideLoader())
}

export const deletePageThunk = (pageIds, activeFolderId) => async (
  dispatch
) => {
  try {
    dispatch(showLoader())
    const pages = await deletePage(pageIds, activeFolderId)
    dispatch(setPageList(pages.data))
  } catch (error) {
    dispatch(sendMessage("Something went wrong!", true))
  }
  dispatch(hideLoader())
}
