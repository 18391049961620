import React, {Component, useContext, useEffect} from 'react';
import { compose } from 'react-recompose';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'
import './index.scss';

import steps_leads from './steps_leads';
import steps_campaigns from './steps_campaigns';
import steps_companies from './steps_companies';
import steps_campaign_agents from './steps_campaign_agents';
import steps_agents from './steps_agents';
import 'shepherd.js/dist/css/shepherd.css';
import { TourContainer } from '@containers'
const tourOptions = {
  defaultStepOptions: {
  },
  useModalOverlay: true,
};

function ShButton({ currentTour, name }) {
  const tour = useContext(ShepherdTourContext);

  const onClick = () => {
      tour.start();
      localStorage.setItem('shepherd-tour', 'yes');
      currentTour(name, tour);
  };

  return (
    // you need to remove dispaly style when run tour
    <button className="button dark " onClick={onClick} style={{ display:"none"}}>
      Start Tour
    </button>
  );
}

//Construct the steps

class Tour extends Component {

    render() {
        let page = this.props.page;
        let currentTour = this.props.currentTour;

        return(
            <>
            {page === 'leads'?
                <ShepherdTour steps={steps_leads} tourOptions={tourOptions}>
                    <ShButton currentTour={currentTour} name="leads" />
                </ShepherdTour>:
            page === 'campaigns'?
                <ShepherdTour steps={steps_campaigns} tourOptions={tourOptions}>
                <ShButton currentTour={currentTour} name="campaigns" />
                </ShepherdTour>:
            page === 'companies'?
                <ShepherdTour steps={steps_companies} tourOptions={tourOptions}>
                <ShButton currentTour={currentTour} name="companies" />
                </ShepherdTour>:
            page === 'agents'?
                <ShepherdTour steps={steps_campaign_agents} tourOptions={tourOptions}>
                <ShButton currentTour={currentTour} name="agents" />
                </ShepherdTour>
                : <></>
            }
            </>
        );

    }
}

export default compose(TourContainer)(Tour);


