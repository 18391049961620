import { connect } from "react-redux";
import * as thunks from "./thunks";
import * as actions from "./actions";

const mapStateToProps = state => ({
    fileInfo: state.uploadFileReducer.fileInfo,
    uploadLoading: state.uploadFileReducer.uploadLoading
});

const mapDispatcherToState = dispatch => ({
    formatFileList: () => dispatch(actions.formatListAction()),
    uploadFile: formData => dispatch(thunks.uploadFileThunk(formData)),
    removeFile: (id, assistId = null) => dispatch(thunks.removeFileThunk(id, assistId))
});

export default connect(mapStateToProps, mapDispatcherToState);
