import { deleteAssistantApi, deleteIntegration, deleteThreadApi, fetchThreadApi, loadAssistantList, manageKnowledgeApi } from "./api";
import { hideLoader, showLoader } from "../loader/actions";
import { loadAssistantListAction, setActiveAssistent, setThreadMsg } from "./actions";
import { sendMessage } from "@containers/messages/thunks";

export const loadAssistantListThunk = () => async dispatch => {
    try {
        dispatch(showLoader());
        const assistantList = await loadAssistantList();
        if (assistantList.data) {
            dispatch(loadAssistantListAction(assistantList.data));
            if (assistantList.data.length > 0) {
                dispatch(setActiveAssistent(assistantList.data[0].id));
            }
        } else {
            dispatch(sendMessage("Something went wrong!", true));
        }
    } catch (error) {
        dispatch(sendMessage("Something went wrong!", true));
    }
    dispatch(hideLoader());
};

export const deleteAssistantThunk = id => async dispatch => {
    try {
        dispatch(showLoader());
        const assistantList = await deleteAssistantApi(id);
        dispatch(loadAssistantListAction(assistantList.data));
        if (assistantList.data.length > 0) {
            dispatch(setActiveAssistent(assistantList.data[0]));
        }
    } catch (error) {
        dispatch(sendMessage("Something went wrong!", true));
    }
    dispatch(hideLoader());
    window.location.reload();
};

export const manageKnowledgeThunk = payload => async dispatch => {
    try {
        dispatch(showLoader());
        const res = await manageKnowledgeApi(payload);
        if (res.data) {
            dispatch(loadAssistantListAction(res.data));
        } else {
            dispatch(sendMessage("Something went wrong!", true));
        }
    } catch (error) {
        dispatch(sendMessage("Something went wrong!", true));
    }
    dispatch(hideLoader());
};


export const manageIntegrationThunk = payload => async dispatch => {
    try {
        dispatch(showLoader());
        const res = await deleteIntegration(payload);
        if (res.data) {
            dispatch(loadAssistantListAction(res.data));
        } else {
            dispatch(sendMessage("Something went wrong!", true));
        }
    } catch (error) {
        dispatch(sendMessage("Something went wrong!", true));
    }
    dispatch(hideLoader());
};

export const deleteThreadThunk = threadId => async dispatch => {
    try {
        dispatch(showLoader());
        const res = await deleteThreadApi(threadId);
        if (res.data) {
            dispatch(loadAssistantListAction(res.data));
        } else {
            dispatch(sendMessage("Something went wrong!", true));
        }
    } catch (error) {
        dispatch(sendMessage("Something went wrong!", true));
    }
    dispatch(hideLoader());
};

export const fetchThreadThunk = (threadId, uuid) => async dispatch => {
    try {
        dispatch(showLoader());
        const res = await fetchThreadApi(threadId, uuid);
        console.log('thread---', res)
        if (res.data) {
            dispatch(setThreadMsg(res.data));
        } else {
            dispatch(sendMessage("Something went wrong!", true));
        }
    } catch (error) {
        dispatch(sendMessage("Something went wrong!", true));
    }
    dispatch(hideLoader());
};
