import React from "react";
import { withRouter } from "react-router-dom";
import Routes from "components/routers";

import "./index.scss";

import Support from "./support";

const Container = props => {
    return (
        <div className="freshAppContainer">
            <Routes />
        </div>
    );
};

export default withRouter(Container);
