import React from "react";
import { AuthContainer } from "@containers";
import { Route, Redirect, withRouter } from "react-router-dom";
import { Auth } from "@services";

const trialRoutes = ["/pages", "/assistant", "/profile", "/facebook"];

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                rest.isAuthorised ? (
                    Auth.isTrial && !trialRoutes.includes(rest.path) ? (
                        <Redirect to="/assistant" />
                    ): Auth.isSocialTrial && !trialRoutes.includes(rest.path) ? (
                        <Redirect to="/facebook" />
                    ) : !Auth.isAdmin && rest.path === "/users" ? (
                        <Redirect to="/dashboard" />
                    ) : (
                        <Component {...props} />
                    )
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

export default withRouter(AuthContainer(PrivateRoute));
