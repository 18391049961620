import { Droppable } from "react-beautiful-dnd";
import ListItem from "./ListItem";
import React, { useState } from "react";
import styled from "styled-components";
import { Button, Confirm, Icon } from "semantic-ui-react";
import StageAutomation from "./StageAutomation";
import { getCurrencySymbol } from "../../../utils/common";

const ColumnHeader = styled.div`
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    color: #6b728f;
    padding: 10px 5px;
`;

const DroppableStyles = styled.div`
    margin: 5px;
    padding: 10px;
    border-radius: 7px;
    width: 330px;
    margin-bottom: 35px;
    border: 1px solid #dfdff0;
    background: #f6f6fa;
    min-height: 80vh;
    height:100%;
`;

const DraggableElement = ({
    prefix,
    elements,
    item,
    pipeline,
    leadActionGroup,
    noteMap,
    createUpdateAutoStage,
    searchLeads,
    onDeleteStage,
    ...rest
}) => {
    const [stageAutoModal, setStageAutoModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const totalValue = () => {
        let value = 0;
        elements.map(ele => {
            value += Number(ele.value);
        });
        return value;
    };

    const confirmDelete = async () => {
        const stageId = prefix.split("-")[1];
        await onDeleteStage(stageId);
        setDeleteModal(false);
        await searchLeads("");
        // window.location.reload();
    };

    return (
        <DroppableStyles>
            <Confirm className="confirm-modal" open={deleteModal} onCancel={() => setDeleteModal(false)} onConfirm={confirmDelete} />
            <ColumnHeader>
                <div className="d-flex align-center content-between">
                    <div className="pipelines-leadname">{item.label}</div>
                    <div className="d-flex">
                        <Button className="me-2" primary>
                            {getCurrencySymbol(pipeline.currency)}
                            {totalValue()}
                        </Button>
                        <div>
                            <Button className="white icon  pipeline" onClick={() => setDeleteModal(true)}>
                                <i className="flaticon stroke trash-1"></i>
                            </Button>
                            <Button className="white icon  pipeline" onClick={() => setStageAutoModal(true)}>
                                <i className="flaticon stroke pencil-1" />
                            </Button>
                        </div>
                    </div>
                </div>
            </ColumnHeader>
            <Droppable droppableId={`${prefix}`}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            minHeight: "60vh",
                            backgroundColor: snapshot.isDraggingOver ? "#edeff9" : "transparent"
                        }}
                    >
                        {elements.map((lead, index) => (
                            <ListItem
                                key={lead.id}
                                lead={lead}
                                index={index}
                                pipeline={pipeline}
                                leadActionGroup={leadActionGroup}
                                noteMap={noteMap}
                                {...rest}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            {stageAutoModal && (
                <StageAutomation
                    setStageAutoModal={setStageAutoModal}
                    createUpdateAutoStage={createUpdateAutoStage}
                    stageId={prefix}
                    stage={item.stage}
                    searchLeads={searchLeads}
                    {...rest}
                />
            )}
        </DroppableStyles>
    );
};

export default DraggableElement;
