import React, { Component } from "react";
import { compose } from "react-recompose";
import { Input, Grid, List, Button, Label, Progress, Tab, Form, Select, Confirm } from "semantic-ui-react";

import { BreadCrumbContainer, ProfileContainer, AssistantContainer, AssistantFormContainer } from "@containers";
import Loader from "../loader";
import AssistantModal from "../@common/modals/assistant";
import "./index.scss";
import "react-image-crop/dist/ReactCrop.css";
import moment from "moment";
import Conversations from "./Conversations";
import Appearence from "./Appearence";
import Settings from "./Settings";
import Knowledge from "./Knowledge";
import Integrations from "./Integrations";
import Embed from "./Embed";

const tabList = [
    { name: "Conversations", icon: "" },
    { name: "Appearence", icon: "ti ti-paint" },
    { name: "Settings", icon: "ti ti-settings" },
    { name: "Integrations", icon: "" },
    { name: "Knowledge", icon: "ti ti-writing" },
    { name: "Embed code", icon: "" }
];

class Assistant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assistantId: null,
            activeTab: 0
        };
    }
    componentWillMount() {
        this.props.addBreadCrumb({
            name: "Assistant",
            path: "/Assistant"
        });
        this.props.loadAssistantList();
    }

    handleAssistantSelect = (e, data) => {
        this.props.setActiveAssistant(data.value);

        const { assistantList } = this.props;
        const assistant = assistantList.find(ass => ass.id == data.value);

        if (assistant) {
            this.props.changeForm({
                ...assistant,
                type: "edit"
            });
        }
    };

    handleClickTab = activeTab => {
        this.setState({
            activeTab
        });
    };

    mainContent = () => {
        const { activeTab } = this.state;
        const { activeAssistant } = this.props;
        if (activeAssistant) {
            if (activeTab === 0) {
                return <Conversations {...this.props} />;
            } else if (activeTab === 1) {
                return <Appearence {...this.props} />;
            } else if (activeTab === 2) {
                return <Settings {...this.props} />;
            } else if (activeTab === 3) {
                return <Integrations {...this.props} />;
            } else if (activeTab === 4) {
                return <Knowledge {...this.props} />;
            } else if (activeTab === 5) {
                return <Embed {...this.props} />;
            } else {
                return <Conversations {...this.props} />;
            }
        }
    };

    render() {
        const { activeTab } = this.state;
        const { assistantList, activeAssistant } = this.props;

        const assistantOptions = assistantList.map(ass => ({
            value: ass.id,
            key: ass.id,
            text: ass.name
        }));

        return (
            <div className={"assistant"}>
                <Loader />
                <AssistantModal />
                <Grid className="h-100">
                    <Grid.Row className="container-row">
                        <Grid.Column className="left-bar">
                            <Grid>
                                <Grid.Row className="assistant-dropdown-box">
                                    <Grid.Column>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <Form>
                                                <Form.Field
                                                    control={Select}
                                                    className="sortby"
                                                    options={assistantOptions}
                                                    value={activeAssistant ? activeAssistant : ""}
                                                    search
                                                    searchInput={{ id: "assistant-list" }}
                                                    name="assistantId"
                                                    onChange={this.handleAssistantSelect}
                                                    placeholder="Select assistant"
                                                />
                                            </Form>
                                            <Button
                                                color="blue"
                                                size="small"
                                                className="bg-white round-5 me-3"
                                                onClick={() =>
                                                    this.props.showModal({
                                                        show: true,
                                                        type: "create",
                                                        name: "",
                                                        company_id: ""
                                                    })
                                                }
                                            >
                                                <i className="ti ti-plus" />
                                            </Button>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <img src="/img/newboticon.svg" />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <List className="property-list">
                                            {tabList.map((tabItem, key) => (
                                                <List.Item
                                                    key={key}
                                                    className={key === activeTab ? "active" : ""}
                                                    as="a"
                                                    onClick={() => this.handleClickTab(key)}
                                                >
                                                    <List.Content>
                                                        <List.Icon
                                                            className={tabItem.icon ? tabItem.icon : "ti ti-database"}
                                                        />
                                                        <span>{tabItem.name}</span>
                                                    </List.Content>
                                                </List.Item>
                                            ))}
                                            <List.Item as="a">
                                                <List.Content>
                                                    <a
                                                        href="https://app.screencast.com/8FM0dHFZDR1Qv"
                                                        target="__blank"
                                                    >
                                                        <i className="ti ti-brand-youtube"></i> How it works
                                                    </a>
                                                </List.Content>
                                            </List.Item>
                                        </List>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column className="right-bar">
                            {assistantList.length > 0 ? (
                                this.mainContent()
                            ) : (
                                <>
                                    <div style={{marginTop: "50px", padding: "0 90px"}}>Welcome. Looks like you haven't created a chat bot yet. Click the plus icon on the
                                    left of this page to start. <a href="#">Learn more</a></div>
                                </>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default compose(BreadCrumbContainer, ProfileContainer, AssistantContainer, AssistantFormContainer)(Assistant);
