import React from 'react';
import { compose, lifecycle } from 'react-recompose';
import 'react-toastify/dist/ReactToastify.css';

import { withRouter, Route,Switch } from 'react-router-dom';
import Layout from './layout';
import { AuthContainer, ProfileContainer } from '@containers';
import { LoginLayout, OptinFormPage, ResetPassword, Builder, Register, ChatbotPage, BuilderOptinForm, ThanksPage } from '.';

// import history from '../history';

import './App.scss';
import './static/linearicons.css';
import './static/stroke.css';
import './static/averta.css';
import './static/style.css';
import './static/my.custom.css';
import './static/tabler-icons.css';
import FBPublic from './fb-public';

const App = () => (
  <div className="App">
    {
      <Switch>
        <Route exact path="/login" component={withRouter(LoginLayout)} />
        <Route exact path="/password-reset" component={withRouter(ResetPassword)} />
        <Route exact path="/thanks" component={withRouter(ThanksPage)} />
        <Route exact path="/campaign/:uuid" component={withRouter(OptinFormPage)} />
        <Route exact path="/builder" component={withRouter(Builder)} />
        <Route exact path="/register-appsumo" component={withRouter(Register)} />
        <Route exact path="/chatbot" component={ChatbotPage} />

        <Route exact path="/companies/:companyId/deals/:dealId/campaigns/:campaignId/form-build" component={withRouter(BuilderOptinForm)} />

        <Route path="/" component={Layout} />
        
        
      </Switch>
    }
  </div>
);

export default withRouter(compose(AuthContainer, ProfileContainer, lifecycle({
  componentWillMount() {
    this.props.autoLogin();
    if (!localStorage.getItem('active-item')) localStorage.setItem('active-item', 0);
  },
}))(App));
