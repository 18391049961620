export const LOAD_HOOK = 'LOAD_HOOK';


// export const SAVED_LEAD = 'SAVED_LEAD';

export const CHANGE_LEAD = 'CHANGE_LEAD';

export const loadHook = payload => ({
  type: LOAD_HOOK,
  payload,
});

export const changeLead = form => ({
  type: CHANGE_LEAD,
  form,
});

// export const savedLead = () => ({
//   type: SAVED_LEAD,
// });
