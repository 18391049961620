import { CREATE_PROJECT, SAVED_FORM, CLOSE_FORM, CHANGE_FORM, SHOW_MODAL } from "./actions";

const initState = {
    show: false,
    form: {
        name: "",
        id: "",
        company_id: "",
        website: "",
        type: "create",
        chat_placeholder: "",
        accent_color: "",
        avatar: "",
        button_icon: "",
        show_branding: false,
        files: []
    },
    title: "New assistant"
};

const assistantForm = (state = initState, action) => {
    switch (action.type) {
        case SHOW_MODAL: {
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.form
                },
                show: true,
                title: action.form.type === "create" ? "New assistant" : "Add knowledge sources"
            };
        }
        case CHANGE_FORM: {
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.form
                }
            };
        }
        case CLOSE_FORM: {
            // const activeAssistantId = getState().assistant.activeAssistant;
            // const assistantList = getState().assistant.assistantList;
            // const activeAssistant = assistantList.find(ass => ass.id === activeAssistantId);
            // console.log({activeAssistantId, assistantList, activeAssistant })
            // dispatch(actions.changeForm(activeAssistant));

            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.form,
                    files: []
                },
                show: false
            };
        }
        case SAVED_FORM: {
            return {
                ...state,
                form: {
                    ...action.form
                },
                show: false
            };
        }
        //Page Manage Reducer
        default: {
            return state;
        }
    }
};

export default assistantForm;
