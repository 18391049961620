import {
  FB_ACCESS_TOKEN,
  FB_FOLDER_LIST,
  FB_LOAD_MORE,
  LOAD_PAGE_LIST,
  LOAD_LINKEDIN_LIST,
  SET_PAGE_LIST,
  LOAD_PROP_LIST
} from "./actions"

const initState = {
  // pageList: [],
  pageList: [],
  linkedinList: [],
  locationList: [
    {
      key: "all",
      value: "all",
      text: "Anywhere",
    },
  ],
  nextUrl: "",
  prevUrl: "",
  fbAccessToken: "",
  folderList: [],
  folderPageList: [],
  page: {},
}

const fbTool = (state = initState, action) => {
  switch (action.type) {
    case LOAD_PAGE_LIST: {
      return {
        ...state,
        pageList: action.pageBody.page_detail_list,
        nextUrl: action.pageBody.next_url,
        prevUrl: action.pageBody.prev_url,
      }
    }
    case LOAD_PROP_LIST: {
        return {
          ...state,
          pageList: action.list,
        }
      }
    case LOAD_LINKEDIN_LIST: {
      return {
        ...state,
        linkedinList: action.list,
      }
    }
    case FB_LOAD_MORE: {
      // const locationList = action.pageBody.location_list.map(location => ({
      //     key: location,
      //     value: location,
      //     text: location
      // }));
      const pageList = [...state.pageList, ...action.pageBody.page_detail_list]
      const locationList = state.locationList
      action.pageBody.location_list.map((location) => {
        const exLocation = locationList.find((ob) => ob.value)
        if (!exLocation) {
          locationList.push({
            key: location,
            value: location,
            text: location,
          })
        }
      })

      return {
        ...state,
        pageList: pageList,
        locationList: locationList,
        nextUrl: action.pageBody.next_url,
        prevUrl: action.pageBody.prev_url,
      }
    }
    case FB_ACCESS_TOKEN: {
      return {
        ...state,
        fbAccessToken: action.token,
      }
    }
    case FB_FOLDER_LIST: {
      return {
        ...state,
        folderList: action.list,
      }
    }
    case SET_PAGE_LIST: {
      return {
        ...state,
        folderPageList: action.folderPageList,
      }
    }
    default: {
      return state
    }
  }
}

export default fbTool
