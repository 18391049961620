import { Draggable } from "react-beautiful-dnd";
import React, { useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import { Button, Icon, Label } from "semantic-ui-react";
import ButtonGroup from "../../@common/button-group";
import { getCurrencySymbol } from "../../../utils/common";
import { LeadStatuses } from "../../../@models/lead-statuses";
import { avatarDemo } from "../../@common/forms/avatar-demo.png";
import ReactHtmlParser from 'html-react-parser';

const CardHeader = styled.div`
    font-weight: 500;
    color: #3c3a4e;
    font-size: 15px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;
    cursor: pointer;
`;

const Author = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;
const CardFooter = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
`;

const DragItem = styled.div`
    padding: 20px 25px;
    border-radius: 7px;
    box-shadow: 0px 3px 0 2px rgba(225, 225, 240, 0.33);
    background: #fff;
    margin: 0 0 8px 0;
    display: grid;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    border-left: solid 2px;
    text-align: left;
    outline: 1px solid #dfdff0;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
`;

const ListItem = ({ lead, index, pipeline, leadActionGroup, noteMap, onLeadEnterDisplayNotes }) => {

    return (
        <Draggable draggableId={lead.id.toString()} index={index}>
            {(provided, snapshot) => {
                return (
                    <DragItem
                        ref={provided.innerRef}
                        // isDraggingOver={snapshot.isDragging}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`lead-status-side-${lead.status[0].toLowerCase()}`}
                    >
                        <div className="d-flex content-between align-items">
                            <CardHeader onClick={() => onLeadEnterDisplayNotes(lead)}>{lead.fullname}</CardHeader>
                            <p className="lead-value">
                                {getCurrencySymbol(pipeline.currency)}
                                {lead.value}
                            </p>
                        </div>
                        <div>
                            <p className="stage-subtitle">{lead.campaign.name}</p>
                        </div>
                        <CardFooter className="leadfooter">
                            <div className="d-flex agent-interaction" style={{ paddingTop: "10px" }}>
                                <Author className="me-2">
                                    {/* <div className={`lead-status-icon lead-status-${lead.status[0].toLowerCase()}`}> */}
                                    <div
                                        className="circular icon-image-blue no-border"
                                        style={{
                                            backgroundImage:
                                                "url('" + (lead.agent ? lead.agent.avatar_path : avatarDemo) + "')",
                                            width: "30px",
                                            height: "30px"
                                        }}
                                    >
                                        {(lead.smsReplayCount && (
                                            <Label
                                                color="red"
                                                floating
                                                style={{ fontSize: "9px", borderRadius: "50%" }}
                                            >
                                                {lead.smsReplayCount}
                                            </Label>
                                        )) ||
                                            ""}
                                    </div>
                                    {/* {(lead.fullname && lead.fullname[0]) || LeadStatuses[lead.status].icon} */}
                                    {/* </div> */}
                                </Author>
                                <div className="text-left last-interaction">
                                    <p className="mb-0">
                                        <Icon name="calendar alternate" />
                                        {noteMap["note-" + lead.id] ? noteMap["note-" + lead.id][1] : ""}
                                    </p>
                                    <p>
                                        {noteMap["note-" + lead.id]
                                            ? ReactHtmlParser(noteMap["note-" + lead.id][0])
                                            : ""}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className="stage-button-group">{leadActionGroup(lead)}</div>
                            </div>
                        </CardFooter>
                    </DragItem>
                );
            }}
        </Draggable>
    );
};

export default ListItem;
