const steps_campaign_agents = [
    {
        id:                         "welcomecampaigns",
        classes:                    'bottom',
        modalOverlayOpeningPadding: "10",
        attachTo:                   {
            element: ".ui.left.floated.header",
            on:      "bottom"
        },
        scrollTo:                   false,
        beforeShowPromise:          function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },
        title:                      [
            "Getting started"],
        text:                       [
            "<div class='content-left'><h2>Your agents page</h2><p>A sales agent or rep is the person communicating with your leads.</p><p>Every lead you add inside ConvertLead will be assigned to an agent account.</p></div><div class='content-right'></div>"],
        buttons:                    [
            {
                classes: "shepherd-button-secondary",
                text:    "Exit",
                type:    "cancel"
            },
            {
                classes: "shepherd-button-primary",
                text:    "Previous",
                type:    "back"
            },
            {
                classes: "shepherd-button-primary blue",
                text:    "Next step",
                type:    "next"
            }
        ],
        when:                       {
            show: () => {
                console.log("show step");
            },
            hide: () => {
                console.log("hide step");
            }
        }
    },

    {
        id:                         "createagent",
        modalOverlayOpeningPadding: "10",
        classes:                    'bottom',
        attachTo:                   {
            element: ".new-campaign",
            on:      "bottom"
        },
        scrollTo:                   false,
        beforeShowPromise:          function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },

        advanceOn: {selector: '.new-campaign', event: 'click'},
        title:     [
            "Create your first agent account."],
        text:      [
            "<div class='content-left'><p> Click the plus icon button.</p></div><div class='content-right'></div>"],
        buttons:   [
            {
                classes: "shepherd-button-secondary",
                text:    "Exit",
                type:    "cancel"
            },
            {
                classes: "shepherd-button-primary",
                text:    "Previous",
                type:    "back"
            },

        ],
        when:      {
            show: () => {
                console.log("show step");
            },
            hide: () => {
                console.log("hide step");
            }
        }
    },

    {
        id:                         "agentsmodal",
        attachTo:                   {
            element: ".agentForm",
            on:      "right"
        },
        scrollTo:                   true,
        beforeShowPromise:          function () {
            return new Promise(function (resolve) {
                // Using JS To click on an element
                document.querySelector(".new-campaign").click();
                resolve();
            });
        },
        modalOverlayOpeningPadding: "10",
        title:                      [
            "Create a new agent account"],
        advanceOn:                  {selector: '.ui.multiple.search.selection.dropdown', event: 'mouseover'},
        text:                       [
            "<div class='content-left'><p>Fill in your agent details (use a unique e-mail address and phone number).</p><p> We'll send him an email with his login credentials and mobile app download once you're done.</p></div><div class='content-right'></div>"],
        buttons:                    [
            {
                classes: "shepherd-button-secondary",
                text:    "Exit",
                type:    "cancel"
            },
            {
                classes: "shepherd-button-primary",
                text:    "Previous",
                type:    "back"
            },
            {
                classes: "shepherd-button-primary blue",
                text:    "Next step",
                type:    "next"
            }
        ],
        when:                       {
            show: () => {
                console.log("show step");
            },
            hide: () => {
                console.log("hide step");
            }
        }
    },

    {
        id:       "detailshoverclose",
        classes:  'left',
        attachTo: {
            element: ".agentForm",
            on:      "left"
        },

        title:   [
            "Assign your agent to a company"],
        text:    [
            "<div class='content-left'><p>Every agent account needs to be assigned to a company. Use your own company account or add more from the companies page.</p></div><div class='content-right'></div>"],
        buttons: [
            {
                classes: "shepherd-button-secondary",
                text:    "Exit",
                type:    "cancel"
            },
            {
                classes: "shepherd-button-primary",
                text:    "Previous",
                type:    "back"
            },
            {
                classes: "shepherd-button-primary blue",
                text:    "Next step",
                type:    "next"
            }
        ],
        when:    {
            show: () => {
                console.log("show step");
            },
            hide: () => {
                console.log("hide step");
            }
        }
    },

    {
        id:       "saveagent",
        attachTo: {
            element: ".actions .button.left",
            on:      "right"
        },
        // advanceOn: { selector: ".ui.teal.left.labeled.button", event: "click"},
        useModalOverlay:            false,
        modalOverlayOpeningPadding: "10",
        title: [
            "Save your agent account"],
        text: [
            "<div class='content-left'><p>Make sure all the details are correct and save your agent. Click previous to edit any info.</p></div><div class='content-right'></div>"],
        buttons: [
            {
                classes: "shepherd-button-secondary",
                text:    "Exit",
                type:    "cancel"
            },
            {
                classes: "shepherd-button-primary",
                text:    "Previous",
                type:    "back"
            },

        ],
        when: {
            show: () => {
                console.log("show step");
            },
            hide: function() {
            }
        }
    },
    {
        id:       "congratsagent",
        classes:  'right',
        attachTo: {
            element: ".agentContainer",
            on:      "bottom"
        },
        scrollTo: true,
      beforeShowPromise: function() {
        return new Promise(function(resolve) {
          setTimeout(function() {
            window.scrollTo(0, 0);
            resolve();
          }, 500);
        });
      },
        title:    [
            "Congrats"],
        text:     [
            "<div class='content-left'><p>You now have your first agent account. Let’s find out what else we can do on this page. Click next.</p></div><div class='content-right'></div>"],
        buttons:  [
            {
                classes: "shepherd-button-secondary",
                text:    "Exit",
                type:    "cancel"
            },
            {
                classes: "shepherd-button-primary blue",
                text:    "Next step",
                type:    "next"
            }
        ],
       when:     {
            show: () => {
            },
            hide: function () {
            },
            cancel: function () {
            }
        }
    },

    {
        id:                "filters",
        classes:           'bottom',
        attachTo:          {
            element: ".ui.pointing.secondary.menu",
            on:      "bottom"
        },
        scrollTo:          false,
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },
        title:             [
            "Filters and sorting"],
        text:              [
            "<div class='content-left'><p>If you have a big sales team, you can use the filters or search bar to quickly find them. You can also export their data and sort them however you want.</p></div><div class='content-right'></div>"],
        buttons:           [
            {
                classes: "shepherd-button-secondary",
                text:    "Exit",
                type:    "cancel"
            },
            {
                classes: "shepherd-button-primary",
                text:    "Previous",
                type:    "back"
            },
            {
                classes: "shepherd-button-primary blue",
                text:    "Next step",
                type:    "next"
            }
        ],
        when:              {
            show: () => {
                console.log("show step");
            },
            hide: function () {
            },
            cancel: function () {
            }
        }
    },


    {
        id:                "export",
        classes:           'bottom',
        attachTo:          {
            element: ".exportbox",
            on:      "bottom"
        },
        scrollTo:          false,
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },
        title:             [
            "Exporting"],
        text:              [
            "<div class='content-left'><p>You can also easily export all your data and performance reports in both .pdf or .csv format.</p></div><div class='content-right'></div>"],
        buttons:           [
            {
                classes: "shepherd-button-secondary",
                text:    "Exit",
                type:    "cancel"
            },
            {
                classes: "shepherd-button-primary",
                text:    "Previous",
                type:    "back"
            },
            {
                classes: "shepherd-button-primary blue",
                text:    "Next step",
                type:    "next"
            }
        ],
        when: {
            show: () => {
                console.log("show step");
            },
            hide: function () {
            },
            cancel: function () {
            }
        }
    },

    {
        id:                "legend",
        classes:           'bottom',
        attachTo:          {
            element: ".legend",
            on:      "bottom"
        },
        scrollTo:          false,
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },
        title:             [
            "Agent stats and details"],
        text:              [
            "<div class='content-left'><p>Every agent has it's own preview card that shows status, number of leads or the company that each agent is assigned to.</p><p>Click on the avatar image to open the agent response time panel</p></div><div class='content-right'></div>"],
        buttons:           [
            {
                classes: "shepherd-button-secondary",
                text:    "Exit",
                type:    "cancel"
            },
            {
                classes: "shepherd-button-primary",
                text:    "Previous",
                type:    "back"
            },
            {
                classes: "shepherd-button-primary blue",
                text:    "Next step",
                type:    "next"
            }
        ],
        when: {
            show: () => {
                console.log("show step");
            },
            hide: function () {
            },
            cancel: function () {
            }
        }
    },

];


export default steps_campaign_agents;
