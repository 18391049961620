import React, { Component } from "react";
import { Grid, List, ListItem, ListContent, ListHeader } from "semantic-ui-react";
import * as moment from "moment";

import "./index.scss";

class Conversations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            msgList: [],
            activeThread: null
        };
    }

    distanceTwoDates = (startDate, isUnix = false) => {
        if (!startDate) {
            return;
        }
        let date1 = startDate;
        if (!isUnix) {
            date1 = moment(startDate);
        } else {
            date1 = moment.unix(startDate);
        }
        const date2 = moment();

        const daysDifference = date2.diff(date1, "days");
        if (daysDifference > 0) {
            return daysDifference + "days ago";
        }
        const hoursDiff = date2.diff(date1, "hours");
        if (hoursDiff > 0) {
            return hoursDiff + " hours ago";
        }
        const minsDiff = date2.diff(date1, "minutes");
        console.log({ minsDiff });
        if (minsDiff > 0) {
            return minsDiff + " minutes ago";
        }
        const secsDiff = date2.diff(date1, "seconds");
        return secsDiff + " seconds ago";
    };

    selectThread = (thread, uuid) => {
        this.setState({
            activeThread: thread
        });

        this.props.fetchThreadMsg(thread.ai_id, uuid);
    };

    generateShortName = thread => {
        const name = thread.user_name ? thread.user_name : thread.name;
        const txtArray = name.split(" ");
        if (txtArray.length === 0) {
            return "N/A";
        } else if (txtArray.length < 2) {
            return txtArray[0].charAt(0).toUpperCase();
        } else {
            return txtArray[0].charAt(0).toUpperCase() + txtArray[1].charAt(0).toUpperCase();
        }
    };

    getContrastColor = hexColor => {
        // Convert hex color to RGB
        let r = parseInt(hexColor.substr(1, 2), 16);
        let g = parseInt(hexColor.substr(3, 2), 16);
        let b = parseInt(hexColor.substr(5, 2), 16);

        // Calculate relative luminance
        let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        // Use a threshold to determine whether to use black or white text
        return luminance > 0.5 ? "black" : "white";
    };

    handleThreadDelete = (e, threadId) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.deleteThread(threadId);
    };

    render() {
        const { activeAssistant, assistantList, msgList } = this.props;
        const assistant = assistantList.find(ass => ass.id == activeAssistant);
        console.log({ assistant });
        const threadList = assistant ? assistant.threads : [];

        const { activeThread } = this.state;

        console.log({ msgList });

        return (
            <Grid className="h-100 assistant-container">
                <Grid.Row className="container-row">
                    <Grid.Column className="left-bar">
                        <List divided verticalAlign="middle">
                            <ListItem>
                                <List.Icon className="ti ti-database" />
                                <ListContent>
                                    <ListHeader>Conversations</ListHeader>
                                </ListContent>
                            </ListItem>
                            {threadList.map(thread => (
                                <ListItem
                                    key={thread.id}
                                    active={activeThread && thread.id === activeThread.id}
                                    onClick={() => this.selectThread(thread, assistant.uuid)}
                                >
                                    <List.Icon style={{ width: "45px", height: "45px" }}>
                                        <span className="avatar">{this.generateShortName(thread)}</span>
                                    </List.Icon>
                                    <ListContent className="conversation-tab-but">
                                        <ListHeader>
                                            <strong>{thread.user_name ? thread.user_name : thread.name}</strong>{" "}
                                            <span className="float-right">
                                                {this.distanceTwoDates(thread.created_at)}
                                            </span>
                                        </ListHeader>
                                        {/* <p className="mb-0"> Enter the exact domain name you want your ...</p> */}
                                        <span> {thread.user_email ? thread.user_email : "no email collected"}</span>
                                        <button
                                            className="delete-conversation"
                                            onClick={e => this.handleThreadDelete(e, thread.id)}
                                        >
                                            <i className="ti ti-trash" />
                                        </button>
                                    </ListContent>
                                </ListItem>
                            ))}
                        </List>
                    </Grid.Column>
                    <Grid.Column className="right-bar">
                        {!activeThread ? (
                            <p>Welcome! You can check chat history by clicking any thread</p>
                        ) : (
                            <>
                                <div className="chat-header">
                                    <span className="avatar">{this.generateShortName(activeThread)}</span>
                                    <div>
                                        <strong>
                                            {activeThread.user_name ? activeThread.user_name : activeThread.name}
                                        </strong>
                                        <p className="mb-0"> conversation history</p>
                                    </div>
                                </div>

                                <List className="chat-history">
                                    {msgList.messages &&
                                        msgList.messages.reverse().map(msg => {
                                            if (msg.role === "user") {
                                                return (
                                                    <ListItem key={msg.id}>
                                                        <ListContent className="text-right">
                                                            {msg.content[0].text.value}
                                                        </ListContent>
                                                    </ListItem>
                                                );
                                            } else {
                                                return (
                                                    <ListContent
                                                        className="text-left"
                                                        key={msg.id}
                                                        style={{
                                                            background: assistant.accent_color || "#4d77ff",
                                                            color: this.getContrastColor(
                                                                assistant.accent_color || "#4d77ff"
                                                            )
                                                        }}
                                                    >
                                                        {msg.content[0].text.value}
                                                    </ListContent>
                                                );
                                            }
                                        })}
                                </List>
                            </>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default Conversations;
