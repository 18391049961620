import { connect } from 'react-redux';
import * as thunks from './thunks';
import * as actions from './actions';

const mapStateToProps = state => ({
  // formSaved: state.forms.convertleadBot.formSaved,
  form: state.forms.convertleadBot.form,
  // show: state.forms.convertleadBot.form.show,
  // required: state.forms.convertleadBot.required,
});

const mapDispatcherToState = dispatch => ({
  loadForm: hook => dispatch(actions.loadHook(hook)),
  changeForm: lead => dispatch(actions.changeLead(lead)),
  saveForm: lead => dispatch(thunks.saveLead(lead)),
  // openLoadForm: lead => dispatch(actions.loadLead(lead)),

});

export default connect(mapStateToProps, mapDispatcherToState);
