import { TOUR_CURRENT } from './actions';

const initState = {
    leads: {
        tour: null
    },
    campaigns: {
        tour: null
    },
    companies: {
        tour: null
    },
    agents: {
        tour: null
    }
};

const tour = (state = initState, action) => {
    switch (action.type) {
        case TOUR_CURRENT: {
            return {
                ...state,
                [action.name]: {
                    tour: action.tour
                }
            };
        }
        default: {
            return state;
        }
    }
};

export default tour;
