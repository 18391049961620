import { api, Auth } from "@services";

export const fetchLeads = params => {
    return api.get(`/v1/${Auth.role}/leads`, {
        params
    });
};

export const fetchAllLeads = params => {
    return api.get(`/v1/${Auth.role}/leads-all`, {
        params
    });
};

export const deleteAgencyCompanyLead = (companyId, id, forceDelete) => {
    if(!forceDelete){
        return api.delete(`/v1/${Auth.role}/companies/${companyId}/leads/${id}`);
    } else {
        return api.delete(`/v1/${Auth.role}/companies/${companyId}/leads-force/${id}`);
    }
};

export const getTwilioTokenBy = leadId => {
    return api.get(`/v1/twilio/token/${leadId}`);
};

export const getOwnTwilioTokenBy = () => {
    return api.get(`/v1/twilio/own-token`);
};

export const exportTo = payload => {
    return api.post(`/v1/reports`, { payload });
};

export const reportPoll = uuid => {
    return api.get(`/v1/reports/${uuid}/poll`);
};

export const deleteCompanyLead = (id, forceDelete) => {
    if(!forceDelete){
        return api.delete(`/v1/${Auth.role}/leads/${id}`);
    } else {
        return api.delete(`/v1/${Auth.role}/leads-force/${id}`);
    }
};

export const leadSummaryApi = filter => {
    return api.get(`/v1/${Auth.role}/leads-summary?filter=${filter}`);
};
