import { LOAD_ASSISTENT_LIST, SET_ACTIVE_ASSISTENT, SET_THREAD_MSG } from "./actions";

const initState = {
    assistantList: [],
    activeAssistant: null,
    msgList: [],
};

const assistantReducer = (state = initState, action) => {
    switch (action.type) {
        case LOAD_ASSISTENT_LIST: {
            return {
                ...state,
                assistantList: action.payload
            };
        }
        case SET_ACTIVE_ASSISTENT: {
            return {
                ...state,
                activeAssistant: action.payload
            };
        }
        case SET_THREAD_MSG: {
            return {
                ...state,
                msgList: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default assistantReducer;
