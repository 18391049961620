import React, { Component } from "react";
import { Button, Form, Input } from "semantic-ui-react";
import "./index.scss";
import { compose } from "react-recompose";
import { BuildContainer } from "@containers";

class BuildForm extends Component {
    state = {};

    fileInputRef = React.createRef();

    componentDidMount() {}

    onChange = e => {
        this.props.changeForm({ name: e.target.value });
    };

    onChangeTitle = e => {
        this.props.changeForm({ pagetitle: e.target.value });
    };

    onChangeDescription = e => {
        this.props.changeForm({ description: e.target.value });
    };

    onChangeHandle = e => {
        this.props.changeForm({ [e.target.name]: e.target.value });
    };

    readFileAsDataURL = file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(file);
        });
    };

    fileChange = async e => {
        if (e.target.files.length > 0) {
            const base64Data = await this.readFileAsDataURL(e.target.files[0]);
            this.props.changeForm({ favicon: base64Data });
        } else {
            this.props.changeForm({ favicon: "" });
        }
    };

    render() {
        const { name, pagetitle, description, type, body_script, head_script, favicon } = this.props.form;
        return (
            <>
                {type === 2 ? (
                    <Form className="buildForm">
                        <Form.Field required>
                            <label>Page</label>
                            <Form.Field
                                control={Input}
                                placeholder={"Page Name"}
                                value={name || ""}
                                onChange={e => this.onChange(e)}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>Title</label>
                            <Form.Field
                                control={Input}
                                placeholder={"Title of your page here"}
                                value={pagetitle || ""}
                                onChange={e => this.onChangeTitle(e)}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>Description</label>
                            <Form.TextArea
                                placeholder={"Description"}
                                value={description || ""}
                                onChange={e => this.onChangeDescription(e)}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>Head Script</label>
                            <Form.TextArea
                                placeholder={"Head Script"}
                                value={head_script || ""}
                                name="head_script"
                                onChange={e => this.onChangeHandle(e)}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>Body Script</label>
                            <Form.TextArea
                                placeholder={"Body Script"}
                                value={body_script || ""}
                                name="body_script"
                                onChange={e => this.onChangeHandle(e)}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>Favicon</label>

                            <Button
                                content="Choose File"
                                labelPosition="left"
                                icon="file"
                                onClick={() => this.fileInputRef.current.click()}
                            />
                            <input ref={this.fileInputRef} type="file" hidden onChange={this.fileChange} />
                            {favicon && <img src={favicon} alt="favicon" width={50} />}
                            {/* <Form.TextArea
                                placeholder={"Description"}
                                value={description || ""}
                                onChange={e => this.onChangeDescription(e)}
                            /> */}
                        </Form.Field>
                    </Form>
                ) : (
                    <Form className="buildForm">
                        <Form.Field required>
                            <label>Page</label>
                            <Form.Field
                                control={Input}
                                placeholder={"Page Name"}
                                value={name || ""}
                                onChange={e => this.onChange(e)}
                            />
                        </Form.Field>
                    </Form>
                )}
            </>
        );
    }
}

export default compose(BuildContainer)(BuildForm);
