import { Grid, Icon, Segment, Sidebar, Menu } from "semantic-ui-react";
import React, { Component } from "react";
import { compose } from "react-recompose";
import AppSidebar from "../sidebar";
import Container from "./container";
import Header from "./header";
import Footer from "./footer";
import { Link } from "react-router-dom";
import { AvatarImage } from "components/@common/image";
import "./index.scss";
import { AuthContainer } from "@containers";
import { Auth } from "@services";
import { Media, mediaStyle, MediaContextProvider } from "components/@common/media";

class DesktopContainer extends Component {
    state = {};
    render() {
        return (
            <>
                <style>{mediaStyle}</style>
                <MediaContextProvider>
                    <Segment as={Media} greaterThan="mobile">
                        <div className="freshAppLayout">
                            <Grid padded="horizontally" columns={2}>
                                {this.props.isAuthorised ? (
                                    <>
                                        <Grid.Column width={1}>
                                            <AppSidebar />
                                        </Grid.Column>
                                        <Grid.Column width={15}>
                                            {!Auth.isAgent ? <Header /> : null}
                                            <Container />
                                            <Footer />
                                        </Grid.Column>
                                    </>
                                ) : (
                                    <Grid.Column width={16}>
                                        <Container />
                                        <Footer />
                                    </Grid.Column>
                                )}
                            </Grid>
                        </div>
                    </Segment>
                </MediaContextProvider>
            </>
        );
    }
}

class MobileContainer extends Component {
    state = {};

    handleSidebarHide = () => {
        this.setState({ sidebarOpened: false });
    };
    handleToggle = () => this.setState({ sidebarOpened: true });
    render() {
        const { sidebarOpened } = this.state;

        return (
            <>
                <style>{mediaStyle}</style>
                <MediaContextProvider>
                    <Segment as={Media} at="mobile">
                        <Sidebar animation="push" onHide={this.handleSidebarHide} visible={sidebarOpened}>
                            <AppSidebar onClickMenuItem={this.handleSidebarHide} />
                        </Sidebar>

                        <Sidebar.Pusher dimmed={sidebarOpened}>
                            <Segment textAlign="center" style={{ padding: 0 }}>
                                {/*<Container>*/}
                                <Menu pointing secondary size="large" style={{ margin: 0, display: "unset" }}>
                                    <Menu.Item
                                        style={{
                                            zIndex: 9999,
                                            color: "#3c3a4e",
                                            position: "absolute",
                                            top: "30px",
                                            left: "10px"
                                        }}
                                        onClick={this.handleToggle}
                                    >
                                        <Icon name="sidebar" />
                                    </Menu.Item>
                                </Menu>
                                {/*</Container>*/}
                                <Grid padded="horizontally">
                                    <Grid.Column>
                                        {!Auth.isAgent ? <Header /> : null}
                                        <Container />
                                        <Footer />
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        </Sidebar.Pusher>
                    </Segment>
                </MediaContextProvider>
            </>
        );
    }
}

const Layout = props => (
    <div>
        <DesktopContainer {...props} />
        <MobileContainer />
    </div>
);

export default compose(AuthContainer)(Layout);
