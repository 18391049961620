import React, { Component } from "react";
import { compose } from "react-recompose";
import { Icon, Segment, Button, Grid, Header, Form } from "semantic-ui-react";
import {
    BreadCrumbContainer,
    LeadNotesContainer,
    LeadFormContainer,
    LeadsContainer,
    EmailFormContainer
} from "@containers";
import TimeLine from "./timeline";
import Loader from "components/loader";
import "./index.scss";
import LeadModal from "components/@common/modals/lead";
import EmailModal from "components/@common/modals/email";
import { config } from "@services";
import { Device } from "twilio-client";
import JoditEditor from "jodit-react";

const editor = React.createRef();
const jdConfig = {
    readonly: false,
    extraButtons: ["CustomTags"],
    minHeight: "300px"
};

class LeadNotes extends Component {
    state = {
        onPhone: false,
        readyToCall: false,
        showSMSModal: false,
        form: {
            message: ""
        }
    };

    async componentWillMount() {
        const { companyId, leadId } = this.props.match.params;
        this.props.loadLead(companyId, leadId);
        this.props.addBreadCrumb({
            name: "Leads",
            path: "/leads"
        });
        this.props.fetchTwilioTokenBy(leadId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.twilioToken !== this.props.twilioToken && this.props.twilioToken) {
            Device.setup(this.props.twilioToken);
        }
    }

    componentDidMount() {
        Device.disconnect(() => {
            this.setState({
                onPhone: false
            });
        });
        Device.ready(() => {
            this.setState({
                readyToCall: true
            });
        });
    }

    onCall = () => {
        const checkIsValidNumber = /^([0-9]|#|\*)+$/.test(this.props.lead.phone.replace(/[+\-()\s]/g, ""));
        if (this.state.onPhone) {
            Device.disconnectAll();
        } else if (this.props.twilioToken && checkIsValidNumber) {
            Device.connect({ number: this.props.lead.phone });
            this.setState({
                onPhone: true
            });
        }
    };

    setSMSModal = () => {
        this.setState({ showSMSModal: !this.state.showSMSModal, form: { message: "" } });
    };

    onChangeSMSMessage = data => {
        this.setState({
            form: { message: data }
        });
    };

    onCancelSendSMS = () => {
        this.setState({ showSMSModal: false, form: { message: "" } });
    };

    onSendSMS = () => {
        if (this.state.form.message === "") {
            return;
        }

        this.props.sendSMSMessage(this.state.form);

        this.setState({ showSMSModal: false, form: { message: "" } });

        let { companyId, leadId } = this.props;
        setTimeout(() => {
            this.props.loadLead(companyId, leadId, true, true);
        }, 5000);
    };

    onAddNote = form => {
        this.props.createLeadNote({
            ...form,
            status: form.status ? form.status : this.props.lead.status
        });
    };

    exportToPDF = (companyId, leadId) => {
        this.props.exportToPDF(companyId, leadId);
    };

    render() {
        const { lead, leadNotes, leadStatuses, twilioToken } = this.props;
        const { onPhone, form } = this.state;

        return (
            <div className="LeadNotes">
                <LeadModal size="small" />
                <EmailModal size="small" />
                <Grid.Column width={6}>
                    <Segment className="lead-profile">
                        <div className="lead-profile-row">
                            <div className={`timeline-status timeline-bg-color-${lead.status.charAt(0).toLowerCase()}`}>
                                <span>{(lead.fullname && lead.fullname.charAt(0)) || lead.status.charAt(0)}</span>
                            </div>
                            <div className="lead-profile-value fullname">{lead.fullname}</div>

                            <Grid.Column style={{ textAlign: "center" }}>
                                <div className={"ui secondary menu leadnotes"}>
                                    <Button
                                        circular
                                        className="email icon"
                                        // as="a" href={`mailto:${lead.email}`}
                                        onClick={this.props.showModal.bind(this, {
                                            ...lead,
                                            company_id: lead.company.id,
                                            show: true
                                        })}
                                    >
                                        <Icon className="icon ti-mail-forward ti" />
                                    </Button>

                                    <Button
                                        circular
                                        className="editlead icon"
                                        onClick={this.props.loadForm.bind(this, {
                                            ...lead,
                                            company_id: lead.company.id,
                                            show: true
                                        })}
                                    >
                                        <Icon className="icon ti-pencil ti" />
                                    </Button>
                                    {twilioToken && lead.phone && (
                                        <>
                                            <Button
                                                circular
                                                className={onPhone ? "endCall icon" : "onCall icon"}
                                                onClick={this.onCall}
                                            >
                                                <Icon className="icon ti-phone ti" />
                                            </Button>
                                            <Button circular className="sms icon" onClick={this.setSMSModal}>
                                                <Icon className="ti-device-mobile-message ti icon" />
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </Grid.Column>
                            {this.state.showSMSModal && (
                                <Form>
                                    <Form.Field>
                                        <label className="sendtexthead">Send a text message</label>

                                        <JoditEditor
                                            ref={editor}
                                            value={form.message || ""}
                                            config={jdConfig}
                                            tabIndex={1}
                                            onChange={this.onChangeSMSMessage}
                                            name="message"
                                            className="jodit-sms"
                                        />

                                        {/* <TextArea
                                                className="text-sms"
                                                name="message"
                                                onChange={this.onChangeSMSMessage}
                                            /> */}
                                    </Form.Field>
                                    <Button.Group>
                                        <Button onClick={this.onCancelSendSMS}>Cancel</Button>
                                        <Button.Or />
                                        <Button type="button" onClick={this.onSendSMS} positive>
                                            Send
                                        </Button>
                                    </Button.Group>
                                </Form>
                            )}
                            <div className="lead-profile-row">
                                <div className="lead-profile-label">
                                    <label>Business Name</label>
                                </div>
                                <div className="lead-profile-value">{lead.business_name}</div>
                            </div>
                            <div className="lead-profile-row">
                                <div className="lead-profile-label">
                                    <label>Phone</label>
                                </div>
                                <div className="lead-profile-value">{lead.phone}</div>
                            </div>
                            <div className="lead-profile-row">
                                <div className="lead-profile-label">
                                    <label>Email</label>
                                </div>
                                <div className="lead-profile-value">{lead.email}</div>
                            </div>
                            <div className="lead-profile-row">
                                <div className="lead-profile-label">
                                    <label>Integration</label>
                                </div>
                                <div className="lead-profile-value">{lead.campaign.name}</div>
                            </div>
                            <div className="lead-profile-row">
                                <div className="lead-profile-label">
                                    <label>Assigned to</label>
                                </div>
                                <div className="lead-profile-value">{lead.agent.name}</div>
                            </div>
                            <div className="lead-profile-row">
                                <div className="lead-profile-label">
                                    <label>Company</label>
                                </div>
                                <div className="lead-profile-value">{lead.company.name}</div>
                            </div>
                            {lead.stage && (
                                <div className="lead-profile-row">
                                    <div className="lead-profile-label">
                                        <label>Stage</label>
                                    </div>
                                    <div className="lead-profile-value">{lead.stage.name}</div>
                                </div>
                            )}
                            <div className="lead-profile-row">
                                <div className="lead-profile-label">
                                    <label>Value</label>
                                </div>
                                <div className="lead-profile-value">{lead.value}</div>
                            </div>

                            <div className="lead-profile-row">
                                <div className="lead-profile-label">
                                    <label>Address</label>
                                </div>
                                <div className="lead-profile-value">{lead.address}</div>
                            </div>
                            <div className="lead-profile-row">
                                <div className="lead-profile-label">
                                    <label>Website</label>
                                </div>
                                <div className="lead-profile-value">{lead.website}</div>
                            </div>

                            <div className="lead-profile-row additionalinfo">
                                <div className="lead-profile-label-additional ">
                                    <label>Additional information:</label>
                                </div>
                            </div>
                            {!!lead.metadata && (
                                <div className="lead-profile-row">
                                    <p>{lead.metadata}</p>
                                </div>
                            )}
                        </div>
                    </Segment>
                </Grid.Column>

                <Segment attached="top" className="pagehead">
                    <Grid.Column floated="left" style={{ textAlign: "left" }}>
                        <Header floated="left" as="h1">
                            Lead timeline
                        </Header>
                    </Grid.Column>
                </Segment>

                <Segment attached="top">
                    <Segment basic className={"notoppad"}>
                        <Loader />

                        <div className="export-data">
                            Export your data
                            <a href={`${config.get("REACT_APP_API_SERVER")}/v1/leads/${lead.id}/export-csv`}>
                                .csv export
                            </a>
                            <a href={`${config.get("REACT_APP_API_SERVER")}/v1/leads/${lead.id}/export-pdf`}>
                                .pdf export
                            </a>
                        </div>

                        <Grid columns="equal">
                            <Grid.Row>
                                <Grid.Column textAlign="left">
                                    <Segment basic>
                                        <TimeLine
                                            notes={leadNotes}
                                            lead={lead}
                                            onAddNote={this.onAddNote}
                                            leadStatuses={leadStatuses}
                                        />
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Segment>
            </div>
        );
    }
}

export default compose(
    BreadCrumbContainer,
    LeadsContainer,
    LeadNotesContainer,
    LeadFormContainer,
    EmailFormContainer
)(LeadNotes);
