import React, { Component } from "react";
import { compose } from "react-recompose";
import { BreadCrumbContainer, AgentsContainer, AgentFormContainer, CompaniesContainer } from "@containers";
// LeadsContainer
import AgentModal from "../@common/modals/agent";
import { Button, Confirm, Form, Grid, Header, Input, Menu, Pagination, Segment, Select, Tab } from "semantic-ui-react";
import "./index.scss";
import * as R from "ramda";
import { Auth } from "@services";
import { disableAutoComplete } from "../../utils";
import AgentProfile from "../agent-profile";
import * as moment from "moment";
import ButtonGroup from "../@common/button-group";
import Loader from "../loader";
import { avatarDemo } from "../@common/forms/avatar-demo.png";
import Tour from "../tour";

const companies = [{ key: null, text: "All companies", value: null }];

class Agents extends Component {
    state = {
        open: false,
        agentId: null,
        agent: {},
        companyId: null,
        activeIndex: 0,
        startDateDisplay: moment()
            .startOf("isoWeek")
            .format("MM/DD/Y"),
        endDateDisplay: moment()
            .endOf("isoWeek")
            .format("MM/DD/Y"),
        startDate: "2000-01-01",
        endDate: moment()
            .endOf("isoWeek")
            .format("Y-MM-DD"),
        sortByValue: "name.asc",
        forceDelete: false
    };

    async componentWillMount() {
        const companyId = +R.pathOr("", ["match", "params", "companyId"], this.props);
        this.props.addBreadCrumb({
            name: "Agents",
            path: "/agents"
        });
        this.props.filterAgents({
            companyId
        });

        if (Auth.isAgency) {
            this.props.loadSelectBoxCompanies("");
        }

        this.setState({
            companyId: companyId
        });

        /**
         * @func: Load Agents Data
         */
        if (this.props.query.showDeleted) {
            this.props.toggleAgentsShowDeleted();
        } else {
            this.props.loadAgents();
        }
    }

    onChangeCompany = (event, data) => {
        this.props.filterAgents({
            companyId: data.value
        });
    };

    onClickViewAgentProfile = agent => {
        document.getElementsByClassName("Leads")[0].className = "Leads sidebarOpened";
        this.setState({
            agent
        });
    };

    getSort = field => {
        const fieldStatus = R.path(["query", "sort", field], this.props);
        if (fieldStatus === true) {
            return "sort amount down";
        }
        if (fieldStatus === false) {
            return "sort amount up";
        }
        return "sort";
    };

    sortBy = (event, data) => {
        this.setState({ sortByValue: data.value });
        this.props.sortAgentsBy(data.value);
    };

    onSearch = (event, data) => {
        this.props.search(data.value);
    };

    onShowArch = (e, tab) => {
        if (tab.activeIndex === this.state.activeIndex) {
            return;
        }

        this.setState({
            activeIndex: tab.activeIndex
        });

        this.props.toggleAgentsShowDeleted();
    };

    onPreviewAgentChange = agent => {
        // this.setState({
        //   ...this.state,
        //   previewLeadId: agent.id,
        //   companyId: agent.company_id
        // })
    };

    componentDidMount() {
        disableAutoComplete();
    }

    openConfirmModal = (open = true, agentId = null, forceDelete = false) => {
        this.setState({ open, agentId, forceDelete });
    };

    onConfirm = () => {
        this.setState({ open: false });
        this.props.delete(this.state.agentId, this.state.forceDelete);
    };

    onRestore = agentId => {
        this.props.restore(agentId);
    };

    onDateChange = (startDate, endDate) => {
        this.setState({
            ...this.state,
            startDate,
            endDate
        });
    };

    exportTo = type => {
        this.props.exportTo({
            type,
            statusType: this.props.query.filters.statusType,
            search: this.props.query.search,
            showDeleted: this.props.query.showDeleted,
            companyId: this.props.query.filters.companyId,
            campaignId: this.props.query.filters.campaignId,
            startDate: this.props.query.filters.startDate || this.state.startDate,
            endDate: this.props.query.filters.endDate || this.state.endDate
        });
    };

    /**
     * Set Companies of selected agent.
     */
    onSetCompaniesOfAgent = companies => {
        return companies.map(company => {
            return {
                key: company.id,
                value: company.id,
                text: company.name
            };
        });
    };

    gotoPage = (event, data) => {
        this.props.gotoPage(data.activePage);
    };

    render() {
        const agents = this.props.agents || [];
        const { query, paginationAgent: pagination } = this.props;
        const { companyId, agent } = this.state;

        const tabs = [
            {
                menuItem: "Active",
                render: () => <></>
            },
            {
                menuItem: "Archived",
                render: () => <></>
            }
        ];

        const sorByFiled = [
            {
                key: "name.desc",
                value: "name.desc",
                text: "Name Descending"
            },
            {
                key: "name.asc",
                value: "name.asc",
                text: "Name Ascending"
            }
        ];

        return (
            <div className="Leads">
                <Tour page={"agents"}></Tour>
                <div className="leads-container">
                    <Segment attached="top">
                        <Grid columns={2}>
                            <Grid.Column>
                                <Header floated="left" as="h1">
                                    Agents
                                </Header>
                            </Grid.Column>
                            <Grid.Column>
                                <Menu secondary>
                                    <Menu.Menu position="right">
                                        <Menu.Item>
                                            <Input
                                                icon="search"
                                                onChange={this.onSearch}
                                                value={query.search || ""}
                                                placeholder="Search..."
                                            />
                                        </Menu.Item>
                                        <Button
                                            color="teal"
                                            className="new-campaign"
                                            onClick={this.props.loadForm.bind(this, { show: true })}
                                        >
                                            <i className="flaticon stroke plus-1  icon"></i>
                                        </Button>
                                    </Menu.Menu>
                                </Menu>
                            </Grid.Column>
                        </Grid>
                    </Segment>
                    <Tab onTabChange={this.onShowArch} menu={{ secondary: true, pointing: true }} panes={tabs} />
                    <Confirm
                        className="confirm-modal"
                        open={this.state.open}
                        onCancel={() => this.openConfirmModal(false)}
                        onConfirm={this.onConfirm}
                    />
                    <Segment basic style={{height:"100%"}}>
                        <div className="leadFilters">
                            <div className="field">
                                <Form>
                                    <Form.Group widths="equal" className="filter white">
                                        {Auth.isAgency ? (
                                            <Form.Field
                                                control={Select}
                                                options={[...companies, ...this.props.selectBoxCompanies]}
                                                placeholder="All companies"
                                                search
                                                onChange={this.onChangeCompany}
                                                defaultValue={companyId || null}
                                                searchInput={{ id: "form-companies-list" }}
                                            />
                                        ) : null}
                                    </Form.Group>

                                    <Form.Field
                                        control={Select}
                                        className="sortby"
                                        options={sorByFiled}
                                        label={{ children: "Sort by", htmlFor: "leads-sort-by" }}
                                        placeholder="Sort by "
                                        search
                                        onChange={this.sortBy}
                                        defaultValue={this.state.sortByValue}
                                        onSearchChange={this.sortBy}
                                        searchInput={{ id: "campaign-sort-by" }}
                                    />
                                </Form>
                            </div>

                            <div className="exportbox">
                                Export your data
                                <a href="#export-csv" onClick={this.exportTo.bind(this, "TYPE_AGENT_CSV")}>
                                    .csv export
                                </a>
                                <a href="#export-pdf" onClick={this.exportTo.bind(this, "TYPE_AGENT_PDF")}>
                                    .pdf export
                                </a>
                            </div>
                        </div>
                        <Loader />
                        {agents.length > 0 ? (
                            agents.map((agent, index) => (
                                <div key={index} data-id={agent.id} className="agentContainer">
                                    <div className="agentMenu">
                                        <div className="bullets">...</div>
                                        {
                                            <ButtonGroup>
                                                <Button
                                                    id={agent.agent_agency_id}
                                                    style={{ width: "90px" }}
                                                    onClick={this.props.loadForm.bind(this, { ...agent, show: true })}
                                                >
                                                    Edit
                                                </Button>
                                                {(!agent.deleted_at && (
                                                    <Button onClick={() => this.openConfirmModal(true, agent.id)}>
                                                        Archive
                                                    </Button>
                                                )) || <Button onClick={() => this.onRestore(agent.id)}>Restore</Button>}
                                                {/* <Button onClick={() => this.openConfirmModal(true, agent.id, true)}>
                                                    Delete
                                                </Button> */}
                                            </ButtonGroup>
                                        }
                                    </div>
                                    <div className="agentDetails" onClick={() => this.onClickViewAgentProfile(agent)}>
                                        <div className="agentAvatar">
                                            <div className="legend">
                                                {(this.state.activeIndex === 0 && agent.campaigns_count !== 0 && (
                                                    <>
                                                        <span className="legendCount">{agent.leads_count}</span>
                                                        <span className="legendName-blue">Leads</span>
                                                        <div
                                                            className="circular icon-image-blue"
                                                            style={{
                                                                backgroundImage:
                                                                    "url('" + (agent.avatar_path || avatarDemo) + "')"
                                                            }}
                                                        ></div>
                                                    </>
                                                )) || (
                                                    <>
                                                        <span className="legendCount">{agent.leads_count}</span>
                                                        <span className="legendName-red">Leads</span>
                                                        <div
                                                            className="circular icon-image-red"
                                                            style={{
                                                                backgroundImage:
                                                                    "url('" + (agent.avatar_path || avatarDemo) + "')"
                                                            }}
                                                        ></div>
                                                    </>
                                                )}
                                            </div>

                                            <div className="agentName">{agent.name}</div>
                                        </div>
                                        <div className="integrationCount">
                                            <span>
                                                {/* {agent.integration_count || 0} */}
                                                {this.state.activeIndex === 0 && agent.campaigns_count !== 0
                                                    ? agent.campaigns_count
                                                    : 0}
                                                &nbsp; Integrations
                                            </span>
                                        </div>
                                        <div className="campaignStatus">
                                            {(this.state.activeIndex === 0 && agent.campaigns_count !== 0 && (
                                                <button className="ui teal button active-btn">Active</button>
                                            )) || <button className="ui teal button inactive-btn">Inactive</button>}
                                        </div>
                                        {agent.companies && (
                                            <div className="campaignNames">
                                                <span>assigned to</span>
                                                {this.state.activeIndex === 0 &&
                                                    agent.companies &&
                                                    agent.companies.map(({ name, id }) => (
                                                        <div key={id} className="campaignName">
                                                            {name}
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="d-flex text-left empty-deal-wrapper">
                                <p className="mr-1">
                                    Looks like you don't have any agent accounts yet. Check our dashboard onboarding
                                    videos or
                                </p>
                                <a
                                    href="javascript:void(0)"
                                    onClick={this.props.loadForm.bind(this, { show: true })}
                                    style={{ margin: "0 10px" }}
                                >
                                    click here
                                </a>

                                <p className="mr-1">to create your first agent account.</p>
                            </div>
                        )}
                        <div className="pagination-footer-box" style={{ width: "100%" }}>
                            <Segment textAlign="right" attached="bottom">
                                <Pagination
                                    onPageChange={this.gotoPage}
                                    defaultActivePage={pagination.current_page}
                                    prevItem={null}
                                    nextItem={null}
                                    totalPages={pagination.last_page}
                                />
                            </Segment>
                        </div>
                    </Segment>

                    <AgentModal />
                    {agent.id && (
                        <AgentProfile
                            s_agent={agent}
                            agentId={agent.id}
                            onDateChange={this.onDateChange}
                            companiesOfAgent={this.onSetCompaniesOfAgent(agent.companies)}
                            onClose={() => this.setState({ agent: {} })}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default compose(BreadCrumbContainer, CompaniesContainer, AgentsContainer, AgentFormContainer)(Agents);
// LeadsContainer|
