import { CHANGE_ACTIVE_PIPELINE, LOAD_PIPELINE, PIPELINE_FORM } from "./actions";

const initState = {
    pipelines: [],
    activePipeline: null,
    openForm: false
};
const pipelinesReducer = (state = initState, action) => {
    switch (action.type) {
        case LOAD_PIPELINE: {
            return {
                ...state,
                pipelines: action.pipelines
            };
        }
        case CHANGE_ACTIVE_PIPELINE: {
            return {
                ...state,
                activePipeline: action.pipeline
            };
        }
        case PIPELINE_FORM: {
            return {
                ...state,
                openForm: action.openForm
            };
        }
        default: {
            return state;
        }
    }
};

export default pipelinesReducer;
