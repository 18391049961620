import * as actions from "./actions";
import { sendMessage } from "@containers/messages/thunks";
import {
    postIntegration,
} from "./api";
import { loadAssistantListAction } from "../../assistant/actions";

export const saveLead = form => async dispatch => {
    try {
        let payload = {};
        if (form.hook === "convertlead") {
            payload = {
                id: form.id,
                campaign: form.campaign,
                integration: form.integration,
                add_fields: form.add_fields,
                hook: form.hook,
                prompt: form.prompt,
            };
        } else {
            payload = {
                id: form.id,
                webhook_url: form.webhook_url,
                add_fields: form.add_fields,
                hook: form.hook,
                prompt: form.prompt,
            };
        }
        const res = await postIntegration(payload);
        dispatch(loadAssistantListAction(res.data));
        dispatch(actions.loadHook({ show: false }));
        dispatch(sendMessage("Success!"));
    } catch (e) {
        dispatch(sendMessage(e.message, true));
    }
};
