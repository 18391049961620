import * as actions from './actions';
import {api, Auth} from "../../@services";
import {hideLoader, showLoader} from "../loader/actions";
import {sendMessage} from "../messages/thunks";
import {fetchAgencyDealCampaigns, fetchAgentCompanies, fetchCompanyDealCampaigns} from "./api";
import { idbGetIem } from '../../middlewares/idb';

export const fetchCampaigns = () => async (dispatch, getState) => {
  try {
    await dispatch(showLoader());
    const { pagination, companyId, dealId, agentId, query } = getState().campaigns;
    let response;
    if (Auth.isAgency && companyId && dealId) {
      response = await fetchAgencyDealCampaigns(companyId, dealId, {
        current_page: pagination.current_page,
        per_page: pagination.per_page,
        showDeleted: (query.showDeleted ? query.showDeleted : null),
        ...query.sort,
      });
    } else if (dealId) {
      response = await fetchCompanyDealCampaigns(dealId, {
        current_page: pagination.current_page,
        per_page: pagination.per_page,
        showDeleted: (query.showDeleted ? query.showDeleted : null),
        ...query.sort,
      });
    } else {
      response = await fetchAgentCompanies(agentId, {
        current_page: pagination.current_page,
        per_page: pagination.per_page,
        showDeleted: (query.showDeleted ? query.showDeleted : null),
        ...query.sort,
      });
    }

    const { data, ...rest } = response.data;
    await dispatch(actions.loadDealCampaigns(data, rest));
  } catch (e) {
    dispatch(sendMessage(e.message, true));
  }

  await dispatch(hideLoader());
};

export const loadDealCampaigns = (companyId, dealId) => async (dispatch, getState) => {
  try {
    await dispatch(actions.fetchAgentCampaigns(''));
    await dispatch(actions.fetchDealCampaigns(companyId, dealId));
    await dispatch(fetchCampaigns());
  } catch (e) {
    dispatch(sendMessage(e.message, true));
  }
};
export const loadAgentCampaigns = agentId => async dispatch => {
  try {
    await dispatch(actions.fetchDealCampaigns('', ''));
    await dispatch(actions.fetchAgentCampaigns(agentId));
    await dispatch(fetchCampaigns());
  } catch (e) {
    dispatch(sendMessage(e.message, true));
  }
};

export const toggleShowDeletedCampaigns = () => async dispatch => {
  await dispatch(actions.toggleShowDeletedCampaigns());
  await dispatch(fetchCampaigns());
};

export const subscribeToFbIntegration = (campaignId, integration) => async dispatch => {
  try {
    await api.post(`/v1/${Auth.role}/campaigns/${campaignId}/fb-integrations`, integration);
    await dispatch(fetchCampaigns());
  } catch (e) {
    dispatch(sendMessage(e.message, true))
  }
};

export const unsubscribeToFbIntegration = (campaignId, integrationId) => async dispatch => {
  try {
    await api.delete(`/v1/${Auth.role}/campaigns/${campaignId}/fb-integrations/${integrationId}`);
    await dispatch(fetchCampaigns());
  } catch (e) {
    dispatch(sendMessage(e.message, true))
  }
};

export const gotoPage = page => async dispatch =>  {
  await dispatch(actions.gotoPageCampaigns(page));
  await dispatch(fetchCampaigns());
};

export const sortCampaigns = field => async dispatch =>  {
  await dispatch(actions.sortCampaigns(field));
  await dispatch(fetchCampaigns());
};

export const deleteCampaign = (companyId, dealId, campaignId, force) => async dispatch =>  {
  try {
    if(force){
      await api.delete(`/v1/${Auth.role}/companies/${companyId}/deals/${dealId}/campaigns-force/${campaignId}`);
    } else {
      await api.delete(`/v1/${Auth.role}/companies/${companyId}/deals/${dealId}/campaigns/${campaignId}`);
    }
    await dispatch(fetchCampaigns());
  } catch (e) {
    dispatch(sendMessage(e.message, true))
  }
};

export const saveIntegrationPage = (editor, preview_image, site) => async dispatch => {
  try {
      dispatch(showLoader());

      let data = {};
      const res = await idbGetIem();
      data.output = localStorage.getItem("output");
      const pageTitle = "";
      const pageDescription = "";
      const pageFavicon = "";
      data.form_content =
          '<!doctype html><html lang="en"><head><title>' +
          pageTitle +
          '</title><meta name="description" content="' +
          pageDescription +
          '"><link href="' +
          pageFavicon +
          '" rel="icon" type="image/x-icon" />' +
          '<meta charset="utf-8">' +
          '<link rel="preconnect" href="https://fonts.googleapis.com">' +
          '<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>' +
          '<link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Slabo+13px&family=Slabo+27px&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap" rel="stylesheet"><style>' +
          editor.getCss() +
          "</style></head><body>" + 
          editor.getHtml() +
          "</body></html>";
      data.form_gjs = JSON.stringify(res);

      if (preview_image) {
        data.form_bg = preview_image;
    }

      // const response = await editPage(data);
      const response = await api.patch(`/v1/${Auth.role}/companies/${site.companyId}/deals/${site.dealId}/campaigns/${site.campaignId}`, data);
      if (response.status == 200) {
          // if (data.preview_image) {
          //     dispatch(domainactions.editProjectPreview(data));
          // }
          // await dispatch(initData());
          dispatch(sendMessage("Page saved"));
      } else {
          dispatch(sendMessage("Error! Please try again"));
      }
      dispatch(hideLoader());
  } catch (e) {
      dispatch(sendMessage(e.message, true));
      dispatch(hideLoader());
  }
};