import React, { Component } from "react";
import { compose } from "react-recompose";
import FbFolderModal from "../@common/modals/fb_folder";
import LeadModal from "../@common/modals/lead";
import { Input, Grid, List, Button, Label, Progress, Tab, Form, Select, Confirm } from "semantic-ui-react";

import {
    BreadCrumbContainer,
    ProfileContainer,
    FBToolContainer,
    MessagesContainer,
    FBFolderFormContainer,
    CompaniesContainer,
    LeadFormContainer,
    LeadNotesContainer,
    LeadsContainer
} from "@containers";
import Loader from "../loader";

import "./index.scss";
import "react-image-crop/dist/ReactCrop.css";
import Prospecter from "./Prospecter";
import moment from "moment";
import { Facebook } from "@services";

class FBPublic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hoverItem: -1,
            tab: "Prospect",
            searchActive: true,

            searchList: [],
            keyword: "",
            locations: [],
            location: "",

            activeFolderId: "",
            folderDelId: "",

            open: false,

            extensionType: "fb"
        };
    }
    componentWillMount() {
        this.props.addBreadCrumb({
            name: "Project",
            path: "/Facebook Tool"
        });
        // this.props.loadLinkedinList();
    }

    handleTabChange = e => {
        this.setState({
            tab: e.target.text
        });
    };

    handleSearchFBPage = async e => {
        const { keyword, location } = this.state;

        const userToken = await Facebook.checkIsLoggedIn();
        if (userToken) {
            await this.props.searchPage(keyword, location, userToken);
        } else {
            this.props.sendMessage("Was not possible to login!", true);
        }
    };

    handleCalcScore = (page, isDate = false, isLinkedin = false) => {
        let days = 0;
        let followersCount = 0;
        let score = 0;
        if (page.extension !== "linkedin" && !isLinkedin) {
            let lastPostTime = "";

            if (isDate) {
                lastPostTime = page.last_post_create_time;
            } else {
                if (!page || !page.posts || page.posts.data.length === 0) {
                    return [0, 0];
                }
                lastPostTime = page.posts.data[0].created_time;
            }

            if (lastPostTime) {
                const currentTime = moment();
                const specificTime = moment(lastPostTime);
                days = currentTime.diff(specificTime, "days");
            } else {
                days = 0;
            }

            followersCount = page.followers_count ? page.followers_count : 0;
            const likeCount = page.rating_count ? page.rating_count : 0;
            score = 5 * days - (followersCount + likeCount) / 100;
        } else if (page.extension === "linkedin") {
            const company = JSON.parse(page.companies);
            if (company && company.lastPostDate && !isNaN(company.lastPostDate)) {
                days = Number(company.lastPostDate);
                followersCount = Number(page.companyFollower);
                score = 5 * days - followersCount / 100;
            }
        } else {
            days = page.last_post_create_time;
            followersCount = page.followers_count ? page.followers_count : 0;
            score = 5 * days - followersCount / 100;
        }

        if (score > 100) {
            return [days, 100];
        }
        if (score <= 0) {
            return [days, 0.1];
        }
        return [days, score];
    };

    handleLoadMore = async () => {
        const { keyword, location } = this.state;
        const { nextUrl } = this.props;
        await this.props.loadMore(nextUrl, keyword, location);
    };

    handleChange = (e, data) => {
        const { name, value } = data;
        this.setState({
            [name]: value
        });
        if (name === "extensionType" && value === "linkedin") {
            const linkedinList = this.props.linkedinList.map(item => ({
                ...item,
                extension: "linkedin"
            }));
            this.props.loadPropList(linkedinList);
        }
        if (name === "extensionType" && value === "fb") {
            this.props.loadPropList([]);
        }
    };

    render() {
        const { keyword, location, extensionType } = this.state;
        const { pageList } = this.props;

        const tabs = [
            {
                menuItem: "Prospectr",
                render: () => <></>
            },
            {
                menuItem: "Folders",
                render: () => <></>
            }
        ];

        return (

            <div className={"Domain facebook-search"}>

<style jsx>{`

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600&display=swap');


.fifteen.wide.column {
    background: #fff;
    padding:0 !important;
   
}

.sixteen.wide.column {
    padding:0 !important;
}

.freshAppLayout > .two.column.grid > .fifteen.wide.column {
    width:100% !important;
    height: 100%;
    margin-top: -20px;
}

.AppSidebar.menu {
    display:none !important;
}

.freshAppHeader {
    display:none !important;
}

.freshAppContainer {
    padding:25px 35px;
}

.Domain.facebook-search .ui.single.line.table tbody tr td {

    background: #fff !important;
    position: relative;
}

.Domain.facebook-search .ui.single.line.table tbody tr {

    background: #fff !important;
    position: relative;
}

@media only screen and (max-width: 1199px) {
    .freshAppContainer {

        padding:25px 0px;
    }

.Domain.facebook-search .four.wide.column.left-menubar.demo {
        position:relative !important;
        width: 100% !important;
        max-width:100% !important;
        left:0 !important;
        height: auto !important;
        min-height: auto !important;
    }

.Domain .right.floated.twelve.wide.column {
        width:100% !important;
        padding:25px 45px;
}

.Domain .scroll-slider {

        height:100% !important;
        position:relative !important;
        max-height:unset !important;

    }

.Domain .demo-step.enrich {
    position:absolute;
    margin-top: -2rem;
}

.Domain .demo-step {
      margin-top: 0;
      margin-bottom: 3rem;
      margin-left: 1rem;
      float: left;
      padding: 15px 25px;
      font-size:30px;
      max-width: 500px;
      line-height: 40px;
      text-align: left;
}

.Domain.facebook-search .left-menubar.demo  button, .Domain.facebook-search .left-menubar.demo  input::placeholder,  .Domain.facebook-search .left-menubar.demo  input, .Domain.facebook-search .left-menubar.demo  label, .Domain.facebook-search .left-menubar.demo  div {

    font-size:30px !important;

}

.Domain.facebook-search .left-menubar.demo label {
    margin-bottom:15px;
}

.Domain.facebook-search .left-menubar .accordion .ui.input input {
    padding:20px !important;
    font-size: 30px !important;
}

 .Domain.facebook-search .left-menubar.demo  .accordion .fetch-data-tooltip {
    font-size:30px;
    text-align:left;
 }

 .pusher .horizontally.padded.grid > div:nth-child(1) {

    padding:0px !important;
    margin:-1px !important;
 }

 .ui.secondary.pointing.menu .item, .ui.secondary.pointing.menu .active.item {
    font-size:20px !important;
 }

 .demo-table tbody .placeholder {

    width: 1190px;
 }

 .Domain.facebook-search .fb-pagetitle {
    font-size:25px !important;
 }

 .Domain.facebook-search .followers div, .Domain.facebook-search div > .reviews {
    font-size:25px !important;
 }

 .Domain.facebook-search .address-line {
    font-size:25px !important;
 }

 .Domain.facebook-search .ui.single.line.table {
    overflow:hidden !important;
    min-height:400px;
 }

 .ui.table thead th {
    font-size:20px !important;
 }

 .Domain-facebook-search .top-space button {
    font-size:20px !important;
 }


 .Domain.facebook-search .ui.single.line.table tr > th:last-child {
    display:none !important;
 }


.Domain.facebook-search .ui.table th.six.wide {
  width: auto;
  min-width: 40% !important; 

}


`}

</style>
                <div>
                    <Loader />
                    <Grid >
                        <Grid.Row style={{paddingTop:"0px"}}>
                            <Grid.Column width={4} className="left-menubar demo">
                                <Grid>
                                    <List>

                                    <div className="demo-step"><span>Step 1 </span> - Enter a Keyphrase & Location</div>
                                        {/* <Grid>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <label>
                                                        {profile.fb_credit_used}/{profile.fb_credit} monthly credits
                                                    </label>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Column width={16} className="small-bar">
                                                <Progress
                                                    percent={(profile.fb_credit_used * 100) / profile.fb_credit}
                                                    color="blue"
                                                    size="large"
                                                ></Progress>
                                            </Grid.Column>
                                        </Grid> */}

                                        <Grid className="tab-bar">
                                            <Tab
                                                onTabChange={this.handleTabChange}
                                                menu={{ secondary: true, pointing: true }}
                                                panes={tabs}
                                            />
                                        </Grid>

                                        <Grid.Row className="mt-4 fb-search-menu">
                                            <Form.Field
                                                className="select-search-type"
                                                control={Select}
                                                options={[
                                                    {
                                                        key: "fb",
                                                        text: "Facebook page search",
                                                        value: "fb"
                                                    },
                                                    {
                                                        key: "linkedin",
                                                        text: "Linkedin extension",
                                                        value: "linkedin"
                                                    }
                                                ]}
                                                placeholder="select option"
                                                search
                                                onChange={this.handleChange}
                                                name="extensionType"
                                                defaultValue={extensionType}
                                                searchInput={{ id: "form-extension-list" }}
                                            />
                                            {extensionType === "fb" ? (
                                                <div className="accordion">
                                                    <Label className="no-domain">
                                                        Find businesses with bad social marketing score.
                                                    </Label>
                                                    <Form onSubmit={this.handleSearchFBPage}>
                                                        <Form.Field required className="mt-3">
                                                            <label>Keyword</label>
                                                            <Input
                                                                placeholder="keyphrase"
                                                                name="keyphrase"
                                                                value={keyword}
                                                                onChange={(e, data) =>
                                                                    this.setState({ keyword: data.value })
                                                                }
                                                            />
                                                        </Form.Field>
                                                        <Form.Field className="mt-3">
                                                            <label>Location(optional)</label>
                                                            <Input
                                                                placeholder="ex: New York"
                                                                name="location"
                                                                value={location}
                                                                onChange={(e, data) =>
                                                                    this.setState({ location: data.value })
                                                                }
                                                            />
                                                        </Form.Field>

                                                        <Button fluid color="blue" className="mt-3 fetch-data-but">
                                                            Fetch
                                                        </Button>
                                                        <div className="fetch-data-tooltip">
                                                            <img src="img/roundarrowwhite.svg" />
                                                            fetch facebook page data by keyword or location
                                                        </div>
                                                    </Form>
                                                </div>
                                            ) : (
                                                <div>
                                                    <Label className="no-domain">
                                                        Scrape linkedin people profiles and enrich them
                                                    </Label>
                                                    <a href="https://www.loom.com/share/2737fa43644444e285657660df4dcf24" target="__blank">
                                                        how it works
                                                    </a>
                                                    <div className="d-flex">
                                                        <Button fluid color="blue" className="mt-3 fetch-data-but">
                                                            Download extension
                                                        </Button>
                                                        <Button
                                                            fluid
                                                            color="blue"
                                                            className="mt-3 fetch-data-but"
                                                            onClick={() => this.props.loadLinkedinList(true)}
                                                        >
                                                            Refresh
                                                        </Button>
                                                    </div>
                                                    <div className="fetch-data-tooltip">
                                                        <img src="img/roundarrow.svg" />
                                                        leave this page opened, all your data will be displayed on the
                                                        right
                                                    </div>
                                                </div>
                                            )}
                                        </Grid.Row>
                                    </List>
                                </Grid>
                            </Grid.Column>

                            <Prospecter
                                pageList={pageList}
                                handleCalcScore={this.handleCalcScore}
                                handleLoadMore={this.handleLoadMore}
                                addPageToFolderHandle={this.addPageToFolderHandle}
                                {...this.props}
                            />
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default compose(
    BreadCrumbContainer,
    ProfileContainer,
    FBToolContainer,
    MessagesContainer,
    FBFolderFormContainer,
    CompaniesContainer,
    LeadFormContainer,
    LeadNotesContainer,
    LeadsContainer
)(FBPublic);
