import { CHANGE_LEAD, LOAD_HOOK } from "./actions";
import { Auth } from "@services";

const initState = {
    formSaved: false,

    form: {
        show: false,
        campaign: "",
        integration: "",

        add_fields: ["email", "name"],
        prompt: "",

        hook: "convertlead",
        title: "Convertlead Integration",
        show: false,
        company_id: ""
    }
};

const convertLeadBotForm = (state = initState, action) => {
    switch (action.type) {
        case LOAD_HOOK: {
            console.log("action--type", action);
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload
                }
            };
        }

        case CHANGE_LEAD: {
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.form
                }
            };
        }
        // case SAVED_LEAD: {
        //     return {
        //         ...state,
        //         formSaved: true,
        //         form: {
        //             ...state.form,
        //             ...action.form,
        //             show: false
        //         }
        //     };
        // }
        default: {
            return state;
        }
    }
};

export default convertLeadBotForm;
