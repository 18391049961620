import React, { Component } from "react";
import { Dropdown, Form, Grid, Input, Label, label, Select } from "semantic-ui-react";
import "./index.scss";
import { api, Auth } from "@services";
import { pipeline } from "stream";
// import styled from "styled-components";

// const DroppableStyles = styled.Select`
//     margin: 5px;
//     padding: 10px;
//     border-radius: 6px;
//     border: dashed 1px grey;
// `;
const customStyle = {
    minWidth: "unset !important",
    borderBottom: "solid 1px #dee0f0 !important;",
    paddingLeft: "10px !important"
};
class DealForm extends Component {
    state = {
        pipelineList: [],
        pipelineOptionList: [],
        currency: this.props.form.pipelines_currency || "USD",
        isPipelneEdit: false
    };
    onChangeName = (event, data) => {
        this.props.changeForm({ name: data.value });
        if (Auth.isCompany && this.props.selectBoxAgencies.length === 1) {
            this.onChangeAgency(event, { value: this.props.selectBoxAgencies[0].value });
        }
    };

    getPipelineList = async () => {
        const res = await api.get(`/v1/pipeline`);
        const companyId = this.props.form.companyId;
        if (Auth.isAgency) {
            if (companyId) {
                const list = res.data
                    .filter(item => item.company_id === companyId)
                    .map(item => ({
                        key: item.id,
                        value: item.id,
                        text: item.name,
                        currency: item.currency
                    }));
                this.setState({
                    pipelineOptionList: list,
                    pipelineList: res.data
                });
            }else{
                this.setState({
                    pipelineList: res.data
                });
            }
        } else {
            const list = res.data
                .map(item => ({
                    key: item.id,
                    value: item.id,
                    text: item.name,
                    currency: item.currency
                }));
            this.setState({
                pipelineOptionList: list,
                pipelineList: res.data
            });
        }
    };

    componentDidMount() {
        const { pipeline_id } = this.props.form;
        if (pipeline_id) {
            this.setState({
                isPipelneEdit: true
            });
        } else {
            this.setState({
                isPipelneEdit: false
            });
        }
        this.props.loadSelectBoxTimezones();
        this.getPipelineList();
    }

    onChangeCompany = (event, data) => {
        this.props.changeForm({ companyId: data.value });
        this.props.loadSelectBoxCompanies("");

        if (data.value) {
            const list = this.state.pipelineList
                .filter(item => item.company_id === data.value)
                .map(item => ({
                    key: item.id,
                    value: item.id,
                    text: item.name,
                    currency: item.currency
                }));
            this.setState({
                pipelineOptionList: list
            });
        }
    };

    onChangeTimezone = (event, data) => {
        this.props.changeForm({ timezone: data.value });
    };

    onChangeAgency = (event, data) => {
        this.props.changeForm({ agency_company_id: data.value });
    };

    onSearchChange = event => {
        this.props.loadSelectBoxCompanies(event.target.value);
    };

    onSearchTimezone = event => {
        this.props.loadSelectBoxTimezones(event.target.value);
    };

    onChangePipeline = (e, { value }) => {
        const pipeline = this.state.pipelineList.find(item => item.id === value);
        this.setState({
            currency: pipeline.currency
        });
        this.props.changeForm({ pipeline_id: value });
    };

    handleValueChange = (e, { value }) => {
        this.props.changeForm({ value: value });
    };

    getCurrencySymbol = currency => {
        if (currency === "USD") {
            return "$";
        }
        if (currency === "EUR") {
            return "€";
        }
        if (currency === "GBP") {
            return "£";
        }
        return "$";
    };

    render() {
        const { name, value, pipeline_id } = this.props.form;
        const { currency, pipelineOptionList, isPipelneEdit } = this.state;
        return (
            <Form size="big" className="dealForm">
                <Form.Field required>
                    <label>Name</label>
                    <Form.Field
                        control={Input}
                        placeholder="Campaign name"
                        value={name || ""}
                        onChange={this.onChangeName}
                    />
                </Form.Field>
                {Auth.isAgency && !this.props.form.id ? (
                    <Form.Field
                        control={Select}
                        options={this.props.selectBoxCompanies || []}
                        label={{ children: "Company", htmlFor: "deal-form-companies-list" }}
                        placeholder="Select company"
                        search
                        defaultValue={this.props.form.companyId || null}
                        onChange={this.onChangeCompany}
                        onSearchChange={this.onSearchChange}
                        searchInput={{ id: "deal-form-companies-list" }}
                    />
                ) : null}
                {Auth.isCompany && this.props.selectBoxAgencies && this.props.selectBoxAgencies.length > 1 ? (
                    <Form.Field
                        control={Select}
                        options={this.props.selectBoxAgencies || []}
                        label={{ children: "Agency", htmlFor: "deal-form-agencies-list" }}
                        placeholder="Select Agency"
                        search
                        defaultValue={this.props.form.agency_company_id || null}
                        onChange={this.onChangeAgency}
                        searchInput={{ id: "deal-form-agencies-list" }}
                    />
                ) : null}
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            {/* <Form.Field required>
                                <label>Value</label>
                                <Form.Field>
                                    <Input type="text" placeholder="Value" action className="w-50">
                                        <Select
                                            options={[
                                                { key: "USD", text: "$", value: "USD" },
                                                { key: "EUR", text: "€", value: "EUR" },
                                                { key: "GBP", text: "£", value: "GBP" }
                                            ]}
                                            defaultValue="USD"
                                            style={customStyle}
                                            className="currency-list"
                                        />
                                        <input />
                                    </Input>
                                </Form.Field>
                            </Form.Field> */}

                            <Form.Field required>
                                <label>Value</label>
                                <Form.Field>
                                    <Input
                                        labelPosition="left"
                                        type="number"
                                        placeholder="Value"
                                        onChange={this.handleValueChange}
                                        value={value}
                                    >
                                        <Label basic className="currency-label">
                                            {this.getCurrencySymbol(currency)}
                                        </Label>
                                        <input />
                                    </Input>
                                </Form.Field>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={11}>
                            {/* <label>Pipeline</label> */}
                            <Form.Field
                                control={Select}
                                options={pipelineOptionList}
                                label="Pipeline"
                                placeholder="Pipeline name"
                                search
                                defaultValue={pipeline_id}
                                onChange={this.onChangePipeline}
                                searchInput={{ id: "deal-form-pipeline-list" }}
                                required
                                disabled={isPipelneEdit}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        );
    }
}

export default DealForm;
