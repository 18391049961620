export const DATABASE = "gjs";
export const DB_VERSION = 1;

export function idbConnect() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(DATABASE, DB_VERSION);

        request.onsuccess = ev => {
            resolve(request.result);
        };

        request.onupgradeneeded = ev => {
            //Is fired when version changes. Create data tables
            const db = ev.target.result;
            db.createObjectStore("projects", {
                autoIncrement: false
            });
            // objectStore.createIndex("title", "title", { unique: false });
            // objectStore.createIndex("author", "author", { unique: false });
        };

        request.onerror = () => {
            reject("Error occured");
        };
        request.onblocked = () => {
            reject("Blocked!");
        };
    });
}

export const idb = idbConnect();

export async function idbGetIem () {
    return new Promise(function(resolve, reject){
        idbConnect().then(function(idb) {
            const store = idb.transaction("projects", "readonly").objectStore("projects");
            const request = store.get(1);
            request.onsuccess = e => {
                resolve(e.target.result);
            };
        });
    });
}

export const idbAddItem = item => {
    idbRemoveItem(1);
    idbConnect().then(function(idb) {
        const store = idb.transaction("projects", "readwrite").objectStore("projects");
        const request = store.put(JSON.parse(item), 1);
        request.onsuccess = e => {
            console.log(`New assets are set`);
        };
    });
};

export const idbRemoveItem = key => {
    idbConnect().then(function(idb) {
        const objectStore = idb.transaction("projects", "readwrite").objectStore("projects");
        const request = objectStore.delete(key);
        request.onsuccess = e => {
            console.log(`Item with key ${key} was removed`);
        };
    
        request.onerror = e => {
            console.log(`Error: ${e}`);
        };
    });

};
