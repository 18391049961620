import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Form, Input, Button, FormField, Radio, TextArea } from "semantic-ui-react";
import CopyText from "react-copy-text";
import { disableAutoComplete } from "../../../../../utils";

const embedTyps = [
    { value: "fullPage", label: "Full page", icon: `ti ti-app-window` },
    { value: "formCode", label: "Form code", icon: `ti ti-app-window` },
    { value: "modal", label: "Modal", icon: `ti ti-window-maximize` }
];
class OptInForm extends Component {
    state = {
        value: "",
        copied: false
    };
    onChange = (field, order, event, data) => {
        console.log({ field, order, event, data });
        const fieldData = {
            [data.name]: data.hasOwnProperty("checked") ? data.checked : data.value
        };

        if (order !== undefined) {
            fieldData[order] = order;
        }

        this.props.changeOptinForm(field, fieldData);
    };

    onEmbedTypeChange = data => {
        this.props.changeOptinForm("embed_type", data.value);
        this.props.saveOptinFormEmbedType({
            ...this.props.form,
            embed_type: data.value
        });
    };

    onCopy = formLink => {
        this.setState({
            copied: true,
            value: formLink
        });
        setTimeout(() => {
            this.setState({
                copied: false,
                value: ""
            });
        }, 400);
    };

    componentDidMount() {
        disableAutoComplete();
    }

    gotoOptinBuilder = () => {
        this.props.gotoOptinBuilder(this.props.form);
    };

    render() {
        const { form } = this.props;

        const embedType = form.embed_type;
        let formLink = "";

        if (embedType === "fullPage") {
            formLink = this.props.optinFormLink;
        } else if (embedType === "formCode") {
            formLink = form.form_content;
        } else {
            formLink = `<button id="new-lead-form-btn">Open Lead Modal</button><script src="https://api.convertlead.com/lead.js?id=${form.uuid}"></script>`;
        }

        return (
            <div>
                <Form size="big">
                    <FormField>
                        <label>Embed type</label>
                    </FormField>
                    <div style={{ display: "flex" }}>
                        {embedTyps.map(et => (
                            <FormField key={et.value}>
                                <i class={et.icon} style={{ fontSize: "42px" }}></i>
                                <Radio
                                    label={et.label}
                                    name="embed_type"
                                    value={et.value}
                                    checked={form.embed_type === et.value}
                                    onChange={(e, data) => this.onEmbedTypeChange(data)}
                                />
                            </FormField>
                        ))}
                        <img src="/img/optin-logo.png" />
                    </div>

                    <Form.Field>
                        <label>Form Link</label>
                        <CopyText text={this.state.value} onCopied={this.onCopied} />
                        {/* <Input
                            action={{
                                color: "teal",
                                labelPosition: "right",
                                icon: "copy",
                                content: `${this.state.copied ? "Copied" : "Copy"}`,
                                onClick: this.onCopy
                            }}
                            defaultValue={formLink}
                        /> */}
                        <TextArea value={formLink} />
                        <Button
                            color="tour"
                            onClick={() => this.onCopy(formLink)}
                            style={{
                                position: "absolute",
                                bottom: 0,
                                right: 0
                            }}
                        >
                            <i className="ti ti-copy"></i>
                        </Button>
                    </Form.Field>

                    <Form.Field>
                        <label>Form preview</label>
                        {form.form_bg ? (
                            <div
                                style={{
                                    backgroundImage: `url(${form.form_bg})`,
                                    width: "100%",
                                    height: "250px",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Button color="teal" onClick={() => this.gotoOptinBuilder()}>
                                    Form editor
                                </Button>
                            </div>
                        ) : (
                            <div
                                style={{
                                    backgroundColor: "grey",
                                    width: "100%",
                                    height: "250px",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Button color="teal" onClick={() => this.gotoOptinBuilder()}>
                                    Form editor
                                </Button>
                            </div>
                        )}
                    </Form.Field>
                </Form>
            </div>
        );
    }
}

export default OptInForm;
