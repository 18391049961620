import React, { Component } from "react";
import { compose } from "react-recompose";
import { Link } from "react-router-dom";
import { MenuContainer, AuthContainer, ProfileContainer } from "@containers";
import PropTypes from "prop-types";

import { Menu } from "semantic-ui-react";
import "./index.scss";
import { Auth } from "@services";
import { AvatarImage } from "components/@common/image";

class AppSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: localStorage.getItem("active-item")
        };

        this.onMenuItemClick = this.onMenuItemClick.bind(this);
    }

    logout = () => {
        this.props.logout();
        this.onItemClick();
    };

    componentWillMount() {
        this.props.getUserSideBarMenu(Auth.role);
    }
    componentDidMount() {
        this.setState({ activeIndex: Number(localStorage.getItem("active-item")) });
    }

    onItemClick = () => {
        this.props.onClickMenuItem && this.props.onClickMenuItem();
    };

    onMenuItemClick = index => {
        localStorage.setItem("active-item", index);
        this.setState({ activeIndex: index });
        this.props.onClickMenuItem && this.props.onClickMenuItem();
    };

    render() {
        const { visibleMenus, profile } = this.props;

        const trialCompanySidebar = [
            {
                icon: "ti ti-apps",
                name: "Campaigns"
            },
            {
                icon: "ti ti-book",
                name: "Leads"
            },
            {
                icon: "ti ti-user-search",
                name: "Agents"
            },
            {
                icon: "ti ti-chart-line",
                name: "Stats"
            }
        ];

        return (
            <Menu className="AppSidebar" fixed="left" vertical={true} icon={true}>
                <Menu.Item className="app-logo" onClick={this.onItemClick}>
                    <img src="/img/logo.png" className="top logo" alt="jsx-a11y/alt-text" />
                </Menu.Item>
                {Auth.isTrial || Auth.isSocialTrial ? (
                    <div className="sidebar-top-menu">
                        <a href="https://convertlead.com" target="_blank" className="upgrade-but-menu">
                            <img src="img/upgrademenu.svg" alt="jsx-a11y/alt-text" />
                        </a>
                        <p className="sidebar-title">Menu</p>
                        {trialCompanySidebar.map((menu, i) => (
                            <Link to="/pages" key={i} index={i}>
                                <Menu.Item>
                                    <i className={this.state.activeIndex === i ? menu.icon + " active" : menu.icon} />
                                    <div>{menu.name}</div>
                                </Menu.Item>
                            </Link>
                        ))}
                    </div>
                ) : (
                    <>
                        <p className="sidebar-title">Menu</p>
                        {visibleMenus.map((menu, i) => (
                            <Link to={menu.path} key={i} index={i} onClick={this.onMenuItemClick.bind(this, i)}>
                                <Menu.Item>
                                    <i className={this.state.activeIndex === i ? menu.icon + " active" : menu.icon} />
                                    <div>{menu.name}</div>
                                </Menu.Item>
                            </Link>
                        ))}
                    </>
                )}

                <div className="sidebar-bootom">
                    <p className="sidebar-title">Links</p>
                    <a
                        href="http://support.convertlead.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={this.onItemClick}
                    >
                        <i className="ti ti-message-dots" />
                    </a>
                    <Menu.Item onClick={this.logout}>
                        <i className="ti ti-plug-x" />
                    </Menu.Item>
                    <Link to="/profile" onClick={this.onItemClick}>
                        <AvatarImage src={profile.avatar_path} sidebar-avatar rounded size="tiny" />
                    </Link>
                </div>
            </Menu>
        );
    }
}

AppSidebar.propTypes = {
    visibleMenus: PropTypes.array.isRequired
};

export default compose(ProfileContainer, MenuContainer, AuthContainer)(AppSidebar);
