import React, { Component } from "react"
import { compose } from "react-recompose"
import FbFolderModal from "../@common/modals/fb_folder"
import LeadModal from "../@common/modals/lead"
import {
  Input,
  Grid,
  List,
  Button,
  Label,
  Progress,
  Tab,
  Form,
  Select,
  Confirm,
} from "semantic-ui-react"

import {
  BreadCrumbContainer,
  ProfileContainer,
  FBToolContainer,
  MessagesContainer,
  FBFolderFormContainer,
  CompaniesContainer,
  LeadFormContainer,
  LeadNotesContainer,
  LeadsContainer,
} from "@containers"
import Loader from "../loader"

import "./index.scss"
import "react-image-crop/dist/ReactCrop.css"
import FacebookFolder from "./Folder"
import Prospecter from "./Prospecter"
import moment from "moment"
import { Facebook } from "@services"

class FacebookTool extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hoverItem: -1,
      tab: "Prospect",
      searchActive: true,

      searchList: [],
      keyword: "",
      locations: [],
      location: "",

      activeFolderId: "",
      folderDelId: "",

      open: false,

      extensionType: "fb",
    }
  }
  componentWillMount() {
    this.props.addBreadCrumb({
      name: "Project",
      path: "/Facebook Tool",
    })
    this.props.getFolders()
    this.props.loadLinkedinList()
  }

  handleTabChange = (e) => {
    this.setState({
      tab: e.target.text,
    })
  }

  handleSearchFBPage = async (e) => {
    const { keyword, location } = this.state

    const userToken = await Facebook.checkIsLoggedIn()
    if (userToken) {
      await this.props.searchPage(keyword, location, userToken)
    } else {
      this.props.sendMessage("Was not possible to login!", true)
    }
  }

  handleCalcScore = (page, isDate = false, isLinkedin = false) => {
    let days = 0
    let followersCount = 0
    let score = 0
    if (page.extension !== "linkedin" && !isLinkedin) {
      let lastPostTime = ""

      if (isDate) {
        lastPostTime = page.last_post_create_time
      } else {
        if (!page || !page.posts || page.posts.data.length === 0) {
          return [0, 0]
        }
        lastPostTime = page.posts.data[0].created_time
      }

      if(lastPostTime){
        const currentTime = moment()
        const specificTime = moment(lastPostTime)
        days = currentTime.diff(specificTime, "days")
      }else{
        days = 0;
      }
      
      followersCount = page.followers_count ? page.followers_count : 0
      const likeCount = page.rating_count ? page.rating_count : 0
      score = 5 * days - (followersCount + likeCount) / 100
    } else if (page.extension === "linkedin") {
      const company = JSON.parse(page.companies)
      if (company && company.lastPostDate && !isNaN(company.lastPostDate)) {
        days = Number(company.lastPostDate)
        followersCount = Number(page.companyFollower)
        score = 5 * days - followersCount / 100
      }else{
        days = "100+ ";
        score = 100;
      }
    } else {
      if(!page.last_post_create_time){
        days = "100+ ";
        score = 100;
      } else {
        days = page.last_post_create_time
        followersCount = page.followers_count ? page.followers_count : 0
        score = 5 * days - followersCount / 100
      }
    }

    if (score > 100) {
      return [days, 100]
    }
    if (score <= 0) {
      return [days, 0.1]
    }
    return [days, score]
  }

  handleLoadMore = async () => {
    const { keyword, location } = this.state
    const { nextUrl } = this.props
    await this.props.loadMore(nextUrl, keyword, location)
  }

  openConfirmModal = (open = true, folderDelId = "") => {
    this.setState({ open, folderDelId })
  }

  onDeleteConfirm = () => {
    const { folderDelId } = this.state
    this.setState({ open: false })
    this.props.deleteFolder(folderDelId)
  }

  addPageToFolderHandle = async (folderId, pages) => {
    await this.props.addPageToFolder(folderId, pages)
    await this.props.getUserProfile()
  }

  handleChange = (e, data) => {
    const { name, value } = data
    this.setState({
      [name]: value,
    })
    if (name === "extensionType" && value === "linkedin") {
      const linkedinList = this.props.linkedinList.map((item) => ({
        ...item,
        extension: "linkedin",
      }))
      this.props.loadPropList(linkedinList)
    }
    if (name === "extensionType" && value === "fb") {
      this.props.loadPropList([])
    }
  }

  render() {
    const {
      tab,
      searchActive,
      keyword,
      location,
      activeFolderId,
      extensionType,
    } = this.state
    const { pageList, folderList, profile, linkedinList } = this.props
    const folderOptionList = folderList.map((folder) => ({
      key: folder.id,
      value: folder.id,
      text: folder.name,
    }))

    const folder = folderList.find((f) => f.id === activeFolderId)

    const templateCategorylist = folderList.map((folder, index) => {
      return (
        <List.Item
          key={folder.id}
          className={`clickable folder ${
            activeFolderId === folder.id ? "selected" : ""
          }`}
          // onClick={() => this.onSelectItem("templates", category.key)}
          onMouseEnter={() => {
            this.setState({
              hoverItem: folder.id,
            })
          }}
          onMouseLeave={() => {
            this.setState({
              hoverItem: -1,
            })
          }}
        >
          <List.Content>
            <div
              onClick={() => this.setState({ activeFolderId: folder.id })}
              className="select-folder-but"
            >
              <List.Icon color="blue" name="" className="ti ti-folder" />
              <span>{folder.name}</span>
            </div>

            {this.state.hoverItem === folder.id && (
              <div className="d-flex folder-edit">
                <Button
                  className="tiny-btn icon"
                  onClick={this.props.showFolderModal.bind(this, folder)}
                  size="tiny"
                >
                  <i className="flaticon stroke pencil-1"></i>
                </Button>
                <Button
                  className="tiny-btn icon"
                  onClick={this.openConfirmModal.bind(this, true, folder.id)}
                  size="tiny"
                >
                  <i className="flaticon stroke trash-1"></i>
                </Button>
              </div>
            )}
          </List.Content>
        </List.Item>
      )
    })

    const tabs = [
      {
        menuItem: "Prospectr",
        render: () => <></>,
      },
      {
        menuItem: "Folders",
        render: () => <></>,
      },
    ]

    return (
      <div className={"Domain facebook-search"}>
        <FbFolderModal />
        <LeadModal />
        <Confirm
          open={this.state.open}
          onCancel={this.openConfirmModal.bind(this, false)}
          onConfirm={this.onDeleteConfirm}
          className="confirm-modal"
        />
        <div>
          <Loader />
          <Grid>
            <Grid.Row>
              <Grid.Column width={4} className="left-menubar">
                <Grid>
                  <List>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <label>
                            {profile.fb_credit_used}/{profile.fb_credit} monthly
                            credits
                          </label>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Column width={16} className="small-bar">
                        <Progress
                          percent={
                            (profile.fb_credit_used * 100) / profile.fb_credit
                          }
                          color="blue"
                          size="large"
                        ></Progress>
                      </Grid.Column>
                    </Grid>

                    <Grid className="tab-bar">
                      <Tab
                        onTabChange={this.handleTabChange}
                        menu={{ secondary: true, pointing: true }}
                        panes={tabs}
                      />
                    </Grid>

                    {tab === "Folders" ? (
                      <>
                        <Grid className="text-start">
                          <Grid.Row className="d-flex justify-between align-items-center">
                            Manage folders
                            <Button
                              color="teal"
                              className="circle-btn"
                              onClick={this.props.showFolderModal.bind(this, {
                                id: "",
                                name: "",
                                description: "",
                                default_msg: "",
                                company: "",
                              })}
                              title="New Company"
                            >
                              <i className="flaticon stroke plus-1  icon"></i>
                            </Button>
                          </Grid.Row>
                          <Grid.Row className="justify-between">
                            <label htmlFor="" className="ui label no-domain">
                              Add leads to folders to enrich them and keep them
                              organized by niche or interest.
                            </label>
                          </Grid.Row>
                        </Grid>

                        <List.Item className="folders">
                          <List.Content>
                            <List.List>{templateCategorylist}</List.List>
                          </List.Content>
                        </List.Item>
                      </>
                    ) : (
                      <Grid.Row className="mt-4 fb-search-menu">
                        <Form.Field
                          className="select-search-type"
                          control={Select}
                          options={[
                            {
                              key: "fb",
                              text: "Facebook page search",
                              value: "fb",
                            },
                             {
                              key: "linkedin",
                              text: "Linkedin extension",
                              value: "linkedin",
                             },
                          ]}
                          placeholder="select option"
                          search
                          onChange={this.handleChange}
                          name="extensionType"
                          defaultValue={extensionType}
                          searchInput={{ id: "form-extension-list" }}
                        />
                        {extensionType === "fb" ? (
                          <div className="accordion">
                            <Label className="no-domain">
                              Find businesses with bad social marketing score.
                            </Label>
                            <a href="https://www.youtube.com/watch?v=0Ru-GCDrLG8" class="linkedin-help" target="__blank">
                              <i class="ti ti-brand-youtube"></i> How it works
                            </a>
                            <Form onSubmit={this.handleSearchFBPage}>
                              <Form.Field required className="mt-3">
                                <label>Keyword</label>
                                <Input
                                  placeholder="keyphrase"
                                  name="keyphrase"
                                  value={keyword}
                                  onChange={(e, data) =>
                                    this.setState({ keyword: data.value })
                                  }
                                />
                              </Form.Field>
                              <Form.Field className="mt-3">
                                <label>Location(optional)</label>
                                <Input
                                  placeholder="ex: New York"
                                  name="location"
                                  value={location}
                                  onChange={(e, data) =>
                                    this.setState({ location: data.value })
                                  }
                                />
                              </Form.Field>

                              <Button
                                fluid
                                color="blue"
                                className="mt-3 fetch-data-but"
                              >
                                Fetch
                              </Button>
                              <div className="fetch-data-tooltip">
                                <img src="img/roundarrow.svg" />
                                fetch facebook page data by keyword or location
                              </div>
                            </Form>
                          </div>
                        ) : (
                          <div>
                            <Label className="no-domain">
                              Gather data from linkedin people profiles and enrich them with email and contact information.
                            </Label>
                            <a href="https://www.youtube.com/watch?v=SddEEXRK-LY" class="linkedin-help" target="__blank">
                              <i class="ti ti-brand-youtube"></i> How it works
                            </a>
                            <a href="https://convertleadpages.s3.amazonaws.com/ProspectrV1.2.zip" class="linkedin-help" target="__blank">
                             <i class="ti ti-download"></i>  Download extension
                            </a>
                            <div className="d-flex">
                              <Button
                                fluid
                                color="blue"
                                className="mt-3 fetch-data-but"
                                onClick={() =>
                                  this.props.loadLinkedinList(true)
                                }
                              >
                                Refresh
                              </Button>
                            </div>
                            <div className="fetch-data-tooltip">
                              <img src="img/roundarrow.svg" />
                              Pull data from your chrome extension (current unsaved leads will be removed)
                            </div>
                          </div>
                        )}

                        {/* <Accordion
                          fluid
                          styled
                          className="text-left"
                          style={{ boxShadow: "none" }}
                        >
                          <Accordion.Title
                            active={searchActive}
                            index={0}
                            onClick={() =>
                              this.setState({ searchActive: !searchActive })
                            }
                          >
                            Facebook page search
                            <Icon name="dropdown" />
                          </Accordion.Title>
                          <Accordion.Content active={searchActive}>
                            
                          </Accordion.Content>
                        </Accordion> */}
                      </Grid.Row>
                    )}
                  </List>
                </Grid>
              </Grid.Column>
              {tab === "Folders" ? (
                <FacebookFolder
                  pageList={pageList}
                  handleCalcScore={this.handleCalcScore}
                  folderOptionList={folderOptionList}
                  folder={folder}
                  activeFolderId={activeFolderId}
                  {...this.props}
                />
              ) : (
                <Prospecter
                  pageList={pageList}
                  handleCalcScore={this.handleCalcScore}
                  handleLoadMore={this.handleLoadMore}
                  folderOptionList={folderOptionList}
                  addPageToFolderHandle={this.addPageToFolderHandle}
                  {...this.props}
                />
              )}
            </Grid.Row>
          </Grid>
        </div>
      </div>
    )
  }
}

export default compose(
  BreadCrumbContainer,
  ProfileContainer,
  FBToolContainer,
  MessagesContainer,
  FBFolderFormContainer,
  CompaniesContainer,
  LeadFormContainer,
  LeadNotesContainer,
  LeadsContainer
)(FacebookTool)
