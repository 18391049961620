import React, { Component } from "react";
import {
    Form,
    Input,
    // TextArea,
    // Select,
    Grid,
    Modal,
    Button
} from "semantic-ui-react";
import JoditEditor from "jodit-react";
import "./index.scss";

const editor = React.createRef();
const config = {
    readonly: false,
    extraButtons: ["CustomTags"]
};

class SendEmailModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: "",
            content: "",
        };
    }
    componentWillMount() {
        this.setState({
            subject: "",
            content: ""
        });
    }

    // componentDidUpdate() {
    //     this.setState({
    //         subject: "",
    //         content: ""
    //     });
    // }

    onChange = (event, data) => {
        this.setState({ [data.name]: data.value });
    };
    onChangeEmailContent = content => {
        // const { object } = this.props.form;
        // this.props.changeForm({ object: { ...(object || {}), content: content } });
        this.setState({ content });
    };

    onSubmit = () => {
        const { sendEmailAddress, tagetPageId } = this.props;
        const { subject, content } = this.state;
        this.props.onSubimit(sendEmailAddress, subject, content, tagetPageId);
        this.handleCloseModal();
    };
    handleCloseModal = () => {
        this.props.onCloseSendEmailModal();
        this.setState({
            subject: "",
            content: ""
        });
    };
    render() {
        const { isEmailModal } = this.props;
        const { subject, content } = this.state;
        return (
            <Modal
                className="Pipeline modal"
                open={isEmailModal}
                centered={false}
                size={"tiny"}
                onClose={() => this.handleCloseModal()}
            >
                <Modal.Header>Send Email</Modal.Header>
                <Form size="big" className="leadForm" onSubmit={() => this.onSubmit()}>
                    <Grid columns={1} relaxed="very" stackable>
                        <Grid.Column>
                            <Form.Field required>
                                <label>Subject</label>
                                <Input
                                    type="text"
                                    placeholder="Subject"
                                    value={subject || ""}
                                    onChange={this.onChange}
                                    name="subject"
                                />
                            </Form.Field>

                            <Form.Field required>
                                <label>Content</label>

                                <Form.Field required>
                                    <JoditEditor
                                        ref={editor}
                                        value={content || ""}
                                        config={config}
                                        tabIndex={1}
                                        onChange={this.onChangeEmailContent}
                                        name="content"
                                    />
                                </Form.Field>
                            </Form.Field>
                        </Grid.Column>
                    </Grid>
                    <Modal.Actions className="text-right">
                        <Button color="black" onClick={() => this.handleCloseModal()}>
                            Cancel
                        </Button>
                        <Button color="teal" labelPosition="right" type="submit">
                            Send
                        </Button>
                    </Modal.Actions>
                </Form>
            </Modal>
        );
    }
}

export default SendEmailModal;
