import * as actions from "./actions";
import { sendMessage } from "../messages/thunks";
import { hideLoader, showLoader } from "../loader/actions";
import { loadAllOnlyLeads } from "../leads/actions";
import * as api from "./api";
import { Auth } from "@services";

export const loadPipelines = companyId => async (dispatch, getState) => {
    try {
        const response = await api.fetchPipelines({
            companyId
        });
        const { data } = response;
        dispatch(actions.loadPipelines(data));

        // active pipeline
        const { activePipeline } = getState().pipeline;
        const { listType } = getState().leads;
        if (activePipeline) {
            const newActivePipe = data.find(item => item.id === activePipeline.id);
            dispatch(actions.changeActivePipeline(newActivePipe));
        } else {
            if (data.length > 0 && listType === "dragdrop") {
                dispatch(actions.changeActivePipeline(data[0]));
            }
        }
    } catch (e) {
        dispatch(sendMessage(e.message, true));
    }
};

export const createUpdatePipeline = pipeline => async dispatch => {
    try {
        if (!pipeline.name) {
            dispatch(sendMessage("Missing required Name", true));
            return;
        }
        if (!pipeline.currency) {
            dispatch(sendMessage("Missing required currency", true));
            return;
        }
        if (!pipeline.stageList || pipeline.stageList.length === 0) {
            dispatch(sendMessage("Please add at least one status in the CUSTOM STATUSES", true));
            return;
        }

        if (pipeline.id) {
            await api.updatePipeline(pipeline);
            dispatch(sendMessage("Pipeline created successfully!"));
        } else {
            if (Auth.isAgency && !pipeline.companyId) {
                dispatch(sendMessage("Missing required companyId", true));
                return;
            }
            await api.createPipeline(pipeline);
            dispatch(sendMessage("Pipeline updated successfully!"));
        }
        dispatch(actions.pipelineForm(false));
        await dispatch(loadPipelines(""));
    } catch (e) {
        dispatch(sendMessage(e.message, true));
    }
};

export const changeDragAction = (leads, type) => async (dispatch, getState) => {
    try {
        dispatch(showLoader());

        const { query } = getState().leads;

        const pipelineId = query.filters.pipeline_id;
        const newLeads = await api.changeDragAction(leads, pipelineId);
        if (type === "group") {
            await dispatch(loadPipelines());
        }
        dispatch(loadAllOnlyLeads(newLeads.data));
        dispatch(hideLoader());

        // return true;
    } catch (error) {
        dispatch(sendMessage(error.message, true));
    }
};

export const createUpdateAutoStage = param => async (dispatch, getState) => {
    try {
        await api.createUpdateAutoStage(param);
        await dispatch(loadPipelines());
    } catch (error) {
        dispatch(sendMessage(error.message, true));
    }
};

export const deleteStage = stageId => async dispatch => {
    try {
        await api.deleteStage(stageId);
        await dispatch(loadPipelines());
    } catch (error) {
        dispatch(sendMessage(error.message, true));
    }
};

export const deletePipeline = pipelineId => async dispatch => {
    try {
        await api.deletePipeline(pipelineId);
        dispatch(sendMessage("Pipeline was deleted successfully!", false));
        window.location.reload();
    } catch (error) {
        dispatch(sendMessage(error.message, true));
    }
};
