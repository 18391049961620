import React, { Component } from "react";
import { Form, Input, TextArea, Select, Grid, Label, Button, GridColumn, FormField } from "semantic-ui-react";
import "./index.scss";

const fieldOptions = [
    { key: "name", value: "name", text: "name" },
    { key: "email", value: "email", text: "email" },
    { key: "business_name", value: "business_name", text: "business_name" },
    { key: "website", value: "website", text: "website" },
    { key: "address", value: "address", text: "address" },
    { key: "phone_number", value: "phone_number", text: "phone_number" },
    { key: "meta", value: "meta", text: "meta" }
];

class ConvertLeadBotForm extends Component {
    state = {
        pipelineList: [],
        pipeline: null,

        value: 0,

        newField: false,

        fieldCount: 0
    };

    componentWillMount() {
        // this.props.getCompanyDeals();

        if (this.props.form.hook === "convertlead") {
            if (this.props.form.company_id) {
                this.props.filterDealsByCompany(this.props.form.company_id);
            }
            if (this.props.form.campaign) {
                this.props.filterDealsByDealId(this.props.form.campaign);
            }
            this.props.getCompanyDeals();
        }

        // else {
        //     this.props.filterDealsByDealId("");
        // }
    }

    onChangeDeal = (event, data) => {
        console.log("data.value->> campaing id", data.value);
        this.props.filterDealsByDealId(data.value);
        // this.props.filterDealCampaignsById("");
        console.log(":deal value", data.value);
        this.props.changeForm({ campaign: data.value });
    };

    onChangeCampaign = (event, data) => {
        this.props.changeForm({ integration: data.value });
    };

    componentDidMount() {
        // disableAutoComplete();
        // this.getPipelineList();
    }

    handleChangeForm = (e, data) => {
        this.props.changeForm({ prompt: data.value });
    };

    handleCoreChangeForm = e => {
        const { name, value } = e.target;
        this.props.changeForm({ [name]: value });
    };

    addField = isTrue => {
        this.setState({
            newField: isTrue
        });
    };

    updateFieldList = (e, data) => {
        const { value } = data;
        const { add_fields } = this.props.form;
        const prompt = `Start the conversation by asking user ${[...add_fields, value].join(",")}.`;
        this.props.changeForm({
            add_fields: [...add_fields, value],
            prompt
        });
        this.setState({
            newField: false
        });
    };

    updateWebhookFieldList = (e, data) => {
        const { value } = data;
        const { add_fields } = this.props.form;
        const add_field_list = add_fields.map(fi => fi.value);
        const prompt = `Start the conversation by asking user ${[...add_field_list, value].join(",")}.`;
        this.props.changeForm({
            add_fields: [...add_fields, { value: value, text: value }],
            prompt
        });
        this.setState({
            newField: false
        });
    };

    changeFieldName = (value, data) => {
        const { add_fields } = this.props.form;
        const new_field_list = add_fields.map(field => {
            if (field.value === value) {
                return {
                    ...field,
                    text: data.value
                };
            } else {
                return field;
            }
        });
        this.props.changeForm({
            add_fields: new_field_list
        });
    };

    removeField = value => {
        const { add_fields } = this.props.form;
        const new_field_list = add_fields.filter(field => field !== value);
        const prompt = `Start the conversation by asking user ${new_field_list.join(",")}.`;
        this.props.changeForm({
            add_fields: new_field_list,
            prompt
        });
    };

    removeWebhookField = value => {
        const { add_fields } = this.props.form;
        const add_field_list = add_fields.filter(fi => fi.value !== value);
        const new_field_values = add_field_list.map(fi => fi.value);
        const prompt = `Start the conversation by asking user ${new_field_values.join(",")}.`;
        this.props.changeForm({
            add_fields: add_field_list,
            prompt
        });
    };

    render() {
        const { campaign, integration, add_fields, prompt, hook, webhook_url } = this.props.form;
        const { newField } = this.state;

        let newFieldOptions = [];
        if (hook === "convertlead") {
            newFieldOptions = fieldOptions.filter(field => !add_fields.includes(field.value));
        } else {
            const add_field_list = add_fields.map(fi => fi.value);
            newFieldOptions = fieldOptions.filter(field => !add_field_list.includes(field.value));
        }

        return (
            <Form size="big" className="leadForm">
                <Grid relaxed="very" stackable>
                    {hook === "convertlead" ? (
                        <Grid.Column>
                            <Form.Field
                                required
                                control={Select}
                                options={this.props.selectBoxDeals || []}
                                label={{ children: "Where do we send the data ?", htmlFor: "deals-list" }}
                                placeholder="select campaign"
                                search
                                defaultValue={campaign || null}
                                name="campaign"
                                onChange={this.onChangeDeal}
                                searchInput={{ id: "deals-list" }}
                            />
                            <Form.Field
                                required
                                control={Select}
                                options={this.props.selectBoxDealCampaigns || []}
                                label={{ children: "Assign lead to integration", htmlFor: "campaigns-list" }}
                                placeholder="Select integration"
                                search
                                defaultValue={integration || null}
                                name="integration"
                                onChange={this.onChangeCampaign}
                                searchInput={{ id: "campaigns-list" }}
                            />

                            <Grid.Row columns={2}>
                                <GridColumn>
                                    <p className="mb-0 modal-subhead">Field mapping</p>
                                    <small>
                                        Tell our assistant what data needs to be collected and to what fields should
                                        each be sent inside convertlead (email & name are required){" "}
                                    </small>
                                    {add_fields.map(field => (
                                        <FormField key={field}>
                                            <Input
                                                action={
                                                    !["email", "name"].includes(field) && (
                                                        <button
                                                            className="remove-field-but"
                                                            onClick={() => this.removeField(field)}
                                                        >
                                                            <i className="icon ti ti-circle-x" />
                                                        </button>
                                                    )
                                                }
                                                defaultValue={field}
                                                disabled
                                            />
                                        </FormField>
                                    ))}

                                    {newField && (
                                        <Form.Field
                                            required
                                            control={Select}
                                            options={newFieldOptions}
                                            placeholder="Select One"
                                            search
                                            onChange={this.updateFieldList}
                                        />
                                    )}

                                    <FormField>
                                        {" "}
                                        <Button onClick={() => this.addField(true)}>Add field</Button>
                                    </FormField>
                                </GridColumn>
                            </Grid.Row>
                            <FormField>
                                <p className="mb-0 modal-subhead">Collection instructions</p>
                                <small>Explain your agent how to request the information from your visitors.</small>
                                <TextArea
                                    style={{ marginTop: "20px" }}
                                    placeholder="Tell us more"
                                    value={prompt || ""}
                                    onChange={this.handleChangeForm}
                                    name="prompt"
                                />
                            </FormField>
                        </Grid.Column>
                    ) : (
                        <Grid.Column className="webhook-integration">
                            <FormField>
                                <label>Webhook url</label>
                                <input
                                    onChange={this.handleCoreChangeForm}
                                    name="webhook_url"
                                    value={webhook_url}
                                    placeholder="http://yourapp.com/781231279"
                                />
                            </FormField>
                            <FormField>
                                <p className="mb-0 modal-subhead">Webhook fields</p>
                                {add_fields.map(field => (
                                    <Grid.Row key={field.value} columns={2}>
                                        <GridColumn>
                                            <Input defaultValue={field.value} disabled />
                                        </GridColumn>
                                        <GridColumn className="d-flex">
                                            <Input
                                                value={field.text}
                                                onChange={(e, data) => this.changeFieldName(field.value, data)}
                                            />
                                            {!["name", "email"].includes(field.value) && (
                                                <button
                                                    className="remove-field-but"
                                                    onClick={() => this.removeWebhookField(field.value)}
                                                >
                                                    <i className="icon ti ti-circle-x" />
                                                </button>
                                            )}
                                        </GridColumn>
                                    </Grid.Row>
                                ))}
                            </FormField>
                            <FormField>
                                {newField && (
                                    <Form.Field
                                        required
                                        control={Select}
                                        options={newFieldOptions}
                                        placeholder="Select One"
                                        search
                                        onChange={this.updateWebhookFieldList}
                                    />
                                )}

                                <Button onClick={() => this.addField(true)}>Add field</Button>
                            </FormField>

                            <FormField>
                                <p className="mb-0 modal-subhead">Collection instructions</p>
                                <small>Explain your agent how to request the information from your visitors.</small>
                                <TextArea
                                    style={{ marginTop: "20px" }}
                                    placeholder="Tell us more"
                                    value={prompt || ""}
                                    onChange={this.handleChangeForm}
                                    name="prompt"
                                />
                            </FormField>
                        </Grid.Column>
                    )}
                </Grid>
            </Form>
        );
    }
}

export default ConvertLeadBotForm;
