import { api } from "@services";

export const loadAssistantList = () => api.get(`/v1/assistant`);
export const submitAssistantForm = (id, formData) => api.put(`/v1/assistant/${id}`, { formData });
export const deleteAssistantApi = id => api.delete(`/v1/assistant/${id}`);

export const deleteThreadApi = id => api.delete(`/v1/delete-thread/${id}`);

export const manageKnowledgeApi = knowledge => api.post(`/v1/assistant-knowledge`, knowledge);
export const fetchThreadApi = (threadId, uuid) => api.get(`/v1/thread/${threadId}/${uuid}`);

export const deleteIntegration = ({ assist_id, type }) => api.delete(`/v1/assistant-integration/${assist_id}/${type}`);
