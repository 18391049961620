export const LOAD_ASSISTENT_LIST = "LOAD_ASSISTENT_LIST";
export const SET_ACTIVE_ASSISTENT = "SET_ACTIVE_ASSISTENT";
export const SET_THREAD_MSG = "SET_THREAD_MSG";

export const loadAssistantListAction = payload => ({
    type: LOAD_ASSISTENT_LIST,
    payload
});
export const setActiveAssistent = payload => ({
    type: SET_ACTIVE_ASSISTENT,
    payload
});

export const setThreadMsg = payload => ({
    type: SET_THREAD_MSG,
    payload
});