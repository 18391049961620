import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DraggableElement from "./DraggableElement";
import { api, Auth } from "@services";
import { useSelector } from "react-redux";
import { DATE_FORMAT } from "@constants";
import moment from "moment";
import styled from "styled-components";

const Container = styled.div`
    min-height: 100vh;
    min-width: 100vw;
    display: inline-flex;
`;

const DATA = [
    {
        id: "af1",
        label: "Incoming leads",
        items: [
            { id: "af2", label: "Item 1" },
            { id: "af3", label: "Item 2" }
        ],
        tint: 1
    },
    {
        id: "af4",
        label: "Closing leads",
        items: [
            { id: "af5", label: "Item 1" },
            { id: "af6", label: "Item 2" }
        ],
        tint: 2
    },
    {
        id: "af7",
        label: "On hold",
        items: [
            { id: "af8", label: "Item 1" },
            { id: "af9", label: "Item 2" },
            { id: "af10", label: "Item 2" },
            { id: "af11", label: "Item 2" },
            { id: "af12", label: "Item 2" }
        ],
        tint: 3
    }
];

export default function DragList({
    leads,
    activePipeline,
    leadActionGroup,
    changeDragAction,
    createUpdateAutoStage,
    searchLeads,
    ...rest
}) {
    const [items, setItems] = useState([]);
    const [groups, setGroups] = useState({});

    const [noteMap, setNoteMap] = useState([]);
    const [initLoading, setInitLoading] = useState(false);

    const leadNotes = useSelector(state => state.leads.leadNotes);

    useEffect(() => {
        // if (!initLoading) {
            const leadMap = [];
            leadNotes.map(note => {
                leadMap["note-" + note.lead_id] = [
                    note.message,
                    moment
                        .utc(note.created_at)
                        .local()
                        .format(`${DATE_FORMAT} H:mm`)
                ];
            });

            setNoteMap(leadMap);

            if (activePipeline) {
                let group = [];
                let dragList = [];
                activePipeline.stages
                    .sort((a, b) => (a.priority > b.priority ? 1 : -1))
                    .map((item, index) => {
                        group["group-" + item.id] = index;
                        const dragItem = {
                            id: "group-" + item.id,
                            label: item.name,
                            items: leads
                                .filter(lead => lead.stage_id === item.id)
                                .sort((a, b) => (a.priority > b.priority ? 1 : -1)),
                            stage: item
                        };
                        dragList.push(dragItem);
                    });
                setGroups(group);

                setItems(dragList);
            }
            setInitLoading(true)
        // }
        // }, [leads, activePipeline]);
    }, [leads, activePipeline]);

    function buildAndSave(items) {
        const groups = {};
        for (let i = 0; i < Object.keys(items).length; ++i) {
            const currentGroup = items[i];
            groups[currentGroup.id] = i;
        }

        // Set the data.
        setItems(items);

        // Makes the groups searchable via their id.
        setGroups(groups);
    }

    const onDragEnd = async result => {
        const { destination, draggableId, source, type } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if ("group" === type) {
            const sourceIndex = source.index;
            const targetIndex = destination.index;

            const workValue = items.slice();
            const [deletedItem] = workValue.splice(sourceIndex, 1);
            workValue.splice(targetIndex, 0, deletedItem);

            // buildAndSave(workValue);
            const res = await changeDragAction(workValue, 'group');
            return;
        }

        const sourceDroppableIndex = groups[source.droppableId];
        const targetDroppableIndex = groups[destination.droppableId];
        const sourceItems = items[sourceDroppableIndex].items.slice();
        const targetItems =
            source.droppableId !== destination.droppableId ? items[targetDroppableIndex].items.slice() : sourceItems;

        const [deletedItem] = sourceItems.splice(source.index, 1);
        targetItems.splice(destination.index, 0, deletedItem);

        const workValue = items.slice();
        workValue[sourceDroppableIndex] = {
            ...items[sourceDroppableIndex],
            items: sourceItems
        };
        workValue[targetDroppableIndex] = {
            ...items[targetDroppableIndex],
            items: targetItems
        };

        setItems(workValue);
        const res = await changeDragAction(workValue, 'item');
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="ROOT" type="group" direction="horizontal">
                {provided => (
                    <Container {...provided.droppableProps} ref={provided.innerRef} className="d-flex">
                        {items.map((item, index) => (
                            <Draggable draggableId={item.id} key={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                    >
                                        <DraggableElement
                                            prefix={item.id}
                                            elements={item.items}
                                            item={item}
                                            pipeline={activePipeline}
                                            leadActionGroup={leadActionGroup}
                                            noteMap={noteMap}
                                            createUpdateAutoStage={createUpdateAutoStage}
                                            searchLeads={searchLeads}
                                            {...rest}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </Container>
                )}
            </Droppable>
        </DragDropContext>
    );
}
