import React, { Component, useEffect, useRef, useState } from "react";
import { IntegrationOptionFormContainer } from "@containers";
// import { Loader } from "components";
import { toast } from "react-toastify";

import { useThread } from "./bot/useThread";

import { Button, Dimmer, Form, Grid, Icon, Image, Input, Segment, TextArea } from "semantic-ui-react";
import { api } from "@services";
import "./index.scss";
import { postMessage } from "./services/api";
import { useRunPolling } from "./bot/useRunPolling";
import { useRunStatus } from "./bot/useRunStatus";
import { runFinishedStates } from "./bot/constants";

export default function ChatbotPage({ match, location }) {
    const contentRef = useRef(null);
    const [newMsg, setNewMsg] = useState("");

    const [runStatus, setRunStatus] = useState("");

    const scrollDown = () => {
        if (contentRef && contentRef.current) {
            contentRef.current.scrollTo({
                top: contentRef.current.scrollHeight,
                behavior: "smooth"
            });
        }
    };

    const queryParams = new URLSearchParams(location.search);
    const uuid = queryParams.get("id");

    const [run, setRun] = useState(undefined);
    const { threadId, messages, clearThread, assist, setMessages } = useThread(run, setRun, uuid, scrollDown);
    useRunPolling(threadId, run, setRun, setRunStatus);
    useEffect(() => {
        scrollDown();
    }, [messages]);

    // toast.success("message", {
    //     position: toast.POSITION.TOP_RIGHT
    // });

    const handleSubmit = async () => {
        console.log("runStatus->>>>>", runStatus);
        if (!runStatus || runFinishedStates.includes(runStatus)) {
            setNewMsg("");
            setRunStatus("queued");
            const tempUserMsg = {
                id: "-1",
                role: "user",
                content: [
                    {
                        type: "text",
                        text: {
                            value: newMsg,
                            annotations: []
                        }
                    }
                ]
            };
            setMessages([...messages, tempUserMsg]);

            const chat1 = await postMessage(threadId, newMsg);
            setRun(chat1);
        }
    };

    const getContrastColor = hexColor => {
        // Convert hex color to RGB
        let r = parseInt(hexColor.substr(1, 2), 16);
        let g = parseInt(hexColor.substr(3, 2), 16);
        let b = parseInt(hexColor.substr(5, 2), 16);

        // Calculate relative luminance
        let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        // Use a threshold to determine whether to use black or white text
        return luminance > 0.5 ? "black" : "white";
    };

    const removeAnnocation = str => {
        const cleanedStr = str.replace(/【\d+†source】/g, "");
        return cleanedStr;
    };

    if (!assist) {
        return <p>Loading...</p>;
    }

    const { name, chat_placeholder, accent_color, avatar, show_branding, welcome_message } = assist;

    return (
        <div className="">
            <Grid className="assistant-container mt-0 assistant-appearence" style={{ marginTop: "0 !important" }}>
                <Grid.Row className="container-row p-0">
                    <Grid.Column className="p-0 right-bar">
                        <div className="bot-wrapper w-100">
                            <div className="h-100vh bot-container">
                                <div className="bot-header">
                                    <div className="avatar">
                                        <img src={avatar ? avatar : "/img/user.png"} alt="" width={30} />
                                        <span className="assistant-name">{name}</span>
                                    </div>
                                    <div style={{ flexGrow: 1 }}></div>
                                    <div className="bot-header-actions">
                                        {/* <i className="ti ti-x"></i> */}
                                        <i className="ti ti-refresh" onClick={() => clearThread()}></i>
                                    </div>
                                </div>
                                <div className="body-content" ref={contentRef}>
                                    {!!welcome_message && !!threadId && (
                                        <div className="msg-left">
                                            <p
                                                style={{
                                                    background: accent_color || "#4d77ff",
                                                    color: getContrastColor(accent_color || "#4d77ff")
                                                }}
                                            >
                                                {welcome_message}
                                            </p>
                                        </div>
                                    )}
                                    {messages
                                        // .toReversed()
                                        // .filter(message => message.hidden !== true)
                                        .map(message => {
                                            if (message.role === "user") {
                                                return (
                                                    <div className="msg-right" key={message.id}>
                                                        <p>{removeAnnocation(message.content[0].text.value)}</p>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div className="msg-left" key={message.id}>
                                                        <p
                                                            style={{
                                                                background: accent_color || "#4d77ff",
                                                                color: getContrastColor(
                                                                    accent_color || "#4d77ff"
                                                                )
                                                            }}
                                                        >
                                                            {removeAnnocation(message.content[0].text.value)}
                                                        </p>
                                                    </div>
                                                );
                                            }
                                        })}
                                    {!!runStatus && !runFinishedStates.includes(runStatus) && (
                                        <div>Agent is typing...</div>
                                    )}
                                </div>
                                <div className="flex-grow-1"></div>
                                <div className="chat-input">
                                    <Form onSubmit={handleSubmit}>
                                        <Input
                                            placeholder={chat_placeholder}
                                            value={newMsg}
                                            onChange={(e, data) => setNewMsg(data.value)}
                                            className="chat-input"
                                        />
                                        <button>
                                            <i className="ti ti-send"></i>
                                        </button>
                                    </Form>
                                </div>
                                {!!show_branding && (
                                    <div className="powered text-center">
                                        powered by{" "}
                                        <a href="https://convertlead.com">
                                            <img src="https://app.convertlead.com/img/logo.png"></img> Convertlead
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}
