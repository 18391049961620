import React, { Component } from "react"
import { compose } from "react-recompose"
import { toast } from "react-toastify"
import {
  Segment,
  Header,
  Input,
  Grid,
  Menu,
  List,
  Button,
  Table,
  Label,
  Progress,
  Checkbox,
  Form,
  Select,
  Confirm,
} from "semantic-ui-react"
import { Device } from "twilio-client"
import SendEmailModal from "./SendEmail"

class FacebookFolder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selIds: [],
      moveFolderId: "",
      confirmOpen: false,
      query: "",
      onPhone: false,
      isEmailModal: false,
      sendEmailAddress: "",
      tagetPageId: "",
    }
    this.timeout = null
  }

  componentWillMount() {
    if (this.props.activeFolderId) {
      this.fetchPageList(this.props.activeFolderId)
    }
    if (this.props.profile.twilio_token) {
      Device.disconnect(() => {
        this.setState({
          onPhone: false,
        })
      })
      Device.ready(() => {})
      // this.props.fetchOwnTwilioToken()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.activeFolderId !== this.props.activeFolderId &&
      this.props.activeFolderId
    ) {
      this.fetchPageList(this.props.activeFolderId)
    }
    
    if (
      prevProps.folder !== this.props.folder
    ) {
      if(this.props.folder.get_user.twilio_token){
        Device.setup(this.props.folder.get_user.twilio_token)
      }
    }
  }

  fetchPageList = async (folderId) => {
    await this.props.getPagesByFolderId(folderId, this.state.query)
  }

  handleCheckbox = (data, pageId) => {
    if (data.checked) {
      this.setState({
        selIds: [...this.state.selIds, pageId],
      })
    } else {
      this.setState({
        selIds: this.state.selIds.filter((selId) => selId !== pageId),
      })
    }
  }

  handleAllCheck = (isChecked) => {
    if (isChecked) {
      const pageIdList = this.props.folderPageList.map((page) => page.id)
      this.setState({
        selIds: pageIdList,
      })
    } else {
      this.setState({
        selIds: [],
      })
    }
  }

  handleSelectFolder = (e, data) => {
    this.setState({ moveFolderId: data.value })
  }

  handleMovePages = async () => {
    const { moveFolderId, selIds } = this.state
    if (!moveFolderId || moveFolderId === this.props.activeFolderId) {
      this.props.sendMessageWarn("Please select another folder")
      return
    }
    if (selIds.length === 0) {
      this.props.sendMessageWarn("You must select at least 1 page.")
      return
    }
    await this.props.movePages(moveFolderId, selIds, this.props.activeFolderId)
    this.setState({ selIds: [] })
  }

  handleDelete = async () => {
    this.setState({ confirmOpen: false })
    const { selIds } = this.state
    if (selIds.length === 0) {
      this.props.sendMessageWarn("You must select at least 1 page.")
      return
    }
    await this.props.deletePages(selIds, this.props.activeFolderId)
    this.setState({ selIds: [] })
  }

  openConfirmModal = (confirmOpen = true) => {
    this.setState({ confirmOpen })
  }

  onDeleteConfirm = () => {
    const { folderDelId } = this.state
    this.setState({ confirmOpen: false })
    this.props.deleteFolder(folderDelId)
  }

  handleSearch = (e) => {
    const { value } = e.target
    this.setState({ query: value })

    clearTimeout(this.timeout) // Clear the previous timer

    // Set a new timer to fetch the filtered data after 3 seconds
    this.timeout = setTimeout(() => {
      this.fetchPageList(this.props.activeFolderId)
    }, 1200)
  }

  exportTo = (type) => {
    this.props.exportTo({
      type,
      search: this.state.query,
      folderId: this.props.activeFolderId,
    })
  }

  onCall = (phoneNumber) => {
    if (this.props.folder.get_user.twilio_token) {
      const checkIsValidNumber = /^([0-9]|#|\*)+$/.test(
        phoneNumber.replace(/[+\-()\s]/g, "")
      )
      if (this.state.onPhone) {
        Device.disconnectAll()
      } else if (checkIsValidNumber) {
        try {
          Device.connect({ number: phoneNumber })
          this.setState({
            onPhone: true,
          })
        } catch (e) {
          toast.error(e.message, { position: toast.POSITION.TOP_RIGHT })
        }
      }
    }
  }

  generateWebsite = (page) => {
    return page.website
    if (page.rating_count !== -7) {
      return page.website
    } else {
      const companyWebsite = JSON.parse(page.companies)
      if (companyWebsite && companyWebsite.url) {
        return companyWebsite.url
      }
      return ""
    }
  }

  render() {
    const {
      pageList,
      folder,
      folderOptionList,
      folderPageList,
    } = this.props
    const {
      selIds,
      moveFolderId,
      onPhone,
      isEmailModal,
      sendEmailAddress,
      tagetPageId,
    } = this.state

    const fbPageList = folderPageList.map((page, index) => {
      return (
        <Table.Row key={index}>
          <Table.Cell>
            <Checkbox
              onChange={(e, data) => this.handleCheckbox(data, page.id)}
              checked={selIds.includes(page.id)}
            />
          </Table.Cell>
          <Table.Cell>
            <div className="pagename-wrapper">
              <div className="fb-pagetitle">{page.page_name}</div>
              <div className="d-flex followers">
                <Label className="no-domain">
                  {page.followers_count} followers
                </Label>
                <Label className="no-domain">
                  <i className="flaticon stroke briefcase"></i> {page.category}
                </Label>
              </div>
              {/* -7 => linkedin */}
              {page.rating_count !== -7 && (
                <div>
                  <Label className="no-domain reviews">
                    <i className="ti ti-star"></i> {page.overall_star_rating}(
                    {page.rating_count}
                    reviews)
                  </Label>
                </div>
              )}
            </div>
          </Table.Cell>
          <Table.Cell>
            {page.phone && folder.get_user.twilio_token && (
              <Button
                color="grey"
                className={
                  "circle-btn " + (onPhone ? "icon endCall" : "icon onCall")
                }
                onClick={() => this.onCall(page.phone)}
                title="Call "
              >
                <a>
                  <i className="ti ti-phone-call"></i>
                </a>
              </Button>
            )}
            {page.emails && (
              <Button
                color="grey"
                className="circle-btn"
                // onClick={}
                title="Send e-mail"
                onClick={() => {
                  this.setState({
                    isEmailModal: true,
                    sendEmailAddress: page.emails,
                    tagetPageId: page.id,
                  })
                }}
              >
                <a>
                  <i className="ti ti-mail"></i>
                </a>
              </Button>
            )}
            <Button
              color="grey"
              className="circle-btn"
              onClick={() => {
                this.props.openLoadForm({
                  show: true,
                  fullname: page.page_name,
                  email: page.emails,
                  phone: page.phone,
                  address: page.single_line_address,
                  website: this.generateWebsite(page),
                })
              }}
              title="Add to integration"
            >
              <i className="ti ti-plus"></i>
            </Button>
            <p className="mb-0">{page.phone}</p>
            <p>{page.emails}</p>
          </Table.Cell>
          <Table.Cell>
            <div className="score-bar">
              <Progress
                percent={this.props.handleCalcScore(page, true, page.rating_count === -7)[1]}
                color="blue"
                size="large"
                style={{ minWidth: "unset" }}
                className="postition-relative"
              >
                <span className="postition-absolute">
                  {this.props.handleCalcScore(page, true, page.rating_count === -7)[0]} days ago
                </span>
              </Progress>
            </div>
          </Table.Cell>
          <Table.Cell>
            <p className="address-line">{page.single_line_address}</p>
          </Table.Cell>
          <Table.Cell className="actions">
            {this.generateWebsite(page) && (
              <Button
                color="teal"
                className="circle-btn square"
                onClick={() =>
                  window.open(this.generateWebsite(page), "_blank")
                }
                title="Company website"
              >
                <i className="ti ti-link"></i>
              </Button>
            )}

            {page.rating_count !== -7 ? (
              <>
                <Button
                  color="teal"
                  className="circle-btn square"
                  onClick={() =>
                    window.open(
                      `https://m.me/${page.page_id}?text=${folder.default_msg}`,
                      "_blank"
                    )
                  }
                  title="Contact on messenger"
                >
                  <i className=" ti ti-brand-messenger"></i>
                </Button>
                <Button
                  color="teal"
                  className="circle-btn square"
                  onClick={() =>
                    window.open(
                      `https://facebook.com/${page.page_id}`,
                      "_blank"
                    )
                  }
                  title="Facebook profile"
                >
                  <i class=" ti ti-brand-facebook"></i>
                </Button>
              </>
            ) : (
              <Button
                color="teal"
                className="circle-btn square"
                onClick={() => window.open(page.picture, "_blank")}
                title="Linkedin profile"
              >
                <i class=" ti ti-brand-linkedin"></i>
              </Button>
            )}
          </Table.Cell>
        </Table.Row>
      )
    })

    if (!folder) {
      return (
        <Grid.Column width={12} floated="right">
          <Segment attached="top">
            <Grid.Column>
              <Header floated="left" as="h1">
                <div className="d-flex">
                  <h1 className="ui left floated header">
                    <List.Icon className="ti ti-folder" />
                    Folder
                  </h1>
                </div>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Menu secondary>
                <Menu.Menu position="right">
                  <Menu.Item>
                    <Input icon="search" placeholder="Search..." />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
            </Grid.Column>
          </Segment>

          <Segment basic className="top-space">
            <Grid.Row className="d-flex justify-between">
              <Grid.Column>
                <Button.Group>
                  <Button disabled>{selIds.length} item selected</Button>
                  <Button>move to folder</Button>
                  <Button>delete</Button>
                </Button.Group>
              </Grid.Column>
              <Grid.Column>
                <div className="exportbox">
                  Export your data
                  <a
                    href="#"
                    onClick={() => this.exportTo("TYPE_FB_FOLDER_CSV")}
                  >
                    .csv export
                  </a>
                  <a
                    href="#"
                    onClick={() => this.exportTo("TYPE_FB_FOLDER_PDF")}
                  >
                    .pdf export
                  </a>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Table singleLine>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}>
                    <span className="table-head">
                      <Checkbox
                        checked={
                          selIds.length !== 0 &&
                          selIds.length === folderPageList.length
                        }
                        onChange={(e, data) =>
                          this.handleAllCheck(data.checked)
                        }
                      />
                    </span>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={5}>
                    <span className="table-head">PAGE NAME</span>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>
                    <span className="table-head">CONTACT</span>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>
                    <span className="table-head">LAST POST</span>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={6}>
                    <span className="table-head">ADDRESS</span>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>
                    <span className="table-head">Actions</span>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body></Table.Body>
            </Table>
          </Segment>
        </Grid.Column>
      )
    }

    return (
      <Grid.Column width={12} floated="right">
        <SendEmailModal
          isEmailModal={isEmailModal}
          sendEmailAddress={sendEmailAddress}
          onCloseSendEmailModal={() => this.setState({ isEmailModal: false })}
          onSubimit={this.props.sendContactEmail}
          tagetPageId={tagetPageId}
        />
        <Confirm
          open={this.state.confirmOpen}
          onCancel={() => this.openConfirmModal(false)}
          onConfirm={this.handleDelete}
          className="confirm-modal"
        />
        <Segment attached="top">
          <Grid.Column>
            <Header floated="left" as="h1">
              <div className="d-flex">
                <h1 className="ui left floated header">
                  <List.Icon className="ti ti-folder" />
                  {folder.name}
                </h1>
                <Label className="no-domain">{folder.description}</Label>
              </div>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Menu secondary>
              <Menu.Menu position="right">
                <Menu.Item>
                  <Input
                    icon="search"
                    placeholder="Search..."
                    value={this.state.query}
                    onChange={this.handleSearch}
                  />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </Grid.Column>
        </Segment>

        <Segment basic className="top-space">
          <Grid.Row className="d-flex justify-between">
            <Grid.Column>
              <Button.Group>
                <Button disabled>{selIds.length} item selected</Button>
                <Button onClick={() => this.handleMovePages()}>
                  move to folder
                </Button>
                <Button onClick={() => this.openConfirmModal(true)}>
                  delete
                </Button>
                <Form.Field
                  control={Select}
                  options={folderOptionList}
                  placeholder="Select Folder"
                  search
                  onChange={this.handleSelectFolder}
                  searchInput={{ id: "fb-folder-list" }}
                  value={moveFolderId}
                />
              </Button.Group>
            </Grid.Column>
            <Grid.Column>
              <div className="exportbox">
                Export your data
                <a
                  href="#export-csv"
                  onClick={() => this.exportTo("TYPE_FB_FOLDER_CSV")}
                >
                  .csv export
                </a>
                <a
                  href="#export-pdf"
                  onClick={() => this.exportTo("TYPE_FB_FOLDER_PDF")}
                >
                  .pdf export
                </a>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Table singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}>
                  <span className="table-head">
                    <Checkbox
                      checked={
                        selIds.length !== 0 &&
                        selIds.length === folderPageList.length
                      }
                      onChange={(e, data) => this.handleAllCheck(data.checked)}
                    />
                  </span>
                </Table.HeaderCell>
                <Table.HeaderCell width={5}>
                  <span className="table-head">PAGE NAME</span>
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  <span className="table-head">CONTACT</span>
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  <span className="table-head">LAST POST</span>
                </Table.HeaderCell>
                <Table.HeaderCell width={6}>
                  <span className="table-head">ADDRESS</span>
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  <span className="table-head">Actions</span>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{fbPageList}</Table.Body>
          </Table>
        </Segment>
      </Grid.Column>
    )
  }
}

export default FacebookFolder
