import React, { Component } from "react";
import { Button, Form, Input, Select, TextArea } from "semantic-ui-react";
// import "./index.scss";
import { Auth } from "@services";
import { compose } from "react-recompose";
import { AssistantFormContainer, UploadFileContainer } from "@containers";
import Loader from "../../../loader";

class AssistantForm extends Component {
    state = {
        fileList: []
    };

    componentWillMount() {
        if (Auth.role === "agency") {
            this.props.loadSelectBoxCompanies();
        }
    }

    onChangeFirst = e => {
        this.props.changeForm({ changeable: e.target.value });
    };

    onChangeField = data => {
        this.props.changeForm({ [data.name]: data.value });
    };

    handleUploadFile = e => {
        const file_0 = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file_0);
        formData.append("parameter", "file");

        const assistantForm = this.props.form.form;
        formData.append("type", assistantForm.type);
        if (assistantForm.type === "knowledge") {
            formData.append("assist_id", assistantForm.id);
        }
        this.props.uploadFile(formData, assistantForm);
    };

    handleDeleteFile = id => {
        const assistantForm = this.props.form.form;
        if (assistantForm.type === "knowledge") {
            this.props.removeFile(id, assistantForm.id);
        } else {
            this.props.removeFile(id);
        }
    };

    render() {
        const { name, company_id, website, type } = this.props.form.form;
        const { selectBoxCompanies, fileInfo, uploadLoading } = this.props;
        console.log({ fileInfo });
        console.log({ name, company_id, website, type });

        return (
            <Form className="domainForm">
                {type == "create" && (
                    <>
                        <Form.Field required>
                            <label>Assistant name</label>
                            <Form.Field
                                control={Input}
                                placeholder={"Assistant Name"}
                                value={name}
                                name="name"
                                onChange={(e, data) => this.onChangeField(data)}
                            />
                        </Form.Field>
                        {Auth.role === "agency" && (
                            <Form.Field required>
                                <label>Company</label>
                                <Select
                                    placeholder="Select Company"
                                    value={company_id}
                                    name="company_id"
                                    options={selectBoxCompanies}
                                    onChange={(e, data) => this.onChangeField(data)}
                                />
                            </Form.Field>
                        )}
                    </>
                )}

                <p>How do you want to train your assistant?</p>
                <Form.Field>
                    <label>Upload files or use your website</label>
                    {/* <TextArea name="website" onChange={(e, data) => this.onChangeField(data)} value={website || ""} /> */}
                    <input type="file" name="file" class="d-none" id="train-files" onChange={this.handleUploadFile} />
                    <div className="d-flex">
                        <label htmlFor="train-files" class="a-link">
                            Click to Upload
                        </label>
                        {uploadLoading && <Loader />}
                    </div>
                </Form.Field>
                <div>
                    <div>Traning Files:</div>
                    {fileInfo &&
                        fileInfo.map(traning => (
                            <div key={traning.id} className="d-flex content-between ">
                                <div>
                                    <i className="ti ti-file-text"></i>
                                    {traning.filename}
                                </div>
                                <div className="d-flex">
                                    {traning.bytes} bytes
                                    <button
                                        className="actions button"
                                        onClick={() => this.handleDeleteFile(traning.id)}
                                    >
                                        <i className="ti ti-trash"></i>
                                    </button>
                                </div>
                            </div>
                        ))}
                </div>
            </Form>
        );
    }
}

export default compose(AssistantFormContainer, UploadFileContainer)(AssistantForm);
