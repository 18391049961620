import * as actions from "./actions";
import * as domainactions from "./../../domain/actions";
import * as domainThunks from "./../../domain/thunks";
import { Auth } from "@services";
import { sendMessage } from "@containers/messages/thunks";
import { saveFormApi, deleteFolderApi, updateFormApi, addPageToFolderThunkApi, sendContactEmail } from "./api";
import { hideLoader, showLoader } from "../../loader/actions";
import { setFolderList } from "../../fb-tool/actions";

import history from "./../../../history";
import { getFoldersThunk } from "../../fb-tool/thunks";

export const saveForm = form => async dispatch => {
    dispatch(showLoader());
    let folderList = [];
    if (Auth.role === "Agency" && !form.user_id) {
        dispatch(sendMessage("Company is required field", true));
    } else {
        if (form.id) {
            const folderListRes = await updateFormApi(form);
            folderList = folderListRes.data;
        } else {
            const folderListRes = await saveFormApi(form);
            folderList = folderListRes.data;
        }
        dispatch(getFoldersThunk());
        dispatch(sendMessage("Successfully created"));
        dispatch(actions.closeForm());
    }

    dispatch(hideLoader());
};

export const deleteThunk = id => async dispatch => {
    dispatch(showLoader());
    const folderList = await deleteFolderApi(id);
    dispatch(getFoldersThunk());

    // dispatch(setFolderList(folderList.data));
    dispatch(sendMessage("Successfully deleted"));
    dispatch(hideLoader());
};

export const addPageToFolderThunk = (folderId, pages) => async dispatch => {
    dispatch(showLoader());
    const result = await addPageToFolderThunkApi(folderId, pages);
    if (result.data.status) {
        dispatch(sendMessage("Successfully added"));
    } else {
        dispatch(sendMessage(result.data.msg, true));
    }
    dispatch(hideLoader());
};

export const emailSendThunk = (email, subject, content, folderPageId) => async dispatch => {
    dispatch(showLoader());
    const result = await sendContactEmail(email, subject, content, folderPageId);
    if (result.data.status) {
        dispatch(sendMessage("Successfully added"));
    } else {
        dispatch(sendMessage(result.data.msg, true));
    }
    dispatch(hideLoader());
};
