import { connect } from "react-redux";
import * as thunks from "./thunks";
import * as actions from "./actions";

const mapStateToProps = state => ({
    pipelines: state.pipeline.pipelines,
    activePipeline: state.pipeline.activePipeline,
    openPipelineForm: state.pipeline.openForm
});

const mapDispatchToProps = dispatch => ({
    loadPipelines: companyId => dispatch(thunks.loadPipelines(companyId)),
    pipelineForm: openForm => dispatch(actions.pipelineForm(openForm)),
    changeActivePipeline: pipeline => dispatch(actions.changeActivePipeline(pipeline)),
    createUpdatePipeline: pipeline => dispatch(thunks.createUpdatePipeline(pipeline)),
    changeDragAction: (leads, type) => dispatch(thunks.changeDragAction(leads, type)),
    createUpdateAutoStage: param => dispatch(thunks.createUpdateAutoStage(param)),
    onDeleteStage: stageId => dispatch(thunks.deleteStage(stageId)),
    onDeletePipeline: pipelineId => dispatch(thunks.deletePipeline(pipelineId))
});

export default connect(mapStateToProps, mapDispatchToProps);
