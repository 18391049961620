import React, { Component } from "react";
import * as R from "ramda";
import { compose } from "react-recompose";
import { ProfileContainer } from "@containers";
import { Form, Input, Segment, Button, Grid, Tab } from "semantic-ui-react";
import "./index.scss";
import avatarDemo from "../avatar-demo.png";
import { AvatarImage } from "components/@common/image";
import { disableAutoComplete } from "../../../../utils";

class CompanyForm extends Component {
    state = {
        // open: false,
        // companyId: null,
        // ready: false,
        // companyStats: {},
        tabIndex: 0
        // sortByValue: 'name.desc'
    };
    onFileLoad = event => {
        if (!R.pathOr(false, ["target", "files"], event)) {
            this.props.sendMessage("Missing required File!", true);
            return false;
        }

        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.addEventListener(
            "load",
            () => {
                this.props.changeForm({
                    avatar: reader.result
                });
            },
            false
        );
    };

    onChange = (event, data) => {
        this.props.changeForm({ [data.name]: data.value });
    };

    componentDidMount() {
        disableAutoComplete();
    }
    onChangeTab = (e, tab) => {
        if (tab.activeIndex === this.state.tabIndex) {
            return;
        }

        this.setState({
            tabIndex: tab.activeIndex
        });
    };
    render() {
        const {
            id,
            name,
            phone,
            email,
            avatar,
            twilio_sid,
            twilio_token,
            avatar_path,
            twilio_mobile_number,
            mail_host,
            mail_port,
            mail_username,
            mail_password,
            mail_from_address,
            ai_token,
            fb_credit
        } = this.props.form;
        const { profile } = this.props;
        const tabs = [
            {
                menuItem: "Twilio",
                render: () => <></>
            },
            {
                menuItem: "SMTP",
                render: () => <></>
            }
        ];

        const isDefaultCompany = id === profile.id;
        return (
            <Form size="big" className="companyForm" autoComplete="off">
                <Grid columns={2} relaxed="very" stackable>
                    <Grid.Column>
                        <Form.Field required>
                            <label>Company Name</label>
                            <Input
                                placeholder="Company Name"
                                name="name"
                                autoComplete="off"
                                className="srch"
                                value={name || ""}
                                onChange={this.onChange}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>Phone Number</label>
                            <Input
                                placeholder="Phone Number"
                                name="phone"
                                autoComplete="off"
                                value={phone || ""}
                                onChange={this.onChange}
                            />
                        </Form.Field>
                        <Segment.Inline>
                            <AvatarImage size="tiny" circular src={avatar || avatar_path || avatarDemo} />
                            <label htmlFor="avatar">
                                <Button
                                    icon="upload"
                                    label={{
                                        basic: true,
                                        content: "Select file"
                                    }}
                                    labelPosition="right"
                                />
                                <input
                                    hidden
                                    accept=".png, .jpeg, .jpg, image/png, image/jpeg, image/jpg"
                                    id="avatar"
                                    type="file"
                                    onChange={this.onFileLoad}
                                />
                            </label>
                        </Segment.Inline>
                        <label className="note-label">Add company logo (Optional)</label>
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle">
                        <Form.Field required>
                            <label>Email Address</label>
                            <Input
                                placeholder="Email Address"
                                name="email"
                                value={email || ""}
                                onChange={this.onChange}
                                disabled={isDefaultCompany}
                            />
                        </Form.Field>
                        <Form.Field required={!id}>
                            <label>Password ( min. 6 characters ) </label>
                            <Input
                                placeholder="Password"
                                disabled={isDefaultCompany}
                                name="password"
                                type="password"
                                onChange={this.onChange}
                            />
                        </Form.Field>
                        <Form.Field required={!id}>
                            <label>Re-enter Password</label>
                            <Input
                                placeholder="Password"
                                name="password_confirmation"
                                type="password"
                                onChange={this.onChange}
                                disabled={isDefaultCompany}
                            />
                        </Form.Field>
                        <Form.Field required={true}>
                            <label>AI word count</label>
                            <Input
                                placeholder="AI word"
                                name="ai_token"
                                type="number"
                                min={0}
                                max={profile.ai_token}
                                value={ai_token}
                                onChange={this.onChange}
                                disabled={isDefaultCompany}
                            />
                        </Form.Field>
                        <Form.Field required={true}>
                            <label>FB Credit</label>
                            <Input
                                placeholder="FB credit"
                                name="fb_credit"
                                type="number"
                                min={0}
                                max={profile.fb_credit}
                                value={fb_credit}
                                onChange={this.onChange}
                                disabled={isDefaultCompany}
                            />
                        </Form.Field>
                    </Grid.Column>

                    <Grid.Row className="twilioMobileContainer">
                        <Grid.Column>
                            <Tab
                                onTabChange={this.onChangeTab}
                                menu={{ secondary: true, pointing: true }}
                                panes={tabs}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {this.state.tabIndex === 0 ? (
                        <>
                            <Grid.Row className="twilioContainer">
                                <Grid.Column>
                                    <Form.Field>
                                        <label>Twilio SID</label>
                                        <Input
                                            placeholder="Twilio SID"
                                            name="twilio_sid"
                                            value={twilio_sid || ""}
                                            onChange={this.onChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>Twilio Token</label>
                                        <Input
                                            placeholder="Twilio Token"
                                            name="twilio_token"
                                            value={twilio_token || ""}
                                            onChange={this.onChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="twilioMobileContainer">
                                <Grid.Column>
                                    <Form.Field>
                                        <label>Twilio mobile number</label>
                                        <Input
                                            placeholder="Twilio mobile number"
                                            name="twilio_mobile_number"
                                            value={twilio_mobile_number || ""}
                                            onChange={this.onChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </>
                    ) : (
                        <>
                            <Grid.Row className="twilioContainer">
                                <Grid.Column>
                                    <Form.Field>
                                        <label>Smtp host</label>
                                        <Input
                                            placeholder="host"
                                            name="mail_host"
                                            value={mail_host || ""}
                                            onChange={this.onChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>Smtp port</label>
                                        <Input
                                            placeholder="port"
                                            name="mail_port"
                                            value={mail_port || ""}
                                            onChange={this.onChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="twilioContainer">
                                <Grid.Column>
                                    <Form.Field>
                                        <label>Smtp username</label>
                                        <Input
                                            placeholder="username"
                                            name="mail_username"
                                            value={mail_username || ""}
                                            onChange={this.onChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>Smtp password</label>
                                        <Input
                                            placeholder="password"
                                            name="mail_password"
                                            value={mail_password || ""}
                                            onChange={this.onChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="twilioContainer">
                                <Grid.Column>
                                    <Form.Field>
                                        <label>Smtp from address</label>
                                        <Input
                                            placeholder="from address"
                                            name="mail_from_address"
                                            value={mail_from_address || ""}
                                            onChange={this.onChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </>
                    )}
                </Grid>
            </Form>
        );
    }
}

export default compose(ProfileContainer)(CompanyForm);
