import * as actions from "./actions";
import * as assistantActions from "./../../assistant/actions";
import { Auth } from "@services";
import { sendMessage } from "@containers/messages/thunks";
import { saveFormApi, updateFileApi, updateFormApi } from "./api";
import { hideLoader, showLoader } from "../../loader/actions";
import { formatListAction } from "../../uploadFile/actions";

export const saveForm = (form, type) => async (dispatch, getState) => {
    try {
        dispatch(showLoader());

        const assistantForm = form.form;

        let newList = null;

        if (assistantForm.type === "create") {
            if (!assistantForm.name) {
                throw new Error("Missing required name");
            }
            if (Auth.role === "agency") {
                if (!assistantForm.company_id) {
                    throw new Error("Missing required company");
                }
            }

            const fileList = getState().uploadFileReducer.fileInfo;
            const fileIds = fileList.map(fl => fl.id);
            assistantForm.fileList = fileIds;

            newList = await saveFormApi(assistantForm);
        } else {
            if (!assistantForm.id) {
                throw new Error("Missing required id");
            }

            if (assistantForm.type === "knowledge") {
                const fileList = getState().uploadFileReducer.fileInfo;
                const fileIds = fileList.map(fl => fl.id);

                assistantForm.fileList = fileIds;
            } else {
                assistantForm.type = type;
            }

            newList = await updateFormApi(assistantForm);

        }
        dispatch(actions.closeForm());

        if (newList.data) {
            dispatch(assistantActions.loadAssistantListAction(newList.data));

            // changeForm

            if (newList.data.length == 1) {
                dispatch(assistantActions.setActiveAssistent(newList.data[0].id));
            }

            dispatch(sendMessage("Successfully saved!"));

            const activeAssistantId = getState().assistantReducer.activeAssistant;
            const activeAssistant = newList.data.find(ass => ass.id === activeAssistantId);
            dispatch(actions.changeForm(activeAssistant));

            // clear file list
            dispatch(formatListAction());
        } else {
            dispatch(sendMessage("Something went wrong!", true));
        }

        dispatch(hideLoader());
    } catch (e) {
        dispatch(sendMessage(e.message, true));
        dispatch(hideLoader());
    }
};

export const uploadFileThunk = formData => async dispatch => {
    try {
        dispatch(showLoader());

        const formRes = await updateFileApi(formData);
        console.log({ formRes });

        dispatch(hideLoader());
        return formRes;
    } catch (e) {
        dispatch(sendMessage(e.message, true));
    }
};

export const closeForm = () => (dispatch, getState) => {
    console.log("getState().assistant=>>", getState());
    const activeAssistantId = getState().assistantReducer.activeAssistant;
    const assistantList = getState().assistantReducer.assistantList;
    const activeAssistant = assistantList.find(ass => ass.id === activeAssistantId);
    dispatch(actions.changeForm(activeAssistant));
    dispatch(actions.closeForm());
};
