import React, { Component } from "react";
import { Segment, Header, Grid, Button, Table, Label, Progress, Checkbox, Select, Form } from "semantic-ui-react";


const defaultSearchMsg = "Welcome to convertlead!";
class Prospecter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selIds: [],
            folderId: ""
        };
    }

    handleCheckbox = (data, pageId) => {
        if (data.checked) {
            this.setState({
                selIds: [...this.state.selIds, pageId]
            });
        } else {
            this.setState({
                selIds: this.state.selIds.filter(selId => selId !== pageId)
            });
        }
    };

    handleAllCheck = isChecked => {
        if (isChecked) {
            const pageIdList = this.props.pageList.map(page => page.id);
            this.setState({
                selIds: pageIdList
            });
        } else {
            this.setState({
                selIds: []
            });
        }
    };

    filterByStatus = (e, data) => {
        const folderId = data.value;
        this.setState({ folderId });
    };

    handleAddPageToFolder = async () => {
        const { folderId, selIds } = this.state;
        if (!folderId) {
            this.props.sendMessageWarn("Please select folder");
            return;
        }
        if (selIds.length === 0) {
            this.props.sendMessageWarn("You must select at least 1 page.");
            return;
        }
        const pages = [];
        selIds.map(id => {
            const page = this.props.pageList.find(p => p.id === id);
            if (page) {
                pages.push(page);
            }
        });
        await this.props.addPageToFolderHandle(folderId, pages);
        this.setState({ selIds: [] });
    };

    linkedinWebsite = website => {
        const websiteList = JSON.parse(website);
        if (websiteList.length > 0) {
            return (
                <Button
                    color="teal"
                    className="circle-btn square"
                    onClick={() => window.open(websiteList[0], "_blank")}
                    title="Website"
                >
                    <i className="ti ti-link"></i>
                </Button>
            );
        } else {
            return null;
        }
    };

    render() {
        const { pageList } = this.props;
        const { selIds, folderId } = this.state;
        const fbPageList = pageList.map((page, index) => {
            return (
                <Table.Row key={index}>
                    <Table.Cell>
                        <span className="table-head">
                            <Checkbox
                                onChange={(e, data) => this.handleCheckbox(data, page.id)}
                                checked={selIds.includes(page.id)}
                            />
                        </span>
                    </Table.Cell>
                    <Table.Cell className="d-flex align-items-center">
                        {page.extension !== "linkedin" && (
                            <img
                                src={page.picture.data.url}
                                width={50}
                                height={50}
                                className="circle-btn page-avatar"
                            />
                        )}
                        <div className="pagename-wrapper">
                            <div className="fb-pagetitle">{page.name}</div>
                            <div className="d-flex followers">
                                <Label className="no-domain">
                                    {page.extension !== "linkedin" ? page.followers_count : page.follower} followers
                                </Label>
                                <Label className="no-domain">
                                    <i className="flaticon stroke briefcase"></i> {page.category}
                                </Label>
                            </div>
                        </div>
                    </Table.Cell>
                    <Table.Cell>
                        <div className="score-bar">
                            <Progress
                                percent={this.props.handleCalcScore(page)[1]}
                                color="blue"
                                size="large"
                                style={{ minWidth: "unset" }}
                                className="postition-relative"
                            >
                                <span className="postition-absolute">
                                    {this.props.handleCalcScore(page)[0]} days ago
                                </span>
                            </Progress>
                        </div>
                    </Table.Cell>
                    <Table.Cell>
                        <p className="address-line">
                            {page.extension !== "linkedin" ? page.single_line_address : page.job}
                        </p>
                    </Table.Cell>
                    <Table.Cell className="actions">
                        {page.extension !== "linkedin"
                            ? page.website && (
                                  <Button
                                      color="teal"
                                      className="circle-btn square"
                                      onClick={() => window.open(page.website, "_blank")}
                                      title="Website"
                                  >
                                      <i className="ti ti-link"></i>
                                  </Button>
                              )
                            : this.linkedinWebsite(page.website)}
                        {page.extension !== "linkedin" && (
                            <Button
                                color="teal"
                                className="circle-btn square"
                                onClick={() =>
                                    window.open(`https://m.me/${page.id}?text=${defaultSearchMsg}`, "_blank")
                                }
                                // onClick={}
                                title="New Company"
                            >
                                <i className=" ti ti-brand-messenger"></i>
                            </Button>
                        )}

                        {page.extension !== "linkedin" ? (
                            <Button
                                color="teal"
                                className="circle-btn square"
                                onClick={() => window.open(page.link, "_blank")}
                                title="New Company"
                            >
                                <i class=" ti ti-brand-facebook"></i>
                            </Button>
                        ) : (
                            <Button
                                color="teal"
                                className="circle-btn square"
                                onClick={() => window.open(page.profileLink, "_blank")}
                                title="New Company"
                            >
                                <i class=" ti ti-brand-linkedin"></i>
                            </Button>
                        )}
                    </Table.Cell>
                </Table.Row>
            );
        });

        return (
            
                <Grid.Column width={12} floated="right" className="scroll-slider">
                <Grid.Row className="step-wrapper"><div className="demo-step enrich"><span>Step 2 </span> - Select leads & move to enrichment folder</div></Grid.Row>
                    <Segment attached="top">

                        <Grid.Column>
                            <Header floated="left" as="h1">
                                <div className="d-flex">

                                    <h1 className="ui left floated header">Prospectr</h1>
                                    <Label className="no-domain">Find businesses with bad marketing score</Label>
                                </div>
                            </Header>
                        </Grid.Column>
                    </Segment>


                    <Segment basic className="top-space">


               

                        <Grid.Row className="d-flex justify-between">
                            <Grid.Column>
                                <Button.Group>
                                    <Button disabled>{selIds.length} item selected</Button>
                                    <Button>move to folder</Button>
                                    <Form.Field
                                        control={Select}
                                        options={[]}
                                        placeholder="Select Folder"
                                        search
                                        onChange={this.filterByStatus}
                                        searchInput={{ id: "fb-folder-list" }}
                                        value={folderId}
                                    />
                                </Button.Group>
                            </Grid.Column>
                            <Grid.Column></Grid.Column>
                            </Grid.Row>   
                        <Table singleLine className="demo-table">
                             <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={1}>
                                        <Checkbox
                                            checked={selIds.length > 0 && selIds.length === pageList.length}
                                            onChange={(e, data) => this.handleAllCheck(data.checked)}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                        <span className="table-head">PAGE NAME</span>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        <span className="table-head">INTENT/LAST POST</span>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={3}>
                                        <span className="table-head">ADDRESS</span>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={3}>
                                        <span className="table-head">Actions</span>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                            <img className="placeholder" src="/img/fbplaceholder.png" />
                            {fbPageList}</Table.Body>
                        </Table>
                    </Segment>
                    {pageList.length > 0 && pageList[0].extension !== "linkedin" && (
                        <Segment textAlign="center" attached="bottom" className="load-more-container">
                            <Button fluid color="primary" className="mt-3" onClick={this.props.handleLoadMore}>
                                Load More
                            </Button>
                        </Segment>
                    )}
                </Grid.Column>
            
        );
    }
}

export default Prospecter;
