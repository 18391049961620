import React, { Component } from "react";
import CopyText from "react-copy-text";
import {
    Grid,
    List,
    ListItem,
    ListContent,
    ListHeader,
    FormField,
    Button,
    Form,
    TextArea,
    Confirm,
    Input
} from "semantic-ui-react";

import "./index.scss";
import { config } from "@services";

class Embed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            irameUrl: "",
            iframeCopied: false,

            embedUrl: "",
            embedCopied: false
        };
    }

    initLoad = () => {
        const { assistantList, activeAssistant } = this.props;
        const assistant = assistantList.find(ass => ass.id == activeAssistant);

        const embedJSUrl = config.get("REACT_APP_SERVER") + `embed.js?id=${assistant.uuid}`;
        const embedUrl = `<script src="${embedJSUrl}" async></script>`;

        const irameUrl = config.get("REACT_APP_WEB_URI") + `chatbot?id=${assistant.uuid}`;

        if (assistant) {
            this.setState({
                embedUrl,
                irameUrl
            });
        }
    };

    componentDidMount() {
        // this.initLoad();
    }

    onCopyEmbed = async (embedUrl) => {
        await navigator.clipboard.writeText(embedUrl);
        this.setState({
            embedCopied: true
            // embedUrl: this.state.embedUrl
        });

        setTimeout(() => {
            this.setState({
                embedCopied: false
                // embedUrl: ""
            });
        }, 400);
    };

    onCopyIframe = async (irameUrl) => {
        await navigator.clipboard.writeText(irameUrl);
        this.setState({
            iframeCopied: true
        });

        setTimeout(() => {
            this.setState({
                iframeCopied: false
            });
        }, 400);
    };

    onCopied = txt => {
        console.log({ txt });
    };

    render() {

        // const { embedUrl, irameUrl } = this.state;

        const { assistantList, activeAssistant } = this.props;
        const assistant = assistantList.find(ass => ass.id == activeAssistant);

        const embedJSUrl = config.get("REACT_APP_SERVER") + `embed.js?id=${assistant.uuid}`;
        const embedUrl = `<script src="${embedJSUrl}" async></script>`;

        const irameUrl = config.get("REACT_APP_WEB_URI") + `/chatbot?id=${assistant.uuid}`;

        return (
            <Grid className="h-100 assistant-settings">
                <Grid.Column width={16} className="header">
                    <div className="d-flex align-items-center">
                        <i className="ti ti-settings"></i>
                        <div>
                            <strong>Embed</strong>
                            <p></p>
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column className="main-form" width={8}>
                    <Form>
                        <Form.Field>
                            <label>Embed JS Link </label>
                            <CopyText text={embedUrl} />
                            <Input
                                action={{
                                    color: "teal",
                                    labelPosition: "right",
                                    icon: "copy",
                                    content: `${this.state.embedCopied ? "Copied" : "Copy"}`,
                                    onClick: () => this.onCopyEmbed(embedUrl)
                                }}
                                value={embedUrl}
                            />
                        </Form.Field>
                    </Form>

                    <Form>
                        <Form.Field>
                            <label>Iframe Link </label>
                            <CopyText text={irameUrl} onCopied={() => this.onCopied} />
                            <Input
                                action={{
                                    color: "teal",
                                    labelPosition: "right",
                                    icon: "copy",
                                    content: `${this.state.iframeCopied ? "Copied" : "Copy"}`,
                                    onClick: () => this.onCopyIframe(irameUrl)
                                }}
                                value={irameUrl}
                            />
                        </Form.Field>
                    </Form>
                </Grid.Column>
            </Grid>
        );
    }
}

export default Embed;
