import * as actions from "./actions";
import * as builderactions from "../../builder/actions";
import { hideLoader, showLoader } from "../../loader/actions";

import { sendMessage } from "@containers/messages/thunks";

import { customizePage } from "./api";
import { editPage } from "./../../builder/api";

export const saveForm = form => async dispatch => {
    try {
        dispatch(showLoader());
        if (!form.name) {
            throw new Error("Missing required Validation!");
        }
        let data = {};
        data.output = localStorage.getItem("output");
        data.type = form.type;
        data.name = form.name;
        let id = localStorage.getItem("id");

        if (id && data.output) {
            if (data.output === "project") {
                data.project_id = id;
            } else if (data.output === "template") {
                data.template_id = id;
            }

            let res;
            if (form.type === 0 || form.type === 1) {
                // new and dupliate
                data.name = form.name;
                if (form.type === 1) {
                    data.content =
                        "<html><head><style>" +
                        form.editor.getCss() +
                        "</style></head><body>" +
                        form.editor.getHtml() +
                        "</body></html>";
                    data.gjs = form.page.gjs;
                    // data.gjs = localStorage.getItem('gjsProject');
                }
                res = await customizePage(data);
                res = res.data;
                if (res.state === "success") {
                    await dispatch(sendMessage("Successfully Page Create!"));
                    await dispatch(builderactions.addPage(res.page));
                    await dispatch(actions.saveForm(form));
                } else if (res.state === "noproject") {
                    await dispatch(sendMessage("No project!"), true);
                } else if (res.state === "existpage") {
                    await dispatch(sendMessage("Already page exist!"), true);
                } else {
                    await dispatch(sendMessage("Error create page!"), true);
                }
            } else if (form.type === 2) {
                // page seo
                localStorage.setItem("page_id", form.id);

                if (data.output === "project") {
                    data.project_id = id;
                } else if (data.output === "template") {
                    data.template_id = id;
                }
                data.page_id = form.id;
                data.title = form.pagetitle;
                data.favicon = form.favicon;
                data.description = form.description;
                data.head_script = form.head_script ? form.head_script : "";
                data.body_script = form.body_script ? form.body_script : "";
                let content = `<html><head>`;
                if (data.title) {
                    content += `<title>${data.title}</title>`;
                }
                if (data.description) {
                    content += `<meta name="description" content="${data.description}">`;
                }
                if (data.favicon) {
                    content += `<link href="${data.favicon}" rel="icon" type="image/x-icon" />`;
                }
                content += `<link rel="preconnect" href="https://fonts.googleapis.com">`;
                content += `<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>`;
                content += `<link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Slabo+13px&family=Slabo+27px&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap" rel="stylesheet"><style>`;
                content += `<style>${form.editor.getCss()}</style>`;
                content += data.head_script;
                content += "</head>";
                content += `<body>${data.body_script}${form.editor.getHtml()}</body></html>`;

                data.content = content;

                res = await editPage(data);
                res = res.data;

                if (res.state === "success") {
                    await dispatch(builderactions.editPageSetting(data));
                    await dispatch(actions.closeForm({}));
                    await dispatch(sendMessage("Page setting saved"));
                } else {
                    await dispatch(actions.closeForm({}));
                    await dispatch(sendMessage("Error! Please try again"), true);
                }
            }
        }
    } catch (e) {
        sendMessage(e.message, true);
    }
    dispatch(hideLoader());
};
