import { connect } from "react-redux"
import * as thunks from "./thunks"
import * as actions from "./actions"
// import { loadPropList } from "./actions"

const mapStateToProps = (state) => ({
  assistantList: state.assistantReducer.assistantList,
  activeAssistant: state.assistantReducer.activeAssistant,
  msgList: state.assistantReducer.msgList,
})

const mapActionsToProps = (dispatch) => ({
  loadAssistantList: () => dispatch(thunks.loadAssistantListThunk()),
  setActiveAssistant: (assistant) => dispatch(actions.setActiveAssistent(assistant)),
  deleteAssistant: (id) => dispatch(thunks.deleteAssistantThunk(id)),
  manageKnowledge: (payload) => dispatch(thunks.manageKnowledgeThunk(payload)),
  fetchThreadMsg: (threadId, uuid) => dispatch(thunks.fetchThreadThunk(threadId, uuid)),
  deleteIntegration: (payload) => dispatch(thunks.manageIntegrationThunk(payload)),
  deleteThread: (threadId) => dispatch(thunks.deleteThreadThunk(threadId)),
})

export default connect(mapStateToProps, mapActionsToProps)
