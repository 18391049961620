import { useState, useEffect } from "react";
import { createNewThread, fetchThread } from "../services/api";
import { runFinishedStates } from "./constants";
import { toast } from "react-toastify";

const regexExp = /^[0-9a-zA-Z]{8}\b-[0-9a-zA-Z]{4}\b-[0-9a-zA-Z]{4}\b-[0-9a-zA-Z]{4}\b-[0-9a-zA-Z]{12}$/gi;

export const useThread = (run, setRun, uuid) => {
    const [assist, setAssist] = useState(null);

    const [threadId, setThreadId] = useState(undefined);
    const [thread, setThread] = useState(undefined);
    // const [actionMessages, setActionMessages] = useState([]);
    const [messages, setMessages] = useState([]);

    // This hook is responsible for creating a new thread if one doesn't exist
    function createThread() {
        createNewThread(uuid).then(data => {
            if (data && data.status) {
                console.log("createNewThread threa", data);
                setAssist(data.assist);

                // setRun(data);
                setThreadId(data.thread_id);
                localStorage.setItem("thread_id", data.thread_id);
                console.log(`Created new thread ${data.thread_id}`);
            } else {
                localStorage.removeItem("thread_id");
                toast.error(data.msg, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
    }

    useEffect(() => {
        console.log("uuid---->>> ", uuid);
        console.log(regexExp, uuid, regexExp.test(uuid));
        if (threadId === undefined) {
            const localThreadId = localStorage.getItem("thread_id");
            // const localThreadId = false;

            // if (localThreadId) {
            //     console.log(`Resuming thread ${localThreadId}`);
            //     fetchThread(localThreadId, uuid).then(res => {
            //         console.log("featch threa", res);
            //         if (res && res.status) {
            //             setAssist(res.assist);
            //             setThread(res);
            //             setThreadId(localThreadId);
            //         } else {
            //             createThread();
            //         }
            //     });
            // } else {
            //     console.log("Creating new thread");
            //     createThread();
            // }

            createThread();
        }
    }, [uuid, threadId, setThreadId, setThread, setRun]);

    // This hook is responsible for fetching the thread when the run is finished
    useEffect(() => {
        if (!run || !runFinishedStates.includes(run.status)) {
            return;
        }

        console.log(`Retrieving thread ${run.thread_id}`);
        if(run.status !== "requires_action"){
            fetchThread(run.thread_id, uuid).then(threadData => {
                setThread(threadData);
            });
        }
    }, [run, runFinishedStates, setThread]);

    // This hook is responsible for transforming the thread into a list of messages
    useEffect(() => {
        if (!thread) {
            return;
        }
        console.log(`Transforming thread into messages`);
        console.log({ thread });

        let newMessages = [...thread.messages]
            .sort((a, b) => a.created_at - b.created_at)
            .filter(message => message.hidden !== true);
        setMessages(newMessages);
    }, [thread, setMessages]);

    const clearThread = () => {
        localStorage.removeItem("thread_id");
        setThreadId(undefined);
        setThread(undefined);
        setRun(undefined);
        setMessages([]);
        // setActionMessages([]);
    };

    return {
        threadId,
        messages,
        // actionMessages,
        // setActionMessages,
        clearThread,
        assist,
        setMessages
    };
};
