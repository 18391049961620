import * as actions from "./actions";
import * as domainactions from "./../domain/actions";
import { sendMessage } from "@containers/messages/thunks";
import { fetchProject, fetchTemplate, editPage, delPage, uploadAsset } from "./api";
import { hideLoader, showLoader } from "../loader/actions";
import {
    // idbConnect,
    idbGetIem
} from "../../middlewares/idb";

export const initData = () => async dispatch => {
    try {
        dispatch(showLoader());
        let id = localStorage.getItem("id");
        let output = localStorage.getItem("output");
        if (id && output) {
            if (output === "project") {
                let res = await fetchProject({ project_id: id });
                res = res.data;
                if (res === null) {
                    // await dispatch(sendMessage('Loading Error!'));
                } else {
                    await dispatch(actions.getPages(res.pages));
                    // await dispatch(sendMessage('Project Loading'));
                }
            } else if (output === "template") {
                let res = await fetchTemplate({ template_id: id });
                res = res.data;
                if (res === null) {
                    // await dispatch(sendMessage('Loading Error!'));
                } else {
                    await dispatch(actions.getPages(res.pages));
                    // await dispatch(sendMessage('Project Loading'));
                }
            }
        }
        // await dispatch(sendMessage('Loading Error!'));
    } catch (e) {
        dispatch(sendMessage(e.message, true));
    }
    dispatch(hideLoader());
};

export const savePage = (editor, pageID, preview_image, page) => async dispatch => {
    try {
        dispatch(showLoader());
        const { title, description, favicon } = page;
        let data = {};
        const res = await idbGetIem();
        data.output = localStorage.getItem("output");
        data.page_id = pageID;
        const pageTitle = title ? title : "";
        const pageDescription = description ? description : "";
        const pageFavicon = favicon ? favicon : "";
        const headScript = page.head_script ? page.head_script: '';
        const bodyScript = page.body_script ? page.body_script: '';
        data.content =
            '<!doctype html><html lang="en"><head><title>' +
            pageTitle +
            '</title><meta name="description" content="' +
            pageDescription +
            '"><link href="' +
            pageFavicon +
            '" rel="icon" type="image/x-icon" />' +
            '<meta charset="utf-8">' +
            '<link rel="preconnect" href="https://fonts.googleapis.com">' +
            '<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>' +
            '<link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Slabo+13px&family=Slabo+27px&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap" rel="stylesheet"><style>' +
            editor.getCss() +
            "</style>" + headScript + "</head><body>" + bodyScript + 
            editor.getHtml() +
            "</body></html>";
        data.gjs = JSON.stringify(res);

        if (data.output === "project") {
            data.project_id = localStorage.getItem("id");
            if (preview_image) {
                data.preview_image = preview_image;
            }
        } else if (data.output === "template") {
            data.template_id = localStorage.getItem("id");
        }

        const response = await editPage(data);
        const responseData = response.data;
        if (responseData.state === "success") {
            dispatch(actions.editPage(data));
            if (data.preview_image) {
                dispatch(domainactions.editProjectPreview(data));
            }
            await dispatch(initData());
            dispatch(sendMessage("Page saved"));
        } else {
            dispatch(sendMessage("Error! Please try again"));
        }
        dispatch(hideLoader());
    } catch (e) {
        dispatch(sendMessage(e.message, true));
        dispatch(hideLoader());
    }
};

export const deletePage = pageID => async dispatch => {
    try {
        dispatch(showLoader());
        let res = await delPage(pageID);
        res = res.data;
        if (res.state === "success") {
            await dispatch(actions.deletePage(pageID));
            await dispatch(sendMessage("Page deleted"));
        } else {
            await dispatch(sendMessage("Error! Please try again"));
        }
    } catch (e) {
        dispatch(sendMessage(e.message, true));
    }
    dispatch(hideLoader());
};

export const uploadAssetForm = from => async dispatch => {
    try {
        dispatch(showLoader());
        let res = await uploadAsset(from);
        dispatch(hideLoader());
        return res;
    } catch (e) {
        dispatch(sendMessage(e.message, true));
    }
    dispatch(hideLoader());
};
