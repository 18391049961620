import { combineReducers } from "redux";

import agent from "./agent/reducer";
import builder from "./builder/reducer";
import campaign from "./campaign/reducer";
import company from "./company/reducer";
import deal from "./deal/reducer";
import domain from "./domain/reducer";
import lead from "./lead/reducer";
import user from "./user/reducer";
import optinFormIntegration from "./integrations/optinform/reducer";
import reminder from "./reminder/reducer";
import automation from "./automation/reducer";
import automationReply from "./automation_reply/reducer";
import email from "./email/reducer";
import fbFolder from "./fb_folder/reducer";
import assistantForm from "./assistant/reducer";
import convertleadBot from "./convertleadBot/reducer";

// import pipeline from './pipeline/reducer'
export default combineReducers({
    optinFormIntegration,
    agent,
    builder,
    campaign,
    company,
    deal,
    domain,
    lead,
    user,
    reminder,
    automation,
    automationReply,
    email,
    fbFolder,
    assistantForm,
    convertleadBot
    // pipeline
});
