import React, { Component } from "react";
import { compose } from "react-recompose";
import { ConvertleadBotFormContainer } from "@containers";
import {
    Grid,
    List,
    ListItem,
    ListContent,
    ListHeader,
    FormField,
    Button,
    Form,
    TextArea,
    Confirm
} from "semantic-ui-react";
import ConvertLeadBotFormModal from "../@common/modals/convertleadBot";

import "./index.scss";
import ConvertLeadBotForm from "../@common/forms/convertLeadBot";

class Integrations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            removeModal: false
        };
    }

    initLoad = () => {
        const { activeAssistant, assistantList } = this.props;
        const assistant = assistantList.find(ass => ass.id == activeAssistant);
        if (assistant) {
            this.props.changeForm({
                ...assistant,
                type: ""
            });
        }
    };

    componentDidMount() {
        this.initLoad();
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.props.changeForm({
            [name]: value
        });
    };

    handleSubmit = () => {
        this.props.saveForm(this.props.form, false);
    };

    handleDelete = () => {
        if (this.props.form.form.id) {
            this.props.deleteAssisatnt(this.props.form.form.id);
            window.location.reload();
        }
    };

    openConfirmModal = (removeModal = true, type) => {
        this.setState({ removeModal, type });
    };

    onConfirm = () => {
        this.setState({ removeModal: false });
        // if (this.props.form.form.id) {
        //     this.props.deleteAssistant(this.props.form.form.id);
        // }
        const { activeAssistant } = this.props;
        this.props.deleteIntegration({
            assist_id: activeAssistant,
            type: this.state.type
        });
    };

    render() {
        const { activeAssistant, assistantList, form } = this.props;
        const assistant = assistantList.find(ass => ass.id == activeAssistant);

        const newFieldList = assistant.convertlead_fields
            ? JSON.parse(assistant.convertlead_fields)
            : ["email", "name"];

        const webhookNewFieldList = assistant.webhook_fields
            ? JSON.parse(assistant.webhook_fields)
            : [
                  { value: "email", text: "email" },
                  { value: "name", text: "name" }
              ];

        return (
            <Grid className="h-100 assistant-settings">
                <ConvertLeadBotFormModal />
                <Confirm
                    className="confirm-modal"
                    open={this.state.removeModal}
                    onCancel={() => this.openConfirmModal(false)}
                    onConfirm={this.onConfirm}
                />
                <Grid.Column width={16} className="header">
                    <div className="d-flex align-items-center">
                        <i className="ti ti-settings"></i>
                        <div>
                            <strong>Integrations</strong>
                            <p>Send collected leads to convertlead or other webhook integrations.</p>
                        </div>
                    </div>
                </Grid.Column>

              {assistant.campaign ? (
                    <Grid.Column className="sixteen wide column main-form" width={12}>
                         <p className="integrations-title">Your Integrations</p>
                        <FormField className="integrations">
                            <div className="active">
                                <img
                                    src="/img/logo.png"
                                    width={"50"}
                                    height={"50"}
                                    className="top logo"
                                    alt="jsx-a11y/alt-text"
                                />
                                <div>
                                    <p>Convertlead integration</p>
                                    <p>
                                        Send leads to your Convertlead campaigns/integrations and seamlessly integrate your assistants with your pipelines. You can create up to two (on convertlead one webhook)
                                    integrations per A.I. Assistant.
                                    </p>
                                </div>
                                <div className="empty-div"></div>
                                <div className="btn-group">
                                    <Button
                                        type="button"
                                        onClick={() =>
                                            this.props.loadForm({
                                                campaign: assistant.campaign,
                                                integration: assistant.integration,

                                                add_fields: newFieldList,

                                                hook: "convertlead",
                                                title: "Convertlead Integration",
                                                prompt:
                                                    assistant.convertlead_prompt ||
                                                    "Start the conversation by asking user email, name.",
                                                show: true,

                                                company_id: assistant.company_id
                                            })
                                        }
                                    >
                                        Setting
                                    </Button>

                                    <Button
                                        type="button"
                                        color="red"
                                        onClick={() => this.openConfirmModal(true, "convertlead")}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </FormField>
                    </Grid.Column>
                ) : (
                    <Grid.Column className="sixteen wide column main-form" width={8}>
                        <FormField className="integrations">
                            <div className="inactive">
                                <img
                                    src="/img/logo.png"
                                    width={"50"}
                                    height={"50"}
                                    className="top logo"
                                    alt="jsx-a11y/alt-text"
                                />
                                <div>
                                    <p>Add a Convertlead integration</p>
                                    <p>
                                        Send leads to your Convertlead campaigns/integrations and seamlessly integrate your assistants with your pipelines. You can create up to two (on convertlead one webhook)
                                    integrations per A.I. Assistant.
                                    </p>
                                </div>
                                <Button
                                    type="button"
                                    onClick={() =>
                                        this.props.loadForm({
                                            campaign: assistant.campaign,
                                            integration: assistant.integration,

                                            add_fields: newFieldList,

                                            hook: "convertlead",
                                            title: "Convertlead Integration",
                                            prompt:
                                                assistant.convertlead_prompt ||
                                                "Start the conversation by asking user email, name.",
                                            show: true
                                        })
                                    }
                                >
                                    New integration
                                </Button>
                            </div>
                        </FormField>
                    </Grid.Column>
                )}

                {assistant.webhook_url ? (
                    <Grid.Column className="sixteen wide column main-form" width={12}>
                        <FormField className="integrations">
                            <div className="active">
                            <i className="top logo icon ti ti-webhook"/>
                                <div>
                                <p>Webhook integration </p>
                                <p>
                                    Send leads to a webhook callback URL and integrate our AI asistants with your own platforms. You can create up to two (on convertlead one webhook)
                                    integrations per A.I. Assistant.
                                </p>
                                </div>
                                <div className="empty-div"></div>
                                <div className="btn-group">
                                    <Button
                                        type="button"
                                        onClick={() =>
                                            this.props.loadForm({
                                                add_fields: webhookNewFieldList,
                                                webhook_url: assistant.webhook_url,
                                                hook: "webhook",
                                                title: "Webhook Integration",
                                                prompt:
                                                    assistant.webhook_prompt ||
                                                    "Start the conversation by asking user email, name.",
                                                show: true
                                            })
                                        }
                                    >
                                        Setting
                                    </Button>

                                    <Button
                                        type="button"
                                        color="red"
                                        onClick={() => this.openConfirmModal(true, "webhook")}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </FormField>
                    </Grid.Column>
                ) : (
                    <Grid.Column className="sixteen wide column main-form" width={8}>
                        <FormField className="integrations">
                            <div className="inactive">
                                <i                                   className="top logo icon ti ti-webhook"/>
                                <div>
                                <p>Add a webhook integration </p>
                                <p>
                                    Send leads to a webhook callback URL and integrate our AI asistants with your own platforms. You can create up to two (on convertlead one webhook)
                                    integrations per A.I. Assistant.
                                </p>
                                </div>
                                <Button
                                    type="button"
                                    onClick={() =>
                                        this.props.loadForm({
                                            add_fields: webhookNewFieldList,
                                            webhook_url: assistant.webhook_url,
                                            hook: "webhook",
                                            title: "Webhook Integration",
                                            prompt:
                                                assistant.webhook_prompt ||
                                                "Start the conversation by asking user email, name.",
                                            show: true
                                        })
                                    }
                                >
                                    New integration
                                </Button>
                            </div>
                        </FormField>
                    </Grid.Column>
                )}
            </Grid>
        );
    }
}

export default compose(ConvertleadBotFormContainer)(Integrations);
