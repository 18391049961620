import React, { Component } from "react";
import { compose } from "react-recompose";
import { BreadCrumbContainer, CompaniesContainer, LeadsContainer, PipelineContainer } from "@containers";
import { Auth } from "@services";
import "./index.scss";
import Tour from "../tour";
import { Popup, Segment, Grid, Menu, Select, Button, Form, Header, Icon } from "semantic-ui-react";
import LeadOverview from "./LeadOverview";
import PipelineSummary from "./PipelineSummary";
import moment from "moment";
import DatePickerSelect from "../@common/datepicker";
import Users from "../users";
import LeadStats from "../dashboard/leads-stats";

const companies = [{ key: "", text: "All companies", value: "" }];

class Home extends Component {
    dateDisplayFormat = "MM/DD/Y";

    state = {
        startDateDisplay: moment()
            .subtract(6, "d")
            .format(this.dateDisplayFormat),
        endDateDisplay: moment().format(this.dateDisplayFormat),
        startDate: moment()
            .subtract(6, "d")
            .format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        companyId: null,
        activePipeline: null
    };

    componentWillMount() {
        if(!Auth.isAgent){
            this.props.loadPipelines("");
            if (Auth.isAgency) {
                this.props.loadSelectBoxCompanies();
            }
            this.onLeadSummary(this.state.startDate, this.state.endDate, this.state.companyId);
        }
    }

    onLeadSummary(startDate, endDate, companyId) {
        this.props.loadSummary({
            startDate,
            endDate,
            companyId
        });
    }

    onChangeDateFrom = date => {
        this.setState({
            startDate: moment(date).format("Y-MM-DD"),
            startDateDisplay: moment(date).format(this.dateDisplayFormat)
        });
    };

    onChangeDateTo = date => {
        this.setState({
            endDate: moment(date).format("Y-MM-DD"),
            endDateDisplay: moment(date).format(this.dateDisplayFormat)
        });
        this.onLeadSummary(this.state.startDate, moment(date).format("Y-MM-DD"), this.state.companyId);
    };

    onRestDate = () => {
        this.setState({
            startDateDisplay: moment()
                .subtract(6, "d")
                .format(this.dateDisplayFormat),
            endDateDisplay: moment().format(this.dateDisplayFormat),
            startDate: moment()
                .subtract(6, "d")
                .format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD")
        });
        this.onLeadSummary(
            moment()
                .subtract(6, "d")
                .format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
            this.state.companyId
        );
    };

    filterByCompany = (e, data) => {
        this.setState({
            companyId: data.value
        });
        this.onLeadSummary(this.state.startDate, this.state.endDate, data.value);

        this.setState({
            activePipeline: null
        });
    };

    onChagngePipeline = (e, data) => {
        const pipeId = data.value;

        this.setState({
            activePipeline: pipeId
        });
    };

    render() {
        const { startDateDisplay, endDateDisplay, startDate, endDate, companyId, activePipeline } = this.state;
        const { pipelines } = this.props;

        let pipelineList = [];
        if (!companyId) {
            pipelineList = pipelines;
        } else {
            pipelineList = pipelines.filter(pipe => pipe.company_id == companyId);
        }

        return (
            <div className="Dashboard">
                <Tour page={"Dashboard"}></Tour>

                {Auth.isAgency || Auth.isCompany ? (
                    <div className="leads-container body">
                        <Segment attached="top" className="dashboard-filters">
                            <Grid columns={2}>
                                <Grid.Column>
                                    <Header floated="left" as="h1">
                                        Dashboard
                                    </Header>
                                    <h3 class="ui header">Welcome to your Convertlead dashboard.</h3>
                                </Grid.Column>
                                <Grid.Column>
                                    <Menu secondary>
                                        <Menu.Menu position="right" className="dashboard-menu">
                                            <Menu.Item className="filter white wrapper">
                                                {Auth.isAgency ? (
                                                    <Form.Field
                                                        control={Select}
                                                        options={[...companies, ...this.props.selectBoxCompanies]}
                                                        placeholder="All companies"
                                                        search
                                                        onChange={this.filterByCompany}
                                                        defaultValue={companyId || null}
                                                        searchInput={{ id: "form-companies-list" }}
                                                    />
                                                ) : null}
                                            </Menu.Item>
                                            <Menu.Item className="wrapper">
                                                <Popup
                                                    position="bottom left"
                                                    trigger={
                                                        <Form.Field>
                                                            <Button>
                                                                <Icon name="calendar alternate outline" />
                                                                {startDateDisplay} - {endDateDisplay}
                                                            </Button>
                                                        </Form.Field>
                                                    }
                                                    flowing
                                                    hoverable
                                                >
                                                    <DatePickerSelect
                                                        onChangeDateFrom={this.onChangeDateFrom}
                                                        onChangeDateTo={this.onChangeDateTo}
                                                        onRestDate={this.onRestDate}
                                                        from={new Date(startDate)}
                                                        to={new Date(endDate)}
                                                    />
                                                </Popup>
                                            </Menu.Item>
                                        </Menu.Menu>
                                    </Menu>
                                </Grid.Column>
                            </Grid>
                        </Segment>
                        <LeadOverview {...this.props} />
                        <PipelineSummary
                            {...this.props}
                            activePipeline={activePipeline}
                            onChagngePipeline={this.onChagngePipeline}
                            startDate={startDate}
                            endDate={endDate}
                            companyId={companyId}
                            activePipelineList={pipelineList}
                        />

                        <div className="AgentProfile">
                            <Segment attached="top">
                                <Grid.Column>
                                    <div className="selectedAgent"> welcome to convertlead </div>
                                    <div className="selectedContent1">
                                        Onboard in 30 minutes with our walkthrough videos.
                                    </div>
                                </Grid.Column>
                            </Segment>
                            <Segment className="stats">
                                <div className="selectedAgent module-title"> Quick overview </div>

                                <a href="https://www.youtube.com/watch?v=Arj5kB0DjpA" target="_blank" className="video-container">
                                    <div className="video-tumbnail">
                                        <i className="ti ti-player-play" />
                                    </div>
                                    <div className="video-text">
                                        <p className="video-title">Intro & Overview</p>
                                        <p className="video-description">
                                            Brief overview of all the things you can do inside Convertlead
                                        </p>
                                    </div>
                                </a>

                                <a href="https://www.youtube.com/watch?v=MGoZ8gUZpEc" target="_blank" className="video-container">
                                    <div className="video-tumbnail">
                                        <i className="ti ti-player-play" />
                                    </div>
                                    <div className="video-text">
                                        <p className="video-title">Setup your first campaign</p>
                                        <p className="video-description">
                                            Convertlead Campaigns walkthrough. Agents, pipelines & integrations
                                        </p>
                                    </div>
                                </a>

                                <a href="https://www.youtube.com/watch?v=EK8LhvCSVf0" target="_blank"  className="video-container">
                                    <div className="video-tumbnail">
                                        <i className="ti ti-player-play" />
                                    </div>
                                    <div className="video-text">
                                        <p className="video-title">Automations - How To</p>
                                        <p className="video-description">
                                            Automation overview, actions & how to setup your automation.
                                        </p>
                                    </div>
                                </a>

                                <a href="https://www.youtube.com/watch?v=AK2JzY-59M0" target="_blank" className="video-container">
                                    <div className="video-tumbnail">
                                        <i className="ti ti-player-play" />
                                    </div>
                                    <div className="video-text">
                                        <p className="video-title">Leads, pipelines & stages</p>
                                        <p className="video-description">
                                            Lead nurturing with pipelines and stage automations.
                                        </p>
                                    </div>
                                </a>


                                 <a href="https://www.youtube.com/watch?v=8f4OrG1JxyA" target="_blank" className="video-container">
                                    <div className="video-tumbnail">
                                        <i className="ti ti-player-play" />
                                    </div>
                                    <div className="video-text">
                                        <p className="video-title">Agents & agent dashboard</p>
                                        <p className="video-description">
                                            Your agents page and agent account dashboard.
                                        </p>
                                    </div>
                                </a>
                            </Segment>
                        </div>
                    </div>
                ) : Auth.isAdmin ? (
                    <Users />
                ) : (
                    <LeadStats />
                )}
            </div>
        );
    }
}

export default compose(BreadCrumbContainer, CompaniesContainer, LeadsContainer, PipelineContainer)(Home);
