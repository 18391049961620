import { useEffect, useRef } from "react";
import { fetchRun, sendEmailNameApi } from "../services/api";
import { runFinishedStates } from "./constants";

export const useRunPolling = (threadId, run, setRun, setRunStatus) => {
    const pollingTimerRef = useRef(null);

    const startPolling = async () => {
        if (threadId && threadId !== undefined && run.run_id) {
            const data = await fetchRun(threadId, run.run_id);
            if (data) {
                if (!run || data.run_id !== run.run_id || data.status !== run.status) {
                    setRun(data);
                }
                pollingTimerRef.current = setTimeout(startPolling, 1000);
            }
        }
    };

    const stopPolling = () => clearInterval(pollingTimerRef.current);

    const submitEmailName = async () => {
        const payload = [];
        let response = [];
        console.log("required run", run);
        if (run.run && run.run.required_action) {
            const tool_calls = run.run.required_action.submit_tool_outputs.tool_calls;

            for (const tool_call of tool_calls) {
                console.log({ tool_call });
                if (tool_call.function.name === "send_email_name") {
                    response.push({
                        tool_call_id: tool_call.id,
                        output: "success"
                    });
                    payload.push(JSON.parse(tool_call.function.arguments));
                }
            }
            // send webhook
            

            // send to convertlead
            const actionRun = await sendEmailNameApi(threadId, run.run_id, payload, response);
            

            setRun(actionRun);
        }
    };

    useEffect(() => {
        const needsToPoll = run && !runFinishedStates.includes(run.status);
        if (run) {
            setRunStatus(run.status);
        }

        if (run && run.status === "requires_action") {
            submitEmailName();
        }

        if (needsToPoll) {
            startPolling();
        } else {
            stopPolling();
        }

        return stopPolling;
    }, [threadId, run, setRun, runFinishedStates]);
};
