import React, { Component } from "react";

class ThanksPage extends Component {
    render() {
        
        return (
            <div className="OptionForm">
               Thanks you.
            </div>
        );
    }
}

export default ThanksPage;
