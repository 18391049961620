import { connect } from "react-redux";
import * as thunks from "./thunks";
import * as actions from "./actions";
import { getSelectBoxStatuses } from "./selectors";

const mapStateToProps = state => ({
    leads: state.leads.leads,
    twilioToken: state.leads.twilioToken,
    newLeadsCount: state.leads.newLeadsCount,
    agentLeads: state.leads.agentLeads,
    pagination: state.leads.pagination,
    statuses: state.leads.statuses,
    selectBoxStatuses: getSelectBoxStatuses(state),
    selectBoxDates: state.agents.selectBoxDates,
    query: state.leads.query,
    openModal: state.leads.openModal,
    openModalStatus: state.leads.openModalStatus,
    listType: state.leads.listType,
    leadNotes: state.leads.leadNotes,
    summary: state.leads.summary
});

const mapDispatchToProps = dispatch => ({
    loadLeads: () => dispatch(thunks.loadLeads()),
    // changeListType: (listType) => dispatch(actions.changeListType(listType)),
    changeListType: listType => dispatch(thunks.changeListType(listType)),
    fetchTwilioTokenBy: id => dispatch(thunks.loadTwilioToken(id)),
    fetchOwnTwilioToken: () => dispatch(thunks.loadOwnTwilioToken()),

    deleteLead: (companyId, id, forceDelete = false) => dispatch(thunks.deleteLead(companyId, id, forceDelete)),
    filterLeads: filters => dispatch(thunks.filterLeads(filters)),
    searchLeads: search => dispatch(thunks.searchLeads(search)),
    gotoPageLead: activePage => dispatch(thunks.gotoPage(activePage)),
    toggleShowDeleted: () => dispatch(thunks.toggleShowDeleted()),
    sort: field => dispatch(thunks.sortLeads(field)),
    openModal: open => dispatch(actions.openLeadModal(open)),
    searchAgentLeads: search => dispatch(thunks.searchAgentLeads(search)),
    filterAgentLeads: filters => dispatch(thunks.filterAgentLeads(filters)),
    agentLeadsByStatuses: statuses => dispatch(thunks.agentLeadsByStatuses(statuses)),
    scrollToPage: page => dispatch(thunks.scrollToPage(page)),
    exportTo: payload => dispatch(thunks.exportTo(payload)),
    reportPoll: uuid => dispatch(thunks.reportPoll(uuid)),

    loadSummary: filter => dispatch(thunks._loadSummary(filter))
});

export default connect(mapStateToProps, mapDispatchToProps);
